<template>
    <div>
        <div class="row">
            <div class="col-10 offset-md-1 text-center">
                <h2 class="text-center mb-4">{{ typeTitle }} Rewards</h2>
                <p class="mb-lg-5" v-html="typeDescription"></p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card-grid mb-5">
                    <b-card
                            v-for="reward in rewardsByType"
                            :key="reward.id"
                            align="center"
                            no-body
                            tag="article">
                        <div class="card-img-container">
                            <b-card-img
                                    :src="cardImage(reward)"
                                    :alt="reward.title"
                                    top>
                            </b-card-img>
                        </div>
                        <b-card-body>
                            <b-card-title>{{ reward.title }}</b-card-title>
                            <template v-if="!reward.coming_soon">
                                <h5 class="text-primary">{{ reward.points_cost }} Points</h5>

                                <b-button v-if="isAvailable(reward)" variant="danger" block @click="$bvModal.show(reward.code)">{{ redeemTitle(reward) }}</b-button>

                                <b-button v-if="!isAvailable(reward)" variant="primary" block @click="$bvModal.show(reward.code)">{{ cannotRedeemTitle(reward) }}</b-button>

                                <small v-if="displaySlotApplicationCount(reward)" class="mt-1 mb-0">Total Applications: {{ currentSlotApplicationCount(reward) }}</small>

                            </template>
                            <template v-if="reward.coming_soon">
                                <h5>&nbsp;</h5>
                                <b-button variant="primary" block disabled>Coming Soon!</b-button>
                            </template>
                        </b-card-body>
                    </b-card>
                </div>

                <all-rewards-links></all-rewards-links>
            </div>
        </div>

        <!--------------------
         Modals
        ---------------------->
        <component
                v-for="reward in rewards"
                :key="reward.id"
                :reward="reward"
                :is="rewardModalType(reward)"
                :show-dates="true"
                @redeemed="showRedeemedModal"
        ></component>

        <reward-redeemed-modal
                ref="rewardRedeemedModal"
        ></reward-redeemed-modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "Rewards",

        props: ['types'],

        mounted() {
            this.fetchRewardSlots();
            this.fetchActiveBundleRuns();
            this.fetchCompany();
            this.fetchRewards();
            this.fetchFormats();
            this.fetchLines();
        },

        computed: {
            ...mapGetters([
                'availableSlotsCardText',
                'activeBundleRuns',
                'displaySlotApplicationCount',
                'currentSlotApplicationCount',
                'company',
                'isAvailable',
                'redeemTitle',
                'rewards',
                'user'
            ]),

            typeTitle() {
                let title = '';

                for (let i = 0; i < this.types.length; i++) {
                    if (i > 0) {
                        title += ' & '
                    }

                    title += this.types[i].charAt(0).toUpperCase() + this.types[i].slice(1)
                }

                return title;
            },

            typeDescription() {
                for (let i = 0; i < this.rewards.length; i++) {
                    if (this.rewards[i].type.title_code === this.types[0]) {
                        return this.rewards[i].type.description;
                    }
                }
                return '';
            },

            rewardsByType() {
                return this.rewards.filter(reward => {
                    for (let i = 0; i < this.types.length; i++) {
                        if (reward.type.title_code === this.types[i]) {
                            return true;
                        }
                    }
                });
            }
        },

        methods: {
            ...mapActions([
                'fetchCompany',
                'fetchFormats',
                'fetchLines',
                'fetchRewards',
                'fetchUser',
                'fetchRewardSlots',
                'fetchActiveBundleRuns'
            ]),

            cardImage(reward) {
                if (reward.code === 'discord-emoji-x1' || reward.code === 'discord-emoji-x5') {
                    return reward.examples[1].path; // TODO: This is a dirty hack so do something about it
                }
                return reward.examples[0].path;
            },

            cannotRedeemTitle(reward) {
                if (reward.requires_available_slot) {
                    return "Applications Closed";
                }

                return "Unavailable";
            },

            rewardModalType(reward) {
                if (reward.type.title === 'Merchandise') {
                    return 'redeem-merchandise-reward-modal';
                }
                if (reward.requires_available_slot) {
                    return 'redeem-reward-large-modal';
                }
                return 'redeem-reward-small-modal';
            },

            showRedeemedModal(reward) {
                this.fetchUser();
                this.$refs.rewardRedeemedModal.open(reward);
            }
        }
    }
</script>

<style scoped>

</style>
