<template>
    <div class="page-container admin">
        <div class="left-nav">
            <b-nav vertical>
                <b-nav-item :to="{name: 'index'}" class="title">
                    <img id="company-logo" src="https://starstickers.s3.eu-west-2.amazonaws.com/site/starstickers-logo.png" alt="StarStickers by Onyrica" />
                </b-nav-item>

                <b-nav-item :to="{name: 'companyDashboard'}" class="">
                    <i class="fal fa-home fa-fw"></i> <span>Dashboard</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companySettings'}">
                    <i class="fal fa-building fa-fw"></i> <span>Company Details</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyRewards'}">
                    <i class="fal fa-treasure-chest fa-fw"></i> <span>Reward Types</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyFeaturedRewards'}">
                    <i class="fal fa-star fa-fw"></i> <span>Featured Rewards</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyMerchandise'}">
                    <i class="fal fa-image fa-fw"></i> <span>Merchandise</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyMerchandiseBundles'}">
                    <i class="fal fa-box-full fa-fw"></i> <span>Merchandise Bundles</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyRewardSlots'}">
                    <i class="fal fa-box-ballot fa-fw"></i> <span>Reward Slots</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyPointEvents'}">
                    <i class="fal fa-diploma fa-fw"></i> <span>Point Events</span>
                </b-nav-item>

                <hr class="divider-white">

                <b-nav-item :to="{name: 'companyUsers'}">
                    <i class="fal fa-users fa-fw"></i> <span>Patrons</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyUserRewards'}">
                    <i class="fal fa-medal fa-fw"></i> <span>Patron Rewards</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyMerchandiseRewards'}">
                    <i class="fal fa-truck-loading fa-fw"></i> <span>Merchandise Rewards</span>
                </b-nav-item>

                <b-nav-item :to="{name: 'companyUserPointEvents'}">
                    <i class="fal fa-sparkles fa-fw"></i> <span>Patron Point Bundles</span>
                </b-nav-item>

                <b-nav-item @click="logout()">
                    <i class="fal fa-power-off fa-fw"></i> <span>Logout</span>
                </b-nav-item>
            </b-nav>
        </div>
        <div class="admin-content">
            <div class="content">
                <router-view :key="$route.fullPath"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchUser();
        },

        computed: {
            ...mapGetters(['user', 'userState'])
        },

        methods: {
            ...mapActions([
                'fetchUser',
                'clearRewardSlots',
                'clearActiveBundleRuns',
                'clearCompany',
                'clearMerchandise',
                'clearRewards',
                'clearUser'
            ]),

            isActive(path) {
                return this.$route.matched.some(({ name }) => name === path);
            },

            logout() {
                Promise.all([
                    this.clearRewardSlots(),
                    this.clearActiveBundleRuns(),
                    this.clearCompany(),
                    this.clearMerchandise(),
                    this.clearRewards(),
                    this.clearUser()
                ]).then(() => {
                    window.location = '/logout';
                });
            }
        }
    }
</script>

<style lang="scss">
</style>
