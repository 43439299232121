const state = {
    userReward: {
        id: null,
        references: [],
        reference_max: 0,
        reward: null,
        reward_status: null,
        description: null,
        illustrations: [],
        illustration_max: 0,
        notes: []
    },
    rewardStatuses: []
};

const getters = {
    userReward: state => {
        return state.userReward;
    },

    rewardStatuses: state => {
        return state.rewardStatuses;
    },

    canCancelReward: (state, getters, rootGetters) => {
        if (!state.userReward.reward_status) {
            return false;
        }

        let statusCode = state.userReward.reward_status.code;

        if (statusCode === 'complete' || statusCode === 'cancelled') {
            return false;
        }

        if (rootGetters.user.isCompanyAdmin) {
            return true;
        }

        return statusCode === 'pending';
    }
};

const actions = {
    fetchUserReward({state, dispatch}, uuid) {
        return new Promise((resolve, reject) => {
            if (state.userReward.id !== uuid) {
                dispatch('clearUserReward');
            }
            axios.get(`/web/user-rewards/${uuid}`)
                .then(response => {
                    dispatch('setUserReward', response.data.reward);
                    dispatch('setRewardStatuses', response.data.reward_statuses);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setUserReward({commit}, userReward) {
        return new Promise((resolve) => {
            commit('SET_USER_REWARD', userReward);
            resolve();
        });
    },

    setRewardStatuses({commit}, rewardStatuses) {
        return new Promise((resolve) => {
            commit('SET_REWARD_STATUSES', rewardStatuses);
            resolve();
        });
    },

    clearUserReward({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_USER_REWARD');
            resolve();
        });
    },

    clearRewardStatuses({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_REWARD_STATUSES');
            resolve();
        });
    },

    updateUserReward({state, dispatch}, {uuid: uuid, description: description}) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/user-rewards/${uuid}`, { description: description })
                .then(response => {
                    dispatch('setUserReward', response.data.reward);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    updateUserRewardStatus({state, dispatch}, {uuid: uuid, statusCode: statusCode}) {
        if (statusCode === 'cancelled') {
            return dispatch('cancelUserReward', uuid);
        }

        return new Promise((resolve, reject) => {
            axios.put(`/web/user-rewards/${uuid}/status`, { reward_status_code: statusCode })
                .then(response => {
                    dispatch('setUserReward', response.data.reward);
                    dispatch('setRewardStatuses', response.data.reward_statuses);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    cancelUserReward({state, dispatch}, uuid) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/user-rewards/${uuid}/cancel`)
                .then(response => {
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    addNote({state, dispatch}, {uuid: uuid, noteText: noteText}) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/user-rewards/${uuid}/note`, { note_text: noteText })
                .then(response => {
                    dispatch('setUserReward', response.data.reward);
                    dispatch('setRewardStatuses', response.data.reward_statuses);
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
};

const mutations = {
    SET_USER_REWARD(state, userReward) {
        state.userReward = userReward;
    },

    SET_REWARD_STATUSES(state, rewardStatuses) {
        state.rewardStatuses = rewardStatuses;
    },

    CLEAR_USER_REWARD(state) {
        state.userReward = {
            id: null,
            references: [],
            reference_max: 0,
            reward: null,
            reward_status: null,
            illustrations: [],
            illustration_max: 0,
            notes: []
        };
    },

    CLEAR_REWARD_STATUSES(state) {
        state.rewardStatuses = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
