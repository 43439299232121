<template>
    <b-modal
            content-class="shadow"
            id="merchandise-bundle-modal"
            :title="modalHeader"
            size="lg"
            no-stacking>

        <h3 v-if="bundleTitle">{{ bundleTitle }}</h3>

        <b-tabs pills class="merchandise-line-tabs">
            <!-- Bundle Details -->
            <b-tab title="Details" @click="setTab('details')" :active="tabIsActive('details')">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <b-form-group
                                label="Title"
                                label-for="title">
                            <b-form-input id="title" v-model="bundleData.title" trim></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-12 mb-3">
                        <b-form-group
                                label="Description"
                                label-for="description">
                            <b-form-textarea
                                    id="description"
                                    rows="3"
                                    max-rows="6"
                                    v-model="bundleData.description"
                            ></b-form-textarea>
                        </b-form-group>
                    </div>

                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group
                                        label="Points Cost"
                                        label-for="points-cost">
                                    <b-form-input id="points-cost" v-model="bundleData.points_cost" type="number" trim></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-md-6">
                                <b-form-checkbox
                                        id="active"
                                        v-model="bundleData.active"
                                        :value="1"
                                        :unchecked-value="0"
                                >
                                    This bundle is active
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-3">
                        <div class="row">
                            <div class="col-md-12" @click="checkForBundleRuns()">
                                <b-form-checkbox
                                    id="limited_item"
                                    v-model="bundleData.limited_item"
                                    :value="1"
                                    :unchecked-value="0"
                                >
                                    This bundle is a limited item<br/>
                                    <small>Limited items are only available when a Merchandie Run has been created for the bundle</small>
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </b-tab>

            <!-- Bundle Items -->
            <b-tab title="Items" @click="setTab('items')" :active="tabIsActive('items')">
                <div class="row merchandise-bundle-item mb-3" v-for="(item, key) in bundleData.items">
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-5">
                                <b-form-group
                                        label="Merchandise Format"
                                        :label-for="`merchandise-format-${key}`">
                                    <b-form-select :id="`merchandise-format-${key}`" v-model="item.format_id" :options="formatsByLine(item.line_id, 'Any')"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-5">
                                <b-form-group
                                        label="Merchandise Line"
                                        :label-for="`merchandise-line-${key}`">
                                    <b-form-select :id="`merchandise-line-${key}`" v-model="item.line_id" :options="linesByFormat(item.format_id, 'Any')"></b-form-select>
                                </b-form-group>
                            </div>
                            <div class="col-md-2">
                                <b-form-group
                                        label="Quantity"
                                        :label-for="`quantity-${key}`">
                                    <b-form-input :id="`quantity-${key}`" v-model="item.quantity" type="number" trim></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <button class="btn btn-danger" @click="deleteItem(key)"><i class="fa fa-times p-0 m-0"></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3 offset-9">
                        <button class="btn btn-success" @click="addItem()"><i class="fa fa-plus"></i> Add Item</button>
                    </div>
                </div>
            </b-tab>

            <!-- Bundle Images -->
            <b-tab title="Images" v-if="bundle" @click="setTab('images')" :active="tabIsActive('images')">

                <template v-if="bundle && bundle.images.length > 0">
                    <div class="reference-grid">
                        <div v-for="image in bundle.images" class="reference-grid-item">
                            <a :href="image.path" target="_blank">
                                <img :src="image.path" alt="Reward illustration">
                            </a>
                            <button @click="triggerFileDelete(image.id)" class="btn btn-danger btn-sm reference-delete"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                </template>

                <template>
                    <h3>Upload an image</h3>
                    <dropzone
                            ownerClass="MerchandiseBundle"
                            :ownerId="bundle.id"
                            type="merchandise"
                            uploadText="Upload Bundle Images"
                            @uploadComplete="uploadComplete"
                    ></dropzone>
                </template>

            </b-tab>
        </b-tabs>

        <template #modal-footer>
            <b-button size="sm" variant="default" @click="close()">
                Cancel
            </b-button>
            <b-button v-if="bundle" size="sm" variant="danger" @click="deleteBundle()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="deleting"></i> Delete
            </b-button>
            <b-button size="sm" variant="success" @click="saveBundle()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="saving"></i> Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "MerchandiseBundleModal",

        props: {
            formats: {
                type: Array,
                default: []
            },
            lines: {
                type: Array,
                default: []
            }
        },

        data() {
            return {
                bundle: null,

                bundleData: {
                    title: null,
                    description: null,
                    points_cost: [],
                    limited_item: false,
                    active: 0,
                    items: []
                },

                tab: 'details',
                saving: false,
                deleting: false
            }
        },

        computed: {
            ...mapGetters(['linesByFormat', 'formatsByLine']),

            modalHeader() {
                return (!this.bundle) ? 'New Bundle' : 'Update Bundle';
            },

            bundleTitle() {
                if (!this.bundle) return null;
                return this.bundle.title;
            }
        },

        methods: {
            ...mapActions(['displayToast', 'deleteFile']),

            open(bundle) {
                this.setBundleData(bundle);
                this.tab = 'details';
                this.$bvModal.show('merchandise-bundle-modal');
            },

            close() {
                this.$bvModal.hide('merchandise-bundle-modal');
            },

            saveBundle() {
                if (this.saving) return;

                this.saving = true;

                let verb = 'post';
                let path = route('web.merchandise.bundles.store');

                if (this.bundle) {
                    verb = 'put';
                    path = route('web.merchandise.bundles.bundle.update', this.bundle.id);
                }

                axios[verb](path, this.bundleData)
                    .then(response => {
                        this.displayToast({text: `The merchandise bundle was updated.`, type: 'success'});
                        this.$emit('reload');
                        this.setBundleData(response.data);
                        this.saving = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            fetchBundle() {
                axios.get(route('web.merchandise.bundles.bundle.show', this.bundle.id))
                    .then(response => {
                        this.setBundleData(response.data);
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            deleteBundle() {
                if (this.deleting) return;

                if (!confirm("Are you sure you want to delete this merchandise bundle?")) {
                    return;
                }

                this.deleting = true;

                axios.delete(route('web.merchandise.bundles.bundle.destroy', this.bundle.id))
                    .then(() => {
                        this.displayToast({text: `The merchandise bundle was deleted.`, type: 'success'});
                        this.$emit('reload');
                        this.close();
                        this.setBundleData(null);
                        this.deleting = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.deleting = false;
                    });
            },

            addItem() {
                this.bundleData.items.push({format_id: null, line_id: null, quantity: 1});
            },

            deleteItem(index) {
                this.bundleData.items.splice(index, 1);
            },

            triggerFileDelete(uuid) {
                this.deleteFile(uuid).then(() => {
                    this.fetchBundle();
                    this.$emit('reload');
                })
            },

            uploadComplete() {
                this.fetchBundle();
                this.$emit('reload');
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            },

            setBundleData(bundle = null) {
                this.bundle = bundle;
                if (bundle) {
                    this.bundleData = {
                        title: bundle.title,
                        description: bundle.description,
                        points_cost: bundle.points_cost,
                        limited_item: bundle.limited_item,
                        active: bundle.active,
                        items: []
                    };

                    for (let i = 0; i < bundle.items.length; i++) {
                        this.bundleData.items.push({
                            format_id: bundle.items[i].merchandise_format ? bundle.items[i].merchandise_format.id : null,
                            line_id: bundle.items[i].merchandise_line ? bundle.items[i].merchandise_line.id : null,
                            quantity: bundle.items[i].quantity
                        });
                    }

                } else {
                    this.bundleData = {
                        title: null,
                        description: null,
                        points_cost: 0,
                        limited_item: false,
                        active: 0,
                        items: []
                    };
                }
                this.saving = false;
                this.deleting = false;
            },

            checkForBundleRuns() {
                if (!this.bundle.active_runs.length) return;

                this.displayToast({text: `You cannot remove limited item status while a bundle run is active.`, type: 'danger'});
                this.bundleData.limited_item = true;
            }
        }
    }
</script>

<style scoped>

</style>
