<template>
    <b-modal
            content-class="shadow"
            id="merchandise-bundle-run-modal"
            :title="modalHeader"
            no-stacking>

        <b-tabs pills class="merchandise-line-tabs">
            <!-- Line Details -->
            <b-tab title="Details" @click="setTab('details')" :active="tabIsActive('details')">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="bundle">Merchandise Bundle:</label>
                        <b-form-select
                            id="bundle"
                            v-model="bundleRunData.bundle_id"
                            :options="bundles"
                            value-field="id"
                            text-field="title"
                            :disabled="bundleChangeBlocked"
                        ></b-form-select>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="quantity">Quantity:</label>
                        <b-form-input
                            id="quantity"
                            type="number"
                            min="1"
                            v-model="bundleRunData.quantity"
                        ></b-form-input>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="max-per-user">Max Per User:</label>
                        <b-form-input
                                id="max-per-user"
                                type="number"
                                min="1"
                                v-model="bundleRunData.max_per_user"
                        ></b-form-input>
                    </div>

                    <div class="col-md-12 mb-3">
                        <b-form-checkbox
                            id="display-quantity"
                            v-model="bundleRunData.display_quantity_remaining"
                            :value="1"
                            :unchecked-value="0"
                        >
                            Display the quantity to the user
                        </b-form-checkbox>
                    </div>

                    <div class="col-md-12 mb-3">
                        <b-form-checkbox
                            id="active"
                            v-model="bundleRunData.active"
                            :value="1"
                            :unchecked-value="0"
                        >
                            This limited bundle run is active
                        </b-form-checkbox>
                    </div>
                </div>
            </b-tab>

            <!-- Line Images -->
            <b-tab title="Purchases" v-if="bundleRun" @click="setTab('purchases')" :active="tabIsActive('purchases')">
                <div class="row" v-if="bundleRun.purchases && bundleRun.purchases.length > 0">
                    <div class="col-md-12">
                        <div class="table-container">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Points</th>
                                        <th>Date Purchased</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="purchase in bundleRun.purchases" :key="purchase.id">
                                        <td>{{ purchase.user_name }}</td>
                                        <td>{{ purchase.user_points }}</td>
                                        <td>{{ purchase.date_purchased }}</td>
                                        <td>
                                            <router-link :to="{ name: 'reward', params: { uuid: purchase.user_reward_id } }" class="btn btn-primary btn-sm" target='_blank'>View</router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="panel panel-default panel-body" v-if="!bundleRun.purchases || bundleRun.purchases.length === 0">
                    <empty-state
                        subtitle="No purchases have yet been made against this bundle run"
                    ></empty-state>
                </div>
            </b-tab>
        </b-tabs>

        <template #modal-footer>
            <b-button size="sm" variant="default" @click="close()">
                Cancel
            </b-button>
            <b-button size="sm" variant="success" @click="saveBundleRun()" :disabled="saving">
                <i class="fa fa-pulse fa-spinner" v-if="saving"></i> Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: "MerchandiseBundleRunModal",

        props: {
            bundles: {
                type: Array,
                default: []
            }
        },

        data() {
            return {
                bundleRun: null,

                bundleRunData: {
                    bundle_id: null,
                    quantity: 1,
                    max_per_user: 1,
                    display_quantity_remaining: false,
                    active: false
                },

                saving: false
            }
        },

        computed: {
            modalHeader() {
                return (!this.bundleRun) ? 'New Limited Bundle Run' : 'Update Limited Bundle Run';
            },

            bundleChangeBlocked() {
                return (this.bundleRun && this.bundleRun.purchases && this.bundleRun.purchases.length > 0);
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            open(bundleRun) {
                this.setBundleRunData(bundleRun);
                this.$bvModal.show('merchandise-bundle-run-modal');
            },

            close() {
                this.$bvModal.hide('merchandise-bundle-run-modal');
            },

            saveBundleRun() {
                if (this.saving) return;

                this.saving = true;

                let verb = 'post';
                let path = route('web.merchandise.bundles.runs.store');

                if (this.bundleRun) {
                    verb = 'put';
                    path = route('web.merchandise.bundles.runs.run.update', this.bundleRun.id);
                }

                axios[verb](path, this.bundleRunData)
                    .then(() => {
                        this.displayToast({text: `The limited bundle run was updated.`, type: 'success'});
                        this.$emit('reload');
                        this.close();
                        this.setBundleRunData(null);
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            setBundleRunData(bundleRun) {
                this.bundleRun = bundleRun;
                if (bundleRun) {
                    this.bundleRunData = {
                        bundle_id: bundleRun.bundle_id,
                        quantity: bundleRun.quantity,
                        max_per_user: bundleRun.max_per_user,
                        display_quantity_remaining: bundleRun.display_quantity_remaining,
                        active: bundleRun.active
                    };
                } else {
                    this.bundleRunData = {
                        bundle_id: null,
                        quantity: 1,
                        max_per_user: 1,
                        display_quantity_remaining: false,
                        active: false
                    };
                }
                this.saving = false;
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            }
        }
    }
</script>

<style scoped>

</style>
