<template>
    <b-modal
        content-class="shadow"
        hide-footer
        hide-header
        id="edit-avatar"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('edit-avatar')"></b-button>

        <h3>Select an avatar</h3>

        <div class="card-grid avatar-select">
            <img
                    v-for="avatar in avatars"
                    :key="avatar.id"
                    :src="avatar.path"
                    class="avatar"
                    :class="{'active': avatarIsSelected(avatar) }"
                    @click="selectAvatar(avatar)"
            >
        </div>

        <dropzone
                ownerClass="User"
                :ownerId="user.id"
                type="avatar"
                uploadText="Upload Your Avatar"
                :fileSize="0.5"
                overwrite
                @uploadComplete="uploadComplete()"
        ></dropzone>

        <b-button variant="success" class="mt-3" block @click="save()" :disabled="saving">
            <i class="fal fa-pulse fa-spinner" v-if="saving"></i> Save changes
        </b-button>

    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.getAvatars();
        },

        data() {
            return {
                avatars: {},
                errors: [],
                saving: false,
                selectedAvatar: null
            }
        },

        computed: {
            ...mapGetters(['user'])
        },

        methods: {
            ...mapActions(['fetchUser']),

            avatarIsSelected(avatar) {
                if (!this.selectedAvatar) {
                    return false;
                }
                return this.selectedAvatar.id === avatar.id;
            },

            getAvatars() {
                return new Promise((resolve) => {
                    axios.get(`/web/users/${this.user.id}/avatars`)
                        .then(response => {
                            this.avatars = response.data;
                            this.selectAvatar(this.user.avatar);
                            resolve();
                        });
                });
            },

            save() {
                this.saving = true;
                axios.put(`/web/users/${this.user.id}/avatar`, { avatar_uuid: this.selectedAvatar.id })
                    .then(() => {
                        this.fetchUser().then(() => {
                            this.saving = false;
                        });
                    }).catch(error => {
                        this.errors = error.data;
                        this.saving = false;
                    });
            },

            selectAvatar(avatar) {
                this.selectedAvatar = avatar;
            },

            uploadComplete() {
                this.getAvatars().then(() => {
                    this.selectAvatar(this.avatars[0]);
                    this.save();
                });
            }
        }
    }
</script>

<style scoped>

</style>
