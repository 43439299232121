<template>
    <div>
        <h2 class="text-center mb-3">Reward Types</h2>
        <div class="links-grid mb-5" :class="cardSize">
            <router-link :to="allowLinks ? {name: 'illustrationRewards'} : {}">
                <b-card align="center" no-body tag="article">
                    <div :class="imgContainerType">
                        <b-card-img
                            src="https://starstickers.s3.eu-west-2.amazonaws.com/site/illustration_link.jpg"
                            alt="Illustration"
                            top>
                        </b-card-img>
                    </div>
                    <b-card-body>
                        <b-card-title>Illustration</b-card-title>
                        <b-card-text v-if="!useSmallIcons">
                            Looking to get your favorite characters drawn in my style? Go standard with regular lineart and color busts, or go bold with mystery boxes in which I get total creative freedom to take the wheel!
                        </b-card-text>
                        <router-link v-if="allowLinks && !useSmallIcons" :to="{name: 'illustrationRewards'}" class="btn btn-block btn-primary">View All</router-link>
                    </b-card-body>
                </b-card>
            </router-link>

            <router-link :to="allowLinks ? {name: 'merchandiseRewards'} : {}">
                <b-card align="center" no-body tag="article">
                    <div :class="imgContainerType">
                        <b-card-img
                                src="https://starstickers.s3.eu-west-2.amazonaws.com/site/merchandise_link.jpg"
                                alt="Merchandise"
                                top>
                        </b-card-img>
                    </div>
                    <b-card-body>
                        <b-card-title>Merchandise</b-card-title>
                        <b-card-text v-if="!useSmallIcons">
                            Ever wanted to have original prints of my art? This is the only place to get them!
                            You can currently get A4 and A5 prints of most art, as well as bookmarks of a selection of pieces. New options coming soon!
                        </b-card-text>
                        <router-link v-if="allowLinks && !useSmallIcons" :to="{name: 'merchandiseRewards'}" class="btn btn-block btn-primary">View All</router-link>
                    </b-card-body>
                </b-card>
            </router-link>

            <router-link :to="allowLinks ? {name: 'mentorshipRewards'} : {}">
                <b-card align="center" no-body tag="article">
                    <div :class="imgContainerType">
                        <b-card-img
                                src="https://starstickers.s3.eu-west-2.amazonaws.com/site/mentorship_link.jpg"
                                alt="Mentorship"
                                top>
                        </b-card-img>
                    </div>
                    <b-card-body>
                        <b-card-title>Mentorship</b-card-title>
                        <b-card-text v-if="!useSmallIcons">
                            Whether you’re just starting to draw or need a little help getting to the next level, my mentorship sessions are designed around you, your level and your current needs.
                        </b-card-text>
                        <router-link v-if="allowLinks && !useSmallIcons" :to="{name: 'mentorshipRewards'}" class="btn btn-block btn-primary">View All</router-link>
                    </b-card-body>
                </b-card>
            </router-link>

            <router-link :to="allowLinks ? {name: 'tokenRewards'} : {}">
                <b-card align="center" no-body tag="article">
                    <div :class="imgContainerType">
                        <b-card-img
                                src="https://starstickers.s3.eu-west-2.amazonaws.com/site/discounts_link.jpg"
                                alt="Tokens"
                                top>
                        </b-card-img>
                    </div>
                    <b-card-body>
                        <b-card-title>Discounts & Tokens</b-card-title>
                        <b-card-text v-if="!useSmallIcons">
                            The perfect addition to any commission or reward. Whether you want to reduce the cost of a commission or spice up a project in the works, tokens are a good way to spend points if you already have something booked.
                        </b-card-text>
                        <router-link v-if="allowLinks && !useSmallIcons" :to="{name: 'tokenRewards'}" class="btn btn-block btn-primary">View All</router-link>
                    </b-card-body>
                </b-card>
            </router-link>
        </div>

        <!-- FAQs -->
<!--        <div class="w-100">-->
<!--            <faqs></faqs>-->
<!--        </div>-->
    </div>
</template>

<script>
    export default {
        name: "AllRewardsLinks",

        props: {
            useSmallIcons: {
                type: Boolean,
                default: true
            },
            allowLinks: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            cardSize() {
                if (this.useSmallIcons) {
                    return 'small-cards';
                }
                return 'large-cards';
            },

            imgContainerType() {
                if (this.useSmallIcons) {
                    return 'links-img-container';
                }
                return 'card-img-container';
            }
        }
    }
</script>

<style scoped>

</style>
