<template>
    <div class="page-content">
        <div class="container">
            <empty-state
                    icon="user-cog"
                    :title="title"
                    white
            >
                <div class="row">
                    <div class="col-10 offset-1" v-if="userState === 'suspended'">
                        <p>You're seeing this page because you're no longer pledging to Onyrica on Patreon and your account has been temporarily frozen.</p>
                        <p v-if="suspendedUserPoints > 0">
                            Don't worry though! Your <strong>{{ suspendedUserPoints }}</strong> points are still in your account and will remain intact for 3 months after you deleted your pledge. Should you wish to conserve them past that date, please note that you'll have to re-pledge on any tier.
                        </p>
                        <p>If you have any questions you're welcome to contact me on hello@onyrica.art and I'll get back to you as soon as possible.</p>
                        <p>
                            With my best wishes,<br/>
                            Ony
                        </p>
                    </div>
                    <div class="col-10 offset-1" v-if="userState === 'pending'">
                        <p>Thank you for creating an account on StarStickers- a reward portal you get access to exclusively by being Onyrica’s Patreon supporter.</p>
                        <p>Please bear with us while we review your account and allocate you any points you’ve earned so far. Once your profile has been approved, you’ll receive an email letting you know you have full access to StarStickers.</p>
                        <p>
                            Many thanks and hugs,<br/>
                            Ony
                        </p>
                    </div>
                    <div class="col-md-2 offset-md-5">
                        <b-button variant="danger" class="mt-3" block @click="logout()">
                            Log Out
                            <i class="fal fa-sign-out"></i>
                        </b-button>
                    </div>
                </div>
            </empty-state>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "UserInactive",

        computed: {
            ...mapGetters(['userState', 'suspendedUserPoints']),

            title() {
                if (this.userState === 'pending') {
                    return "Thanks for creating your account!";
                }
                return "Hey there, thanks for visiting StarStickers.";
            }
        },

        methods: {
            ...mapActions([
                'clearRewardSlots',
                'clearActiveBundleRuns',
                'clearCompany',
                'clearMerchandise',
                'clearRewards',
                'clearUser'
            ]),

            logout() {
                Promise.all([
                    this.clearRewardSlots(),
                    this.clearActiveBundleRuns(),
                    this.clearCompany(),
                    this.clearMerchandise(),
                    this.clearRewards(),
                    this.clearUser()
                ]).then(() => {
                    window.location = '/logout';
                });
            }
        }
    }
</script>

<style scoped>

</style>
