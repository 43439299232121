<template>
    <b-modal
            body-class="p-0"
            content-class="shadow"
            hide-footer
            hide-header
            :id="reward.code"
            modal-class="reward-modal"
            no-stacking
            size="lg">

        <b-button class="modal-close fal fa-times" variant="danger" @click="close()"></b-button>
        <div class="d-flex flex-wrap">

            <!-- Image Carousel -->
            <div class="col-md-6 p-0">
                <b-carousel
                        v-model="slide"
                        controls
                        indicators
                        :interval="4000"
                        @sliding-start="onSlideStart"
                        @sliding-end="onSlideEnd"
                >
                    <b-carousel-slide
                            v-for="example in reward.examples"
                            :key="example.id"
                            :img-src="example.path">
                    </b-carousel-slide>
                </b-carousel>
            </div>

            <!-- Modal Content -->
            <div class="col-md-6 p-0">
                <div class="modal-body">
                    <h3>{{ reward.title }}</h3>
                    <h4 class="text-primary">{{ reward.points_cost }} Points</h4>

                    <!-- Merchandise Selection -->
                    <template v-if="canRedeem(reward) && !confirmingAddress && !requestingRedemption">
                        <div v-if="!requestingRedemption" v-html="reward.description"></div>

                        <!-- Merchandise Information -->
                        <div v-if="reward.code !== 'artbook-bundle'">
                            This bundle comes with the below options:
                            <ul>
                                <li v-for="item in reward.merchandise_items" :key="item.id">
                                    {{ itemInformation(item) }}
                                </li>
                            </ul>
                        </div>

                        <div class="row" v-if="hasSelectableOptions">
                            <div class="col-6">
                                The format:
                            </div>
                            <div class="col-6">
                                Of the illustration:
                            </div>
                        </div>

                        <div class="row bundle-option-selection pt-2 pb-2" v-for="(item, key) in merchandiseItems" :key="key" v-if="!item.format || !item.line">
                            <!-- Select Format -->
                            <div class="col-6">
                                <p v-if="item.format" class="mb-0">
                                    {{ item.format.title }}
                                </p>
                                <b-form-select
                                        v-if="!item.format"
                                        :id="`merchandise-format-${key}`"
                                        v-model="item.format_id"
                                        :options="formatsByLine(item.line_id, 'Select a format')"
                                        size="sm">
                                </b-form-select>
                            </div>

                            <!-- Select Line -->
                            <div class="col-6">
                                <p v-if="item.line" class="mb-0">
                                    {{ item.line.title }}
                                </p>
                                <b-form-select
                                        v-if="!item.line"
                                        :id="`merchandise-line-${key}`"
                                        v-model="item.line_id"
                                        :options="linesByFormat(item.format_id, 'Select an illustration')"
                                        size="sm">
                                </b-form-select>
                            </div>
                        </div>

                        <b-alert :show="hasErrors" variant="danger" class="d-flex">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </b-alert>

                        <div class="redemption-controls mt-2">
                            <b-button variant="success" block v-if="selectionsMade" @click="confirmAddress()">Confirm your address</b-button>
                            <b-button variant="success" block disabled v-if="!selectionsMade">Complete your selections</b-button>
                        </div>
                    </template>

                    <!-- Confirm Address -->
                    <template v-if="canRedeem(reward) && confirmingAddress && !requestingRedemption">
                        <p class="mb-1">
                            Please enter the address you'd like your bundle delivered to into the fields below.
                        </p>

                        <b-button v-if="this.user.address && this.user.address.line_1" variant="link" class="text-xs" @click="setAddressToUserAddress()">
                            Use my last used address: {{ user.address.line_1 }}<span v-if="user.address.line_2">, {{ user.address.line_2 }}</span>
                        </b-button>

                        <div class="row">
                            <div class="col-md-12">
                                <b-form-group
                                        label="Full Name"
                                        label-for="full_name"
                                        label-size="sm"
                                >
                                    <b-form-input id="full_name" v-model="address.full_name" size="sm"trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6" v-if="false">
                                <b-form-group
                                        label="Phone Number (optional)"
                                        label-for="phone_number"
                                        label-size="sm"
                                >
                                    <b-form-input id="phone_number" v-model="address.phone_number" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <b-form-group
                                        label="Address Line 1"
                                        label-for="line_1"
                                        label-size="sm"
                                >
                                    <b-form-input id="line_1" v-model="address.line_1" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group
                                        label="Address Line 2 (optional)"
                                        label-for="line_2"
                                        label-size="sm"
                                >
                                    <b-form-input id="line_2" v-model="address.line_2" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group
                                        label="Town / City"
                                        label-for="city"
                                        label-size="sm"
                                >
                                    <b-form-input id="city" v-model="address.city" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group
                                        label="County / State (optional)"
                                        label-for="county"
                                        label-size="sm"
                                >
                                    <b-form-input id="county" v-model="address.county" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group
                                        label="Postcode / Zip Code"
                                        label-for="postcode"
                                        label-size="sm"
                                >
                                    <b-form-input id="postcode" v-model="address.postcode" size="sm" trim></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-6">
                                <b-form-group
                                        label="Country"
                                        label-for="country"
                                        label-size="sm"
                                >
                                    <b-form-select v-model="address.country" :options="countries" size="sm"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>

                        <div class="redemption-controls mt-2">
                            <b-button variant="success" block v-if="addressConfirmed" @click="requestRedemption()">Redeem Now for {{ reward.points_cost }} Points</b-button>
                            <b-button variant="success" block disabled v-if="!addressConfirmed">Complete your address</b-button>
                            <b-button variant="link" block @click="cancelRequest()">I've Changed My Mind</b-button>
                        </div>
                    </template>

                    <!-- Confirm Redemption -->
                    <template v-if="(canRedeem(reward) || redeeming) && !confirmingAddress && requestingRedemption">
                        <label>Before you redeem</label>
                        <div v-html="reward.type.redemption_note"></div>

                        <label>Your bundle</label>
                        <ul class="mb-2">
                            <li v-for="item in selectedItemInformation" :key="item.id">
                                {{ item.qty }}x {{ item.text }}
                            </li>
                        </ul>

                        <label>Will be sent to</label>
                        <p>
                            {{ addressString }}
                        </p>

                        <div class="redemption-controls">
                            <table class="redemption-table">
                                <tr>
                                    <td>Reward Cost</td>
                                    <td class="cost reward-cost">{{ reward.points_cost }}</td>
                                </tr>
                                <tr>
                                    <td>Current Points</td>
                                    <td class="cost">{{ user.points }}</td>
                                </tr>
                                <tr>
                                    <td>Points After Reward</td>
                                    <td class="cost">{{ totalAfterRedemption(reward) }}</td>
                                </tr>
                            </table>
                            <b-button variant="success" class="mb-3" block @click="redeem()" :disabled="redeeming">
                                <i v-if="redeeming" class="fa fa-pulse fa-spinner"></i>
                                Redeem Now for {{ reward.points_cost }} Points
                            </b-button>
                            <b-button variant="link" block @click="cancelRequest()">I've Changed My Mind</b-button>
                        </div>
                    </template>

                    <!-- Can't redeem -->
                    <template v-if="!canRedeem(reward) && !redeeming">
                        <div v-if="!requestingRedemption" v-html="reward.description"></div>

                        <!-- Merchandise Information -->
                        <div v-if="reward.code !== 'artbook-bundle'">
                            This bundle comes with the below options:
                            <ul>
                                <li v-for="item in reward.merchandise_items" :key="item.id">
                                    {{ itemInformation(item) }}
                                </li>
                            </ul>
                        </div>

                        <hr class="divider">

                        <empty-state
                                icon="paint-brush"
                                title=""
                                :subtitle="cannotRedeemMessage(reward)">
                        </empty-state>
                    </template>
                </div>
            </div>

        </div>
    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props:['reward'],

        data: function() {
            return {
                errors: {},
                merchandiseItems: [],
                address: {
                    full_name: null,
                    phone_number: null,
                    line_1: null,
                    line_2: null,
                    city: null,
                    county: null,
                    postcode: null,
                    country: null
                },
                confirmingAddress: false,
                requestingRedemption: false,
                redeeming: false,
                slide: 0,
                sliding: null
            }
        },

        computed: {
            ...mapGetters([
                'cannotRedeemMessage',
                'canRedeem',
                'countries',
                'formats',
                'formatsByLine',
                'lines',
                'linesByFormat',
                'totalAfterRedemption',
                'user',
            ]),

            hasErrors() {
                return Object.keys(this.errors).length > 0;
            },

            hasSelectableOptions() {
                for (let i = 0; i < this.merchandiseItems.length; i++) {
                    if (
                        !this.merchandiseItems[i].line || !this.merchandiseItems[i].format) {
                        return true;
                    }
                }
                return false;
            },

            selectionsMade() {
                for (let i = 0; i < this.merchandiseItems.length; i++) {
                    if (!this.merchandiseItems[i].format_id || !this.merchandiseItems[i].line_id) {
                        return false;
                    }
                }
                return true;
            },

            selectedItemInformation() {
                let items = {};

                for (let i = 0; i < this.merchandiseItems.length; i++) {

                    let key = `${this.merchandiseItems[i].format_id}${this.merchandiseItems[i].line_id}`;

                    if (items[key] === undefined) {

                        let format = '';
                        for (let j = 0; j < this.formats.length; j++) {
                            if (this.formats[j].id === this.merchandiseItems[i].format_id) {
                                format = this.formats[j].title;
                            }
                        }

                        let line = '';
                        for (let j = 0; j < this.lines.length; j++) {
                            if (this.lines[j].id === this.merchandiseItems[i].line_id) {
                                line = this.lines[j].title;
                            }
                        }

                        items[key] = {
                            qty: 0,
                            text: `${format} of ${line}`
                        }
                    }

                    items[key].qty++;
                }

                return items;
            },

            addressConfirmed() {
                return (
                    this.address.full_name &&
                    this.address.line_1 &&
                    this.address.city &&
                    this.address.postcode &&
                    this.address.country
                )
            },

            addressString() {
                let address = `${this.address.full_name}, ${this.address.line_1}`;
                if (this.address.line_2) {
                    address += `, ${this.address.line_2}`;
                }
                address += `, ${this.address.city}`;
                if (this.address.county) {
                    address += `, ${this.address.county}`;
                }
                address += `, ${this.address.postcode}, ${this.address.country}`;
                return address;
            }
        },

        mounted() {
            this.setMerchandiseSelections();
        },

        methods: {
            ...mapActions(['fetchFormats', 'fetchLines', 'redeemReward']),

            setMerchandiseSelections() {
                for (let i = 0; i < this.reward.merchandise_items.length; i++) {
                    for (let j = 0; j < this.reward.merchandise_items[i].quantity; j++) {
                        let item = {
                            item_id: this.reward.merchandise_items[i].id,
                            format: null,
                            format_id: null,
                            line: null,
                            line_id: null
                        };

                        if (this.reward.merchandise_items[i].merchandise_format) {
                            item.format = this.reward.merchandise_items[i].merchandise_format;
                            item.format_id = this.reward.merchandise_items[i].merchandise_format.id;
                        }

                        if (this.reward.merchandise_items[i].merchandise_line) {
                            item.line = this.reward.merchandise_items[i].merchandise_line;
                            item.line_id = this.reward.merchandise_items[i].merchandise_line.id;
                        }

                        this.merchandiseItems.push(item);
                    }
                }
            },

            redeem() {
                this.redeeming = true;
                this.redeemReward({
                    reward: this.reward,
                    merchandiseItems: this.merchandiseItems,
                    address: this.address
                }).then(() => {
                    this.$emit('redeemed', this.reward);
                    this.reset();
                }).catch(error => {
                    this.errors = error.data;
                    this.requestingRedemption = false;
                    this.confirmingAddress = false;
                    this.redeeming = false;
                });
            },

            confirmAddress() {
                this.requestingRedemption = false;
                this.confirmingAddress = true;
            },

            requestRedemption() {
                this.confirmingAddress = false;
                this.requestingRedemption = true;
            },

            cancelRequest() {
                this.reset();
                this.confirmingAddress = false;
                this.requestingRedemption = false;
            },

            onSlideStart(slide) {
                this.sliding = true
            },

            onSlideEnd(slide) {
                this.sliding = false
            },

            close() {
                this.$bvModal.hide(this.reward.code);
                this.reset();
            },

            reset() {
                this.errors = {};
                this.confirmingAddress = false;
                this.requestingRedemption = false;
                this.redeeming = false;
                this.address = {
                    full_name: null,
                        phone_number: null,
                        line_1: null,
                        line_2: null,
                        city: null,
                        county: null,
                        postcode: null,
                        country: null
                };
            },

            itemInformation(item) {
                if (!item.merchandise_format && !item.merchandise_line) {
                    return `${item.quantity}x any format of any illustration`;
                }
                if (item.merchandise_format && item.merchandise_line) {
                    return `${item.quantity}x ${item.merchandise_format.title} of ${item.merchandise_line.title}`;
                }
                if (item.merchandise_format && !item.merchandise_line) {
                    return `${item.quantity}x ${item.merchandise_format.title} of any illustration`;
                }
                if (!item.merchandise_format && item.merchandise_line) {
                    return `${item.quantity}x any format of ${item.merchandise_line.title}`;
                }
            },

            setAddressToUserAddress() {
                this.address = this.user.address
            }
        }
    }
</script>

<style scoped>

</style>
