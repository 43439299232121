/**
 * State
 */
const state = {
    countries: []
};

/**
 * Getters
 */
const getters = {
    countries: state => {
        return state.countries;
    },

    pluralize: () => (word, count) => {
        return (count === 0 || count > 1) ? `${word}s` : word;
    }
};

/**
 * Actions
 */
const actions = {
    displayToast({}, {text, type = 'info', duration = 5000, dismissible = true}) {
        return new Promise((resolve) => {
            type = (type === 'danger') ? 'error' : type;

            let toastSettings = {
                containerClass: "custom-toast",
                position: "top-right",
                type: type,
                duration: duration,
                closeOnSwipe: false,
                icon: {
                    name: ''
                }
            };

            if (dismissible) {
                toastSettings.action = {
                    icon: 'times',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    }
                };
            }

            switch (type) {
                case 'success':
                    toastSettings.icon.name = 'check-circle';
                    break;
                case 'warning':
                    toastSettings.icon.name = 'exclamation-circle';
                    break;
                case 'danger':
                case 'error':
                    toastSettings.icon.name = 'times-circle';
                    break;
                default:
                    toastSettings.icon.name = 'info-circle';
            }

            Vue.toasted.show(text, toastSettings);

            resolve();
        });
    },

    deleteFile({dispatch}, uuid) {
        return new Promise((resolve, reject) => {
            axios.delete(`/web/files/${uuid}`)
                .then(() => {
                    dispatch('displayToast', {text: 'Your file was successfully deleted.', type: 'success'});
                    resolve();
                })
                .catch(error => {
                    dispatch('displayToast', {text: 'Could not delete your file. Try again.', type: 'error'});
                    reject(error);
                });
        });
    },

    deleteNote({dispatch}, uuid) {
        return new Promise((resolve, reject) => {
            axios.delete(`/web/notes/${uuid}`)
                .then(() => {
                    dispatch('displayToast', {text: 'Your note was successfully deleted.', type: 'success'});
                    resolve();
                })
                .catch(error => {
                    dispatch('displayToast', {text: 'Could not delete your note. Try again.', type: 'error'});
                    reject(error);
                });
        });
    },

    fetchCountries({commit, getters, rootGetters}) {
        return new Promise((resolve, reject) => {
            if (rootGetters.countries.length) {
                return resolve();
            }

            axios.get('https://restcountries.com/v3.1/all')
                .then(response => {
                    let countries = [];
                    for (let i = 0; i < response.data.length; i++) {
                        let country = response.data[i].name.common;
                        countries.push({value: country, text: country});
                    }

                    commit('SET_COUNTRIES', countries.sort((a, b) => a.value.localeCompare(b.value)));

                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
};

/**
 * Mutations
 */
const mutations = {
    SET_COUNTRIES(state, countries) {
        state.countries = countries;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
