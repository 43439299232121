<template>
    <div>
        <h2 class="text-center mb-4">Featured Rewards</h2>
        <div class="card-grid mb-5">
            <b-card
                v-for="reward in featuredRewards"
                :key="reward.id"
                align="center"
                no-body
                tag="article">
                <div class="card-img-container">
                    <b-card-img
                        :src="cardImage(reward)"
                        :alt="reward.title"
                        top>
                    </b-card-img>
                </div>
                <b-card-body>
                    <b-card-title>{{ reward.title }}</b-card-title>
                    <h5 class="text-primary">{{ reward.points_cost }} Points</h5>

                    <b-button v-if="isAvailable(reward)" variant="danger" block @click="$bvModal.show(reward.code)">{{ redeemTitle(reward) }}</b-button>

                    <b-button v-if="!isAvailable(reward)" variant="primary" block @click="$bvModal.show(reward.code)">{{ cannotRedeemTitle(reward) }}</b-button>

                    <small v-if="displaySlotApplicationCount(reward)" class="mt-1 mb-0">Total Applications: {{ currentSlotApplicationCount(reward) }}</small>

                </b-card-body>
            </b-card>
        </div>

        <div v-if="false">
            <!--                    <h2 class="text-center mb-4">Art Rewards</h2>-->
            <!--                    <div class="card-grid mb-5">-->
            <!--                        <b-card-->
            <!--                                v-for="reward in illustrationRewards"-->
            <!--                                :key="reward.id"-->
            <!--                                align="center"-->
            <!--                                no-body-->
            <!--                                tag="article">-->
            <!--                            <div class="card-img-container">-->
            <!--                                <b-card-img-->
            <!--                                        :src="cardImage(reward)"-->
            <!--                                        :alt="reward.title"-->
            <!--                                        top>-->
            <!--                                </b-card-img>-->
            <!--                            </div>-->
            <!--                            <b-card-body>-->
            <!--                                <b-card-title>{{ reward.title }}</b-card-title>-->
            <!--                                <h5 class="text-primary">{{ reward.points_cost }} Points</h5>-->
            <!--                                <b-button variant="danger" block @click="$bvModal.show(reward.code)">Redeem Reward</b-button>-->
            <!--                            </b-card-body>-->
            <!--                        </b-card>-->

            <!-- More coming soon card -->
            <!--                    <b-card-->
            <!--                            align="center"-->
            <!--                            no-body-->
            <!--                            tag="article">-->
            <!--                        <b-card-body class="coming-soon-card">-->
            <!--                            <b-card-title>More rewards coming soon!</b-card-title>-->
            <!--                            <h6 class="text-primary">Check back later to see what else we've got planned.</h6>-->
            <!--                        </b-card-body>-->
            <!--                    </b-card>-->
            <!--                    </div>-->

            <!--                    <h2 class="text-center mb-3">Support Rewards</h2>-->
            <!--                    <div class="card-grid mb-5">-->
            <!--                        <b-card-->
            <!--                                v-for="reward in otherRewards"-->
            <!--                                :key="reward.id"-->
            <!--                                align="center"-->
            <!--                                no-body-->
            <!--                                tag="article">-->
            <!--                            <div class="card-img-container">-->
            <!--                                <b-card-img-->
            <!--                                        :src="cardImage(reward)"-->
            <!--                                        :alt="reward.title"-->
            <!--                                        top>-->
            <!--                                </b-card-img>-->
            <!--                            </div>-->
            <!--                            <b-card-body>-->
            <!--                                <b-card-title>{{ reward.title }}</b-card-title>-->
            <!--                                <h5 class="text-primary">{{ reward.points_cost }} Points</h5>-->
            <!--                                <b-button variant="danger" block @click="$bvModal.show(reward.code)">Redeem Reward</b-button>-->
            <!--                            </b-card-body>-->
            <!--                        </b-card>-->
            <!--                    </div>-->
        </div>

        <!--------------------
         Modals
        ---------------------->
        <component
            v-for="reward in rewards"
            :key="reward.id"
            :reward="reward"
            :is="rewardModalType(reward)"
            :show-dates="true"
            @redeemed="showRedeemedModal"
        ></component>

        <reward-redeemed-modal
            ref="rewardRedeemedModal"
        ></reward-redeemed-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: "FeaturedRewards",

        computed: {
            ...mapGetters([
                'availableSlotsCardText',
                'displaySlotApplicationCount',
                'currentSlotApplicationCount',
                'isAvailable',
                'redeemTitle',
                'rewards',
                'user'
            ]),

            featuredRewards() {
                let rewards = this.rewards.filter(reward => {
                    return (reward.featured === 1);
                });

                return rewards.sort((a, b) => a['featured_priority'] - b['featured_priority']);
            },
        },

        methods: {
            ...mapActions(['fetchUser']),

            cardImage(reward) {
                if (reward.code === 'discord-emoji-x1' || reward.code === 'discord-emoji-x5') {
                    return reward.examples[1].path; // TODO: This is a dirty hack so do something about it
                }
                return reward.examples[0].path;
            },

            cannotRedeemTitle(reward) {
                if (reward.requires_available_slot) {
                    return "Applications Closed";
                }

                return "Unavailable";
            },

            rewardModalType(reward) {
                if (reward.type.title === 'Merchandise') {
                    return 'redeem-merchandise-reward-modal';
                }
                if (reward.requires_available_slot) {
                    return 'redeem-reward-large-modal';
                }
                return 'redeem-reward-small-modal';
            },

            showRedeemedModal(reward) {
                this.fetchUser();
                this.$refs.rewardRedeemedModal.open(reward);
            }
        }
    }
</script>

<style scoped>

</style>
