<template>

     <router-link :to="{ name: route}">
          <li :class="useClass">
               <span v-if="hasLabel" v-html="label"></span>
               <i v-if="!hasLabel" class="la" :class="icon"></i>
          </li>
     </router-link>

</template>

<script>
    export default {
         props: {
              route: {
                   type: String,
                   require: true,
              },
              label: {
                   type: String,
                   default: '',
              },
              icon: {
                   type: String,
                   default: ''
              },
              useClass: {
                   type: String,
                   default: ''
              }
         },

         computed: {
              hasLabel() {
                  return this.label !== '';
              },


         }
    }
</script>

<style scoped>

</style>