<template>
    <b-modal
        body-class="p-0"
        content-class="shadow"
        hide-footer
        hide-header
        id="reward-redeemed-modal"
        modal-class="reward-modal vertical"
        no-close-on-backdrop>

        <div class="d-flex flex-wrap">
            <div class="col-md-12 p-0">

                <!-- Banner -->
                <redeemed-banner></redeemed-banner>

                <!-- Modal Content -->
                <div class="modal-body text-center" v-if="reward">
                    <template v-if="reward.requires_available_slot">
                        <h3>You've Applied For A Reward!</h3>
                        <h5 class="mb-4">What Happens Next?</h5>
                        <p v-html="reward.applied_text"></p>
                    </template>

                    <template v-if="!reward.requires_available_slot">
                        <h3>You've Redeemed Your Reward!</h3>
                        <h5 class="mb-4">What Happens Next?</h5>
                        <p v-html="reward.booked_text"></p>
                    </template>
                    <b-button variant="danger" block @click="close()">Close</b-button>
                </div>
            </div>

        </div>

    </b-modal>
</template>

<script>
    export default {
        data: function() {
            return {
                reward: null
            }
        },

        methods: {
            open(reward) {
                this.reward = reward;
                this.$bvModal.show('reward-redeemed-modal');
            },

            close() {
                this.reward = null;
                this.$bvModal.hide('reward-redeemed-modal');
            }
        }
    }
</script>

<style scoped>

</style>
