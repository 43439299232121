<template>
    <b-modal
            content-class="shadow"
            hide-footer
            hide-header
            id="create-reward-slots-modal"
            no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('create-reward-slots-modal')"></b-button>

        <template v-if="!createdRewardSlotUuid">
            <h3>Create Reward Slots</h3>

            <b-alert :show="hasErrors" variant="danger" class="d-flex">
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </b-alert>

            <div class="form-group">
                <label for="select-reward" class="col-form-label">Select Reward</label>
                <select id="select-reward" class="form-control" v-model="rewardSlots.reward_id">
                    <option :value="null">Select a reward</option>
                    <option v-for="reward in availableRewards" :value="reward.reward_uuid">{{ reward.reward_type }}: {{ reward.reward_title }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="quantity" class="col-form-label">Quantity of Slots</label>
                <input id="quantity" class="form-control" type="number" min="1"  v-model="rewardSlots.quantity" />
            </div>

            <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" v-model="rewardSlots.display_slot_quantity" id="display-slot-quantity">
                <label class="form-check-label" for="display-slot-quantity">
                    Display the slot quantity to users
                </label>
            </div>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="rewardSlots.create_paused" id="create-paused">
                <label class="form-check-label" for="create-paused">
                    Create in Paused state
                </label>
            </div>
            <small>A paused reward slot will not yet be available for users to apply for.</small>

            <b-button variant="success" class="mt-3" block @click="createRewardSlots()" :disabled="saving">
                <i v-if="saving" class="fa fa-pulse fa-spinner"></i>
                Save Reward Slots
            </b-button>
        </template>

        <template v-if="createdRewardSlotUuid">
            <empty-state
                icon="party-horn"
                title=""
                subtitle="Your reward slots have been created!">
            </empty-state>

            <router-link :to="{ name: 'companyRewardSlots.rewardSlot', params: { uuid: createdRewardSlotUuid } }" class="btn btn-block btn-primary btn-sm">View Created Reward Slots</router-link>
        </template>

    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        props: {
            availableRewards: {
                type: Array,
                required: true,
                default: []
            }
        },

        data() {
            return {
                createdRewardSlotUuid: null,

                rewardSlots: {
                    reward_id: null,
                    quantity: 1,
                    display_slot_quantity: true,
                    create_paused: true
                },

                saving: false,
                errors: []
            }
        },

        computed: {
            ...mapGetters(['user']),

            hasErrors() {
                return Object.keys(this.errors).length > 0;
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            open() {
                this.saving = false;
                this.errors = [];

                this.createdRewardSlotUuid = null;
                this.rewardSlots = {
                    reward_id: null,
                    quantity: 1,
                    display_slot_quantity: true,
                    create_paused: true
                };

                this.$bvModal.show('create-reward-slots-modal');
            },

            createRewardSlots() {
                this.errors = [];
                this.saving = true;

                axios.post(route('web.rewardSlots.store'), this.rewardSlots)
                    .then(response => {
                        this.displayToast({text: `Reward slots created.`, type: 'success'});
                        this.createdRewardSlotUuid = response.data.uuid;
                        this.$emit('reload');

                    }).catch(errors => {
                        console.log(errors);
                        this.displayToast({text: `Could not create reward slots.`, type: 'danger'});
                        this.errors = errors.response.data;
                        this.saving = false;
                    })
            }
        }
    }
</script>

<style scoped>

</style>
