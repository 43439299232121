<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Company Rewards
                </h3>
            </div>
        </div>
        <empty-state
                icon="space-station-moon-alt"
                title="Under Construction"
                subtitle="We'll have this up and running as soon as possible">
        </empty-state>
    </div>
</template>

<script>
    export default {
        name: "CompanyRewards"
    }
</script>

<style scoped>

</style>
