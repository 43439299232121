<template>
    <div :class="'img-' + size" class="status-icon loading-animated">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle cx="65.1" cy="65.1" r="62.1" fill="none" stroke-width="6" stroke-miterlimit="10" class="path circle"/></svg>
    </div>
</template>

<script>
    export default {
        name: "LoadingAnimated",

        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>
