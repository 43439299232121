<template>
    <div>
        <div v-if="userId" class="row">
            <div class="col-md-12">
                <router-link :to="{name: 'userSettings', params: { userId: userId }}" class="btn btn-primary float-right">
                    <i class="fal fa-user"></i> User Settings
                </router-link>
            </div>
        </div>

        <b-tabs pills v-if="!loading">

            <!-- Points History -->
            <b-tab title="Points Earned" @click="setTab('points')" :active="tabIsActive('points')">

                <h2 class="my-3">Points Earned</h2>

                <p class="mb-4">
                    Below is a list of all the points you’ve accumulated since pledging to Onyrica’s Patreon. Please note that some points are applied manually and may take a while to appear in your account.<br/>
                    <a href="mailto:hello@onyrica.art">Something missing? Get in touch.</a>
                </p>

                <b-card
                    v-if="thisUserPointEvents.length > 0 && canDisplayPointEvent(pointEvent)"
                    v-for="pointEvent in thisUserPointEvents"
                    :key="pointEvent.point_event_id"
                    class="reward-card">
                    <div class="media">
                        <div class="media-left icon art-challenge">
                            <i :class="iconClasses(pointEvent.point_event_type_icon)"></i>
                        </div>
                        <div class="media-body">
                            <h5 class="title">{{ pointEvent.point_event_type }}</h5>
                            <p v-if="pointEvent.note" class="description">
                                {{ pointEvent.note }}
                            </p>
                            <p class="date">
                                {{ parseDate(pointEvent.created_at) }}
                            </p>
                        </div>
                        <div class="media-right points-earned">
                            <strong class="points">+{{ pointEvent.points }}</strong>
                            Points
                        </div>
                    </div>
                </b-card>
                <empty-state
                    v-if="thisUserPointEvents.length === 0"
                    icon="star"
                    title="No points history...yet"
                    subtitle="Check back later once you've earned some points">
                </empty-state>

            </b-tab>

            <!-- Patreon History -->
            <b-tab title="Patreon History" @click="setTab('history')" :active="tabIsActive('history')">

                <h2 class="my-3">Patreon History</h2>

                <p class="mb-4">
                    Below is a list of any tier changes you’ve made since pledging to Onyrica’s Patreon.<br/>
                    <a href="mailto:hello@onyrica.art">Something missing? Get in touch.</a>
                </p>

                <b-card
                    v-if="thisUserHistories.length > 0"
                    v-for="(history, key) in thisUserHistories"
                    :key="history.id"
                    class="reward-card">
                    <div class="media">
                        <div class="media-left icon patreon-upgrade">
                            <i class="fab fa-patreon" v-if="!isOnboardingHistory(key)"></i>
                            <i class="fal fa-star-shooting fa-rotate-180" v-if="isOnboardingHistory(key)"></i>
                        </div>
                        <div class="media-body">
                            <h5 class="title" v-if="!isOnboardingHistory(key)">Updated to {{ history.level_title }}</h5>
                            <h5 class="title" v-if="isOnboardingHistory(key)">Joined Star Stickers on {{ history.level_title }}</h5>
                            <p class="date">
                                {{ parseDate(history.created_at) }}
                            </p>
                        </div>
                        <div class="media-right points-earned" v-if="!isOnboardingHistory(history)">
                            <strong class="points">{{ history.level_points }}</strong>
                            Points a Month
                        </div>
                    </div>
                </b-card>
                <empty-state
                    v-if="thisUserHistories.length === 0"
                    brandIcon
                    icon="patreon"
                    title="No Patreon history...yet"
                    subtitle="Check back later once you've done some stuff on Patreon">
                </empty-state>

            </b-tab>

            <!-- Reward History -->
            <b-tab title="Reward History" @click="setTab('rewards')" :active="tabIsActive('rewards')">

                <h2 class="my-3">Reward History</h2>

                <p class="mb-4">
                    Below is a list of all the rewards you’ve claimed since pledging to Onyrica’s Patreon. You can view the current status of pending or ongoing rewards here.<br/>
                    <a href="mailto:hello@onyrica.art">Something missing? Get in touch.</a>
                </p>

                <b-card
                    v-if="thisUserRewards.length > 0"
                    v-for="reward in thisUserRewards"
                    :key="reward.user_reward_id"
                    class="reward-card">
                    <b-link :to="{name: 'reward', params: {uuid: reward.user_reward_id}}">
                        <span class="badge pending-badge" v-if="reward.status !== 'Complete'">{{ reward.status }}</span>
                        <div class="media">
                            <div class="media-left icon art-challenge">
                                <i :class="iconClasses(reward.reward_icon)"></i>
                            </div>
                            <div class="media-body">
                                <h5 class="title">{{ reward.title }}</h5>
                                <p class="date">
                                    Redeemed: {{ parseDate(reward.created_at) }} | {{ statusText(reward, false) }}
                                </p>
                            </div>
                            <div class="media-right points-earned">
                                <strong class="points">-{{ reward.points_cost }}</strong>
                                Points
                            </div>
                        </div>
                    </b-link>
                </b-card>
                <empty-state
                    v-if="thisUserRewards.length === 0"
                    icon="gift"
                    title="No rewards history...yet"
                    subtitle="Check back later once you've claimed some rewards">
                </empty-state>

            </b-tab>

            <!-- Active Applications -->
            <b-tab title="Applications" @click="setTab('applications')" :active="tabIsActive('applications')">

                <h2 class="my-3">Current Applications</h2>

                <p class="mb-4">
                    Below is a list of your current active applications for rewards.<br/>
                    <a href="mailto:hello@onyrica.art">Something missing? Get in touch.</a>
                </p>

                <b-card
                    v-if="thisUserApplications.length > 0"
                    v-for="(application, i) in thisUserApplications"
                    :key="i"
                    class="reward-card">
                    <div class="media">
                        <div class="media-left icon art-challenge">
                            <i :class="iconClasses(application.reward_icon)"></i>
                        </div>
                        <div class="media-body">
                            <p class="text-muted mb-0"><strong>{{ application.reward_type }}</strong></p>
                            <h3 class="title">{{ application.reward_title }}</h3>
                            <p class="text-muted mb-0">Applied: {{ application.date_applied }} - Points Cost: {{ application.points_cost }}</p>
                        </div>
                        <div class="media-right points-earned">
                            <button class="btn btn-danger"
                                    v-if="!cancellingApplication(application.application_id)"
                                    @click="cancelApplicationId = application.application_id">
                                Cancel Application
                            </button>
                            <button class="btn btn-danger"
                                    v-if="cancellingApplication(application.application_id)"
                                    @click="cancelApplication(application.application_id)">
                                <i class="fal fa-exclamation-circle" v-if="!processingApplicationCancellation"></i>
                                <i class="fal fa-spinner fa-pulse" v-if="processingApplicationCancellation"></i>
                                 {{ processingApplicationCancellation ? "Confirming Cancel Application" : "Confirm Cancel Application" }}
                            </button>
                        </div>
                    </div>
                </b-card>
                <empty-state
                    v-if="thisUserApplications.length === 0"
                    icon="gift"
                    title="No active applications"
                    subtitle="You application details will display here once you've applied for a reward">
                </empty-state>

            </b-tab>

            <!-- Merchandise History -->
            <b-tab title="Merchandise" @click="setTab('merchandise')" :active="tabIsActive('merchandise')">

                <h2 class="my-3">Merchandise Bundles</h2>

                <p class="mb-4">
                    Below is a list of all the merchandise bundles you’ve ordered since pledging to Onyrica’s Patreon. You can view the current status of pending or ongoing order here.<br/>
                    <a href="mailto:hello@onyrica.art">Something missing? Get in touch.</a>
                </p>

                <b-card
                        v-if="thisUserMerchandise.length > 0"
                        v-for="reward in thisUserMerchandise"
                        :key="reward.user_reward_id"
                        class="reward-card">
                    <b-link :to="{name: 'reward', params: {uuid: reward.user_reward_id}}">
                        <span class="badge pending-badge" v-if="reward.status !== 'Complete'">{{ reward.status }}</span>
                        <div class="media">
                            <div class="media-left icon art-challenge">
                                <i :class="iconClasses(reward.reward_icon)"></i>
                            </div>
                            <div class="media-body">
                                <h5 class="title">{{ reward.title }}</h5>
                                <p class="date">
                                    Redeemed: {{ parseDate(reward.created_at) }} | {{ statusText(reward, true) }}
                                </p>
                            </div>
                            <div class="media-right points-earned">
                                <strong class="points">-{{ reward.points_cost }}</strong>
                                Points
                            </div>
                        </div>
                    </b-link>
                </b-card>
                <empty-state
                        v-if="thisUserRewards.length === 0"
                        icon="gift"
                        title="No rewards history...yet"
                        subtitle="Check back later once you've claimed some rewards">
                </empty-state>

            </b-tab>
        </b-tabs>

        <empty-state
                v-if="loading"
                icon="hourglass-half"
                title="Loading..."
                subtitle="Getting the users details.">
        </empty-state>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.loadUserDetails();
            this.tab = this.startTab;
        },

        props: {
            userId: {
                default: null
            },
            startTab: {
                default: null
            }
        },

        data: function() {
            return {
                tab: null,
                loading: true,
                requestedUser: null,

                cancelApplicationId: null,
                processingApplicationCancellation: false
            }
        },

        computed: {
            ...mapGetters(['user', 'userHistories', 'userPointEvents', 'userRewards', 'isCompanyAdmin']),

            thisUserHistories() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser.histories;
                }
                return this.userHistories;
            },

            thisUserPointEvents() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser.point_events;
                }
                return this.userPointEvents;
            },

            thisUserRewards() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser.rewards.filter(reward => {
                        return (reward.type !== 'Merchandise');
                    });
                }
                return this.userRewards.filter(reward => {
                    return (reward.type !== 'Merchandise');
                });
            },

            thisUserApplications() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser.current_applications;
                }
                return this.user.current_applications;
            },

            thisUserMerchandise() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser.rewards.filter(reward => {
                        return (reward.type === 'Merchandise');
                    });
                }
                return this.userRewards.filter(reward => {
                    return (reward.type === 'Merchandise');
                });
            }
        },

        methods: {
            ...mapActions(['displayToast', 'fetchCompanyUser', 'fetchUser']),

            loadUserDetails() {
                if (this.userId) {
                    this.fetchRequestedCompanyUser();
                } else {
                    this.fetchCompanyUser().then(() => {
                        this.loading = false;
                        this.processingApplicationCancellation = false;
                        this.cancelApplicationId = null;
                    });
                }
            },

            fetchRequestedCompanyUser() {
                axios.get(`/web/company-users/${this.userId}/active`, { uuid: this.userId })
                    .then(response => {
                        this.requestedUser = response.data;
                        this.loading = false;
                        this.processingApplicationCancellation = false;
                        this.cancelApplicationId = null;
                    })
                    .catch(() => {
                        this.displayToast({text: 'Could not retrieve this user.', type: 'error'});
                    });
            },

            cancellingApplication(applicationUUid) {
                return (applicationUUid === this.cancelApplicationId);
            },

            cancelApplication(applicationUUid) {
                if (this.processingApplicationCancellation) return;

                axios.delete(`/web/reward-slot-applications/${applicationUUid}`)
                    .then(() => {
                        this.loadUserDetails();
                        this.fetchUser();
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.processingApplicationCancellation = false;
                    });
            },

            canDisplayPointEvent(pointEvent) {
                return (this.isCompanyAdmin || !pointEvent.date_points_written_off);
            },

            iconClasses(classes) {
                let string = (classes.includes('patreon')) ? 'fab ' : 'fal ';
                string += classes;
                return string;
            },

            isOnboardingHistory(historyKey) {
                return (historyKey === (this.thisUserHistories.length - 1));
            },

            parseDate(date) {
                return moment(date, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM, YYYY');
            },

            statusText(reward, isMerch = false) {
                if (reward.status === 'Complete' && isMerch) {
                    return `Shipped: ${this.parseDate(reward.completed_at)}`;

                } else if (reward.status === 'Complete' && !isMerch) {
                    return `Completed: ${this.parseDate(reward.completed_at)}`;
                }
                return reward.status;
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            },

            goToUserSettings() {
                this.$router.push({ name: 'userSettings', params: { userId: this.userId } })
            }
        }
    }
</script>

<style scoped>

</style>
