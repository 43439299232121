<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-9">
                <h3>
                    Featured Rewards
                </h3>
            </div>
            <div class="col-md-3">
                <b-button variant="success" class="float-right" block @click="saveChanges()" :disabled="saving"><i class="fa fa-pulse fa-spinner" v-if="saving"></i> Save Changes</b-button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <b-card
                        align="center"
                        no-body
                        tag="article">
                    <b-card-body>
                        <b-card-title>Select rewards to feature</b-card-title>

                        <div class="row mb-2" v-for="reward in rewards" :key="reward.id">
                            <div class="col-md-12">
                                <b-button
                                        block
                                        :variant="isFeatured(reward)"
                                        @click="toggleFeaturedReward(reward)"
                                >
                                    {{ reward.title }}
                                </b-button>
                            </div>
                        </div>

                    </b-card-body>
                </b-card>
            </div>
            <div class="col-md-6">
                <b-card
                        align="center"
                        no-body
                        tag="article">
                    <b-card-body>
                        <b-card-title>Change the order</b-card-title>

                        <draggable v-model="featuredRewards" @end="updateOrder()">
                            <transition-group>
                                <b-button block v-for="reward in featuredRewards" :key="reward.id" variant="outline-primary">
                                    {{reward.title}}
                                </b-button>
                            </transition-group>
                        </draggable>
                    </b-card-body>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import draggable from 'vuedraggable';

    export default {
        name: "CompanyFeaturedRewards",

        components: {
            draggable
        },

        data() {
            return {
                featuredRewards: [],
                saving: false
            }
        },

        mounted() {
            this.fetchRewards().then(() => {
                this.setFeaturedRewards();
            });
        },

        computed: {
            ...mapGetters(['rewards'])
        },

        methods: {
            ...mapActions(['fetchRewards', 'displayToast']),

            setFeaturedRewards() {
                let featuredRewards = [];
                for (let i = 0; i < this.rewards.length; i++) {
                    if (this.rewards[i].featured) {
                        featuredRewards.push({
                            id: this.rewards[i].id,
                            title: this.rewards[i].title,
                            priority: this.rewards[i].featured_priority
                        });
                    }
                }
                this.featuredRewards = featuredRewards.sort((a, b) => a['priority'] - b['priority']);
            },

            toggleFeaturedReward(reward) {
                for (let i = 0; i < this.featuredRewards.length; i++) {
                    if (this.featuredRewards[i].id === reward.id) {
                        this.featuredRewards.splice(i, 1);
                        return;
                    }
                }
                this.featuredRewards.push({
                    id: reward.id,
                    title: reward.title,
                    priority: this.featuredRewards.length
                });
            },

            isFeatured(reward) {
                for (let i = 0; i < this.featuredRewards.length; i++) {
                    if (this.featuredRewards[i].id === reward.id) {
                        return 'primary';
                    }
                }
                return 'outline-primary';
            },

            updateOrder() {
                for (let i = 0; i < this.featuredRewards.length; i++) {
                    this.featuredRewards[i].priority = i;
                }
            },

            saveChanges() {
                if (this.saving) return;

                this.saving = true;

                axios.post(`/web/rewards/featured`, {featured_rewards: this.featuredRewards})
                    .then(() => {
                        this.fetchRewards();
                        this.saving = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            }
        }
    }
</script>

<style scoped>

</style>
