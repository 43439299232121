<template>
    <div>
        <!-- Filters -->
        <div class="filters">
            <div class="row">

                <!-- Search -->
                <div class="col-sm-12" :class="searchColSpan" v-if="!hideSearch">
                    <div class="input-group">
                        <label class="search-input">
                            <i class="fa fa-search input-group-icon"></i>
                            <input type="text" class="form-control" placeholder="Search" v-model="searchValue" v-on:keyup.enter="reloadData()">
                            <span class="input-clear" @click="clearSearch()" v-if="searchValue"><i class="fas fa-times-circle"></i></span>
                        </label>
                        <span class="input-group-btn">
                            <button class="btn btn-danger form-control search-btn" @click="reloadData()">Search</button>
                        </span>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4" v-for="(filter, i) in filters" v-if="filters.length > 0 && i < 2">
                    <multiselect
                            v-model="selectedFilters[i]"
                            :options="filter.data"
                            :label="filter.dataLabel"
                            :track-by="filter.dataLabel"
                            @select="filterSelected()"
                            @remove="filterSelected()"
                            allowEmpty
                    >
                        <template slot="placeholder">
                            {{ filter.title }}
                        </template>
                    </multiselect>
                </div>

            </div>
        </div>

        <!-- Empty State -->
        <div class="panel panel-default panel-body" v-if="!data.total && !loading">
            <empty-state
                    subtitle="This table is empty. See if you can fill it with things"
            ></empty-state>
        </div>

        <!-- Loading From Fresh State -->
        <div class="panel panel-default panel-body" v-if="noData && loading">
            <empty-state
                    statusIcon="loading"
                    title="This Table Is Loading..."
                    subtitle="Hang tight!"
            ></empty-state>
        </div>

        <!-- Table -->
        <div class="table-container">
            <table class="table" v-if="data.total">
                <thead>
                    <tr>
                        <th v-for="(header, i) in headers"
                            :key="i"
                            v-if="displayColumn(header.column_title)"
                            @click="setOrder(header.column_title)"
                            :class="{ 'cursor-pointer': header.column_title !== 'not_orderable' }"
                        >
                            {{ header.title }}<span class="fas ml-auto" :class="orderByDirectionArrow(header.column_title)"></span>
                        </th>
                        <th v-if="!hideEdit" class="edit-column">Edit</th>
                        <th v-if="showDelete" class="edit-column">Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, i) in rows" :key="i" :class="{ 'bg-danger': rowHasDanger(row), 'bg-warning': rowHasWarning(row), 'bg-success': rowHasSuccess(row) }">
                        <td v-for="(columnData, j) in row" :key="j" v-if="displayColumn(j)" v-html="parseRowData(j, columnData)"></td>
                        <td v-if="!hideEdit" class="edit-column">
                            <button v-if="!editRoute" class="btn btn-info btn-sm" @click="editButton(row)">{{ editButtonText }}</button>
                            <a v-if="editRoute" class="btn btn-info btn-sm text-white" :href="editRedirectPath(row)">{{ editButtonText }}</a>
                        </td>
                        <td v-if="showDelete" class="edit-column">
                            <button class="btn btn-danger btn-sm" @click="deleteButton(row)">Delete</button>
                        </td>
                    </tr>
                </tbody>

                <!-- Loading State -->
                <div class="panel panel-default panel-body empty-state loading-state" v-if="isExtendedLoading">
                    <i class="fas fa-circle-notch fa-spin"></i>
                    <h2>This Table Is Loading...</h2>
                    <h3>Hang tight!</h3>
                </div>

            </table>
        </div>

        <!-- Table Footer - Result Count and Pagination -->
        <div class="row" v-if="data.last_page > 1">
            <div class="footer">

                <div class="pull-left footer-controls footer-records">
                    Showing {{ data.from }} to {{ data.to }} of {{ data.total }}
                </div>

                <div class="pull-right footer-controls">
                    <ul class="pagination">

                        <!--first page button-->
                        <li class="pagination-nav" v-bind:class="{'disabled': !data.prev_page_url}"
                            @click="reloadData(1)">
                            <span><i class="fas fa-arrow-to-left"></i></span>
                        </li>

                        <!--previous button-->
                        <li class="pagination-nav" v-bind:class="{'disabled': !data.prev_page_url}"
                            @click="reloadData(data.current_page - 1)">
                            <span><i class="fas fa-arrow-left"></i></span>
                        </li>

                        <!--page navigation-->
                        <li v-for="pageNumber in pagesControl"
                            v-bind:class="{'active': isCurrentPage(pageNumber)}"
                            @click="reloadData(pageNumber)">
                            <span class="pagination-nav">{{pageNumber}}</span>
                        </li>

                        <!--next button-->
                        <li class="pagination-nav" v-bind:class="{'disabled': !data.next_page_url}"
                            @click="reloadData(data.current_page + 1)">
                            <span><i class="fas fa-arrow-right"></i></span>
                        </li>

                        <!-- last page button-->
                        <li class="pagination-nav" v-bind:class="{'disabled': !data.next_page_url}"
                            @click="reloadData(data.last_page)">
                            <span><i class="fas fa-arrow-to-right"></i></span>
                        </li>

                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "DataTable",

        mounted() {
            this.orderBy = this.setOrderBy;
            this.reloadData(1);
        },

        props: {
            data: {
                type: Object,
                default: {}
            },
            loading: {
                type: Boolean,
                default: true
            },
            hideSearch: {
                type: Boolean,
                default: false
            },
            filters: {
                type: Array,
                default: function() {
                    return [
                        // Default formatting of filters. MAX OF 2:
                        // {
                        //     title: 'Makes',
                        //     columnName: 'make_id',
                        //     dataLabel: 'title',
                        //     data: []
                        // },
                    ]
                }
            },
            displayId: {
                type: Boolean,
                default: false
            },
            idField: {
                type: String,
                default: 'id'
            },
            editButtonText: {
                type: String,
                default: 'Edit'
            },
            editRoute: {
                default: null
            },
            hideEdit: {
                type: Boolean,
                default: false
            },
            showDelete: {
                type: Boolean,
                default: false
            },
            setOrderBy: {
                type: String,
                default: 'id'
            },
            setStateColourOn: {
                type: String,
                default: ''
            },
            successStateOn: {
                type: String,
                default: ''
            },
            warningStateOn: {
                type: String,
                default: ''
            },
            dangerStateOn: {
                type: String,
                default: ''
            },
            activeState: {
                type: String,
                default: 'active'
            },
            dateFormat: {
                type: String,
                default: 'LLL'
            }
        },

        data() {
            return {
                searchValue: '',
                orderBy: 'id',
                orderByDirection: 'desc',
                pagesControl: [],   // page numbers to be displayed on pagination nav
                paginationRows: 15, // number of rows to fetch per page
                extendedLoading: false,

                selectedFilters: [null, null]
            }
        },

        computed: {
            ...mapGetters(['user', 'userCan']),

            isExtendedLoading() {
                return this.loading && this.extendedLoading;
            },

            noData() {
                return Object.keys(this.data).length === 0;
            },

            headers() {
                if (!this.data.headers) {
                    return [];
                }
                return this.data.headers;
            },

            rows() {
                if (!this.data.data) {
                    return [];
                }
                return this.data.data;
            },

            searchColSpan() {
                switch (this.filters.length) {
                    case 2:
                        return 'col-md-4';
                    case 1:
                        return 'col-md-8';
                    default:
                        return 'col-md-6';
                }
            }
        },

        watch: {
            data() {
                if (Object.keys(this.data).length > 0) {
                    this.makePageControls();
                }
            }
        },

        methods: {
            /** Sets the order of the data to the selected column */
            setOrder(columnTitle) {
                if (columnTitle === 'not_orderable') {
                    return;
                }

                if (this.orderBy === columnTitle) {
                    this.orderByDirection = (this.orderByDirection === 'desc') ? 'asc' : 'desc';
                } else {
                    this.orderBy = columnTitle;
                    this.orderByDirection = 'desc';
                }

                this.reloadData();
            },

            /** Parse a string to display a hyphen rather than no data */
            parseRowData(column, data) {
                if (data === "") {
                    return '-';
                }
                if (column === this.activeState) {
                    return (data == 1) ? 'Active' : 'Inactive';
                }
                if (this.isJSON(data)) {
                    return this.parseJSON(data);
                }
                if (this.isDate(data)) {
                    return moment(data, 'YYYY-MM-DD HH:mm:ss').format(this.dateFormat);
                }
                return data;
            },

            /** Row has the success background colour */
            rowHasSuccess(row) {
                if (this.setStateColourOn && this.successStateOn) {
                    let checks = this.successStateOn.split(',');
                    return (row.hasOwnProperty(this.setStateColourOn) && checks.includes(row[this.setStateColourOn].toString()));
                }
                return false;
            },

            /** Row has the warning background colour */
            rowHasWarning(row) {
                if (this.setStateColourOn && this.warningStateOn) {
                    let checks = this.warningStateOn.split(',');
                    return (row.hasOwnProperty(this.setStateColourOn) && checks.includes(row[this.setStateColourOn].toString()));
                }
                return false;
            },

            /** Row has the danger background colour */
            rowHasDanger(row) {
                if (this.setStateColourOn && this.dangerStateOn) {
                    let checks = this.dangerStateOn.split(',');
                    return (row.hasOwnProperty(this.setStateColourOn) && checks.includes(row[this.setStateColourOn].toString()));
                } else if (row.hasOwnProperty('show_error') && row.show_error) {
                    return true;
                }
                return false;
            },

            displayColumn(title) {
                if (title === 'id' && !this.displayId) {
                    return false;
                }
                if (title === 'show_error') {
                    return false;
                }
                return true;
            },

            /** Emit a call to follow the edit button link */
            editButton(row) {
                this.$emit("editlistener", row);
            },

            /** The edit href path */
            editRedirectPath(row) {
                return `${this.editRoute}/${row[this.idField]}`;
            },

            /** Emit a call to follow the delete button link */
            deleteButton(row) {
                this.$emit("deletelistener", row);
            },

            /** Clear search input */
            clearSearch() {
                this.searchValue = "";
                this.reloadData();
            },

            /** Emit a call to reload the data with the new query string */
            reloadData(pageNumber = 1) {
                if (pageNumber > this.data.last_page) {
                    return;
                }

                let queryString = `?limit=${this.paginationRows}&page=${pageNumber}`;

                if (this.searchValue && this.searchValue !== "") {
                    queryString += `&search=${this.searchValue}`;
                }

                queryString += `&order_by=${this.orderBy}&order_by_direction=${this.orderByDirection}`;

                for (let i = 0; i < this.selectedFilters.length; i++) {
                    if (this.selectedFilters[i] && Object.keys(this.selectedFilters[i]).length > 0) {
                        queryString += `&filter_${i + 1}_column=${this.filters[i].columnName}`;
                        queryString += `&filter_${i + 1}_id=${this.selectedFilters[i].id}`;
                    }
                }

                this.extendedLoading = false;

                let self = this;
                setTimeout(function () {
                    self.extendedLoading = true;
                }, 2000);

                this.$emit("reload", queryString);
            },

            /** A filter has been selected so run the query again after a short pause for Vue to sync */
            filterSelected() {
                this.$nextTick(() => {
                    this.reloadData(1);
                });
            },

            /** return true if passed page number is the current page */
            isCurrentPage(pageNumber) {
                if (this.data.current_page === pageNumber) {
                    return 1;
                }
                return 0;
            },

            /** Make page controls */
            makePageControls() {
                this.pagesControl = [];

                let pageStart = 1;
                let pageNavWidth = 5;

                if (this.data.last_page > pageNavWidth) {
                    // current page is over the nav midpoint
                    if (this.data.current_page > 3) {
                        pageStart = this.data.current_page - 2;
                    }
                    // if we are in the last pageNavWidth number of pages start nav to show only last n
                    if (pageStart + pageNavWidth > this.data.last_page) {
                        pageStart = this.data.last_page - pageNavWidth + 1;
                    }
                }

                for (let i = 0; i < pageNavWidth; i++) {
                    if (i + pageStart > this.data.last_page) {
                        break;
                    }
                    this.pagesControl.push(pageStart + i);
                }
            },

            orderByDirectionArrow(columnTitle) {
                if (this.orderBy !== columnTitle) {
                    return (columnTitle !== 'not_orderable') ? 'fa-sort' : '';
                } else {
                    return (this.orderByDirection === 'asc') ? 'fa-sort-up' : 'fa-sort-down';
                }
            },

            isDate(data) {
                return moment(data, 'YYYY-MM-DD HH:mm:ss', true).isValid();
            },

            isJSON(data) {
                if (Number.isInteger(data)) {
                    return false;
                }
                try {
                    JSON.parse(data);
                } catch (e) {
                    return false;
                }
                return true;
            },

            parseJSON(data) {
                let string = '';
                const json = JSON.parse(data);

                for (let attr in json) {
                    if (json.hasOwnProperty(attr)) {
                        string += `<pre class="mb-2 p-2">${attr}: <strong>${json[attr]}</strong></pre>`;
                    }
                }

                return string;
            },

            triggerSelect(filterKey, filterData) {
                this.selectedFilters[filterKey] = filterData;
                this.filterSelected();
            }
        }
    }
</script>
