<template>
    <b-modal
            content-class="shadow"
            id="merchandise-format-modal"
            :title="modalHeader"
            no-stacking>

        <h3 v-if="formatTitle">{{ formatTitle }}</h3>

        <b-tabs pills class="merchandise-line-tabs">
            <!-- Line Details -->
            <b-tab title="Details" @click="setTab('details')" :active="tabIsActive('details')">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="category">Category:</label>
                        <b-form-select
                                id="category"
                                v-model="formatData.category_code"
                                :options="categories"
                        ></b-form-select>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="title">Title:</label>
                        <b-form-input
                                id="title"
                                v-model="formatData.title"
                        ></b-form-input>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="description">Description: <span class="text-muted text-sm">Optional</span></label>
                        <b-form-textarea
                                id="description"
                                rows="1"
                                max-rows="3"
                                v-model="formatData.description"
                        ></b-form-textarea>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="width">Width: <span class="text-muted text-sm">Optional</span></label>
                        <b-form-input
                                id="width"
                                v-model="formatData.width"
                        ></b-form-input>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="height">Height: <span class="text-muted text-sm">Optional</span></label>
                        <b-form-input
                                id="height"
                                v-model="formatData.height"
                        ></b-form-input>
                    </div>
                </div>
            </b-tab>

            <!-- Line Images -->
            <b-tab title="Images" v-if="format" @click="setTab('images')" :active="tabIsActive('images')">
                <template v-if="format && format.images.length > 0">
                    <div class="reference-grid">
                        <div v-for="image in format.images" class="reference-grid-item">
                            <a :href="image.path" target="_blank">
                                <img :src="image.path" alt="Reward illustration">
                            </a>
                            <button @click="triggerFileDelete(image.id)" class="btn btn-danger btn-sm reference-delete"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                </template>

                <template>
                    <h3>Upload an image</h3>
                    <dropzone
                            ownerClass="MerchandiseFormat"
                            :ownerId="format.id"
                            type="merchandise"
                            uploadText="Upload Merchandise Format Images"
                            @uploadComplete="uploadComplete"
                    ></dropzone>
                </template>
            </b-tab>
        </b-tabs>

        <template #modal-footer>
            <b-button size="sm" variant="default" @click="close()">
                Cancel
            </b-button>
            <b-button v-if="format" size="sm" variant="danger" @click="deleteFormat()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="deleting"></i> Delete
            </b-button>
            <b-button size="sm" variant="success" @click="saveFormat()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="saving"></i> Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: "MerchandiseFormatModal",

        data() {
            return {
                format: null,

                formatData: {
                    category_code: null,
                    title: null,
                    description: null,
                    width: null,
                    height: null
                },

                categories: [
                    { value: 'printed-art', text: 'Printed Art' }
                ],

                tab: 'details',
                saving: false,
                deleting: false
            }
        },

        computed: {
            modalHeader() {
                return (!this.format) ? 'New Format' : 'Update Format';
            },

            formatTitle() {
                if (!this.format) return null;
                return this.format.title;
            }
        },

        methods: {
            ...mapActions(['displayToast', 'deleteFile']),

            open(format) {
                this.setFormatData(format);
                this.$bvModal.show('merchandise-format-modal');
            },

            close() {
                this.$bvModal.hide('merchandise-format-modal');
            },

            saveFormat() {
                if (this.saving) return;

                this.saving = true;

                let verb = 'post';
                let path = route('web.merchandise.formats.store');

                if (this.format) {
                    verb = 'put';
                    path = route('web.merchandise.formats.format.update', this.format.id);
                }

                axios[verb](path, this.formatData)
                    .then(() => {
                        this.displayToast({text: `The merchandise format was updated.`, type: 'success'});
                        this.$emit('reload');
                        this.close();
                        this.setFormatData(null);
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            fetchFormat() {
                axios.get(route('web.merchandise.formats.format.show', this.format.id))
                    .then(response => {
                        this.setFormatData(response.data);
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            deleteFormat() {
                if (this.deleting) return;

                if (!confirm("Are you sure you want to delete this merchandise line?")) {
                    return;
                }

                this.deleting = true;

                axios.delete(route('web.merchandise.formats.format.destroy', this.format.id))
                    .then(() => {
                        this.displayToast({text: `The merchandise format was deleted.`, type: 'success'});
                        this.$emit('reload');
                        this.close();
                        this.setFormatData(null);
                        this.deleting = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.deleting = false;
                    });
            },

            setFormatData(format) {
                this.format = format;
                if (format) {
                    this.formatData = {
                        category_code: format.category.code,
                        title: format.title,
                        description: format.description,
                        width: format.width,
                        height: format.height
                    };
                } else {
                    this.formatData = {
                        category_code: null,
                        title: null,
                        description: null,
                        width: null,
                        height: null
                    };
                }
                this.saving = false;
                this.deleting = false;
            },

            triggerFileDelete(uuid) {
                this.deleteFile(uuid).then(() => {
                    this.fetchFormat();
                    this.$emit('reload');
                })
            },

            uploadComplete() {
                this.fetchFormat();
                this.$emit('reload');
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            }
        }
    }
</script>

<style scoped>

</style>