<template>
    <div>
        <div class="row" v-if="!eventIsActive">
            <div class="row">
                <div class="col-10 offset-md-1 text-center"
                     v-html="company.information.home_message">
                </div>
            </div>

            <div class="col-12">
                <featured-rewards v-if="featuredRewardsAreSet"></featured-rewards>

                <all-rewards-links :useSmallIcons="featuredRewardsAreSet"></all-rewards-links>
            </div>
        </div>

        <div class="row" v-if="eventIsActive">
            <div class="col-12">
                <event-rewards></event-rewards>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {

        mounted() {
            this.fetchRewardSlots();
            this.fetchActiveBundleRuns();
            this.fetchCompany();
            this.fetchCountries();
            this.fetchRewards();
            this.fetchFormats();
            this.fetchLines();
        },

        computed: {
            ...mapGetters([
                'availableSlotsCardText',
                'company',
                'isAvailable',
                'rewards',
                'user'
            ]),

            eventIsActive() {
                return false;
            },

            featuredRewardsAreSet() {
                return Object.keys(this.featuredRewards).length > 0;
            },

            featuredRewards() {
                let rewards = this.rewards.filter(reward => {
                    return (reward.featured === 1);
                });

                return rewards.sort((a, b) => a['featured_priority'] - b['featured_priority']);
            },

            illustrationRewards() {
                return this.rewards.filter(reward => {
                    return (reward.type.title === 'Illustration');
                });
            },

            otherRewards() {
                return this.rewards.filter(reward => {
                    return (reward.type.title !== 'Illustration' && reward.type.title !== 'Merchandise');
                });
            }
        },

        methods: {
            ...mapActions([
                'fetchCompany',
                'fetchCountries',
                'fetchFormats',
                'fetchLines',
                'fetchRewards',
                'fetchRewardSlots',
                'fetchActiveBundleRuns'
            ])
        }
    }
</script>

<style scoped>

</style>
