<template>
    <div>
        <b-navbar>
            <div class="container pb-0 pt-3">
                <!-- Site name -->
                <b-navbar-brand class="text-decoration-none">
                    <img id="company-logo" src="https://starstickers.s3.eu-west-2.amazonaws.com/site/starstickers-logo.png" alt="StarStickers by Onyrica" />
                </b-navbar-brand>

                <b-navbar-nav class="ml-auto">
                    <a href="/register" class="btn btn-danger btn-sm mb-3 text-lg">Register <i class="fa fa-plus"></i></a>
                </b-navbar-nav>

                <b-navbar-nav class="ml-3">
                    <a href="/login" class="btn btn-danger btn-sm mb-3 text-lg">Sign In <i class="fa fa-sign-in"></i></a>
                </b-navbar-nav>
            </div>
        </b-navbar>

        <div class="page-content">
            <div class="container">

                <div v-if="eventIsActive">
                    <div class="row">
                        <div class="col-10 offset-md-1 text-center">
                            <h4>WHAT’S IN THE BOX?!</h4>
                            <p class="mb-lg-5">
                                Please note that from today, all rewards on StarStickers will be replaced by Mystery Boxes. To find out more about why, please refer to <a href="https://www.patreon.com/onyrica" target="_blank">the latest update on Patreon</a>.
                                <br/><br/>
                                <span class="font-italic">[ For those who don't own a calendar, please note that today is April 1st. ]</span>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="event-grid">
                                <b-card
                                    v-for="reward in eventRewards"
                                    :key="reward.id"
                                    align="center"
                                    no-body
                                    tag="article">
                                    <div class="card-img-container">
                                        <b-card-img
                                            :src="cardImage(reward)"
                                            :alt="reward.title"
                                            top>
                                        </b-card-img>
                                    </div>
                                    <b-card-body>
                                        <b-card-title>{{ reward.title }}</b-card-title>
                                        <h5 class="text-primary mb-1">{{ reward.points_cost }} Points</h5>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-12">
                            <a href="/login" class="text-white text-decoration-none">
                                <h3 class="text-center mb-0">Sign in to apply!</h3>
                            </a>
                        </div>
                    </div>
                </div>

                <div v-if="!eventIsActive">
                    <div class="row">
                        <div class="col-10 offset-md-1 text-center"
                             v-html="company.information.visitor_home_message">
                        </div>
                        <div class="col-10 offset-md-1 text-center">
                            <p class="mb-lg-5">
                                To gain access to the shop:<br/>
                                <a href="https://www.patreon.com/onyrica" target="_blank" class="btn btn-danger btn-sm mb-3">Support me on Patreon</a>
                            </p>
                        </div>
                    </div>

                    <div class="row" v-if="featuredRewardsAreSet">
                        <div class="col-12">
                            <!--                        <h2 class="text-center mb-4">Art Rewards</h2>-->
                            <!--                        <div class="card-grid mb-5">-->
                            <!--                            <b-card-->
                            <!--                                    v-for="reward in illustrationRewards"-->
                            <!--                                    :key="reward.id"-->
                            <!--                                    align="center"-->
                            <!--                                    no-body-->
                            <!--                                    tag="article">-->
                            <!--                                <div class="card-img-container">-->
                            <!--                                    <b-card-img-->
                            <!--                                            :src="cardImage(reward)"-->
                            <!--                                            :alt="reward.title"-->
                            <!--                                            top>-->
                            <!--                                    </b-card-img>-->
                            <!--                                </div>-->
                            <!--                                <b-card-body>-->
                            <!--                                    <b-card-title>{{ reward.title }}</b-card-title>-->
                            <!--                                    <h5 class="text-primary mb-1">{{ reward.points_cost }} Points</h5>-->
                            <!--                                </b-card-body>-->
                            <!--                            </b-card>-->

                            <h2 class="text-center mb-4">Featured Rewards</h2>
                            <div class="card-grid mb-5">
                                <b-card
                                    v-for="reward in featuredRewards"
                                    :key="reward.id"
                                    align="center"
                                    no-body
                                    tag="article">
                                    <div class="card-img-container">
                                        <b-card-img
                                            :src="cardImage(reward)"
                                            :alt="reward.title"
                                            top>
                                        </b-card-img>
                                    </div>
                                    <b-card-body>
                                        <b-card-title>{{ reward.title }}</b-card-title>
                                        <h5 class="text-primary mb-1">{{ reward.points_cost }} Points</h5>
                                        <b-button variant="primary" block @click="$bvModal.show(reward.code)">View Details</b-button>
                                    </b-card-body>
                                </b-card>

                                <!-- More coming soon card -->
                                <!--                            <b-card-->
                                <!--                                    align="center"-->
                                <!--                                    no-body-->
                                <!--                                    tag="article">-->
                                <!--                                <b-card-body class="coming-soon-card">-->
                                <!--                                    <b-card-title>More rewards coming soon!</b-card-title>-->
                                <!--                                    <h6 class="text-primary">Check back later to see what else we've got planned.</h6>-->
                                <!--                                </b-card-body>-->
                                <!--                            </b-card>-->
                            </div>

                            <!--                        <h2 class="text-center mb-3">Support Rewards</h2>-->
                            <!--                        <div class="card-grid">-->
                            <!--                            <b-card-->
                            <!--                                    v-for="reward in otherRewards"-->
                            <!--                                    :key="reward.id"-->
                            <!--                                    align="center"-->
                            <!--                                    no-body-->
                            <!--                                    tag="article">-->
                            <!--                                <div class="card-img-container">-->
                            <!--                                    <b-card-img-->
                            <!--                                            :src="cardImage(reward)"-->
                            <!--                                            :alt="reward.title"-->
                            <!--                                            top>-->
                            <!--                                    </b-card-img>-->
                            <!--                                </div>-->
                            <!--                                <b-card-body>-->
                            <!--                                    <b-card-title>{{ reward.title }}</b-card-title>-->
                            <!--                                    <h5 class="text-primary mb-1">{{ reward.points_cost }} Points</h5>-->
                            <!--                                </b-card-body>-->
                            <!--                            </b-card>-->
                            <!--                        </div>-->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <all-rewards-links
                                :useSmallIcons="featuredRewardsAreSet"
                                :allowLinks="false">
                            </all-rewards-links>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <a href="https://www.patreon.com/onyrica" class="text-white text-decoration-none">
                                <h3 class="text-center mb-0">Join the Patreon to find out more!</h3>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <page-footer :visitor="true"></page-footer>

        <!--------------------
         Modals
        ---------------------->
        <component
            v-for="reward in featuredRewards"
            :key="reward.id"
            :reward="reward"
            :is="rewardModalType(reward)"
            :show-dates="false"
            @redeemed="showRedeemedModal"
            @applied="showAppliedModal"
        ></component>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchOnyricaCompany();
            this.fetchOnyricaRewards();
        },

        computed: {
            ...mapGetters(['company', 'onyricaRewards']),

            eventIsActive() {
                return false;
            },

            eventRewards() {
                return this.onyricaRewards.filter(reward => {
                    return (reward.type.title_code === 'event');
                });
            },

            featuredRewardsAreSet() {
                return Object.keys(this.featuredRewards).length > 0;
            },

            featuredRewards() {
                let rewards = this.onyricaRewards.filter(reward => {
                    return (reward.featured === 1);
                });

                return rewards.sort((a, b) => a['featured_priority'] - b['featured_priority']);
            },

            illustrationRewards() {
                return this.onyricaRewards.filter(reward => {
                    return (reward.type.title === 'Illustration');
                });
            },

            otherRewards() {
                return this.onyricaRewards.filter(reward => {
                    return (reward.type.title !== 'Illustration' && reward.type.title !== 'Merchandise');
                });
            }
        },

        methods: {
            ...mapActions(['fetchOnyricaCompany', 'fetchOnyricaRewards']),

            cardImage(reward) {
                if (reward.code === 'discord-emoji-x1' || reward.code === 'discord-emoji-x5') {
                    return reward.examples[1].path; // TODO: This is a dirty hack so do something about it
                }
                return reward.examples[0].path;
            },

            rewardModalType(reward) {
                if (reward.type.title === 'Merchandise') {
                    return 'redeem-merchandise-reward-modal';
                }
                if (reward.requires_available_slot) {
                    return 'redeem-reward-large-modal';
                }
                return 'redeem-reward-small-modal';
            },

            showRedeemedModal(reward) {},

            showAppliedModal(reward) {}
        }
    }
</script>

<style scoped>

</style>
