<template>
    <div>
        <div class="row">
            <div class="col-10 offset-md-1 text-center">
                <h4>WHAT’S IN THE BOX?!</h4>
                <p class="mb-lg-5">
                    Please note that from today, all rewards on StarStickers will be replaced by Mystery Boxes. To find out more about why, please refer to <a href="https://www.patreon.com/onyrica" target="_blank">the latest update on Patreon</a>.
                    <br/><br/>
                    <span class="font-italic">[ For those who don't own a calendar, please note that today is April 1st. ]</span>
                </p>
            </div>
        </div>
        <div class="event-grid mb-5">
            <b-card
                v-for="reward in eventRewards"
                :key="reward.id"
                align="center"
                no-body
                tag="article">
                <div class="card-img-container">
                    <b-card-img
                        :src="reward.examples[0].path"
                        :alt="reward.title"
                        top>
                    </b-card-img>
                </div>
                <b-card-body>
                    <b-card-title>{{ reward.title }}</b-card-title>
                    <h5 class="text-primary">{{ reward.points_cost }} Points</h5>

                    <b-button v-if="isAvailable(reward)" variant="danger" block @click="$bvModal.show(reward.code)">{{ redeemTitle(reward) }}</b-button>

                    <b-button v-if="!isAvailable(reward)" variant="primary" block @click="$bvModal.show(reward.code)">{{ cannotRedeemTitle(reward) }}</b-button>

                    <small v-if="displaySlotApplicationCount(reward)" class="mt-1 mb-0">Total Applications: {{ currentSlotApplicationCount(reward) }}</small>

                </b-card-body>
            </b-card>
        </div>

        <!--------------------
         Modals
        ---------------------->
        <component
            v-for="reward in rewards"
            :key="reward.id"
            :reward="reward"
            :is="rewardModalType(reward)"
            :show-dates="true"
            @redeemed="showRedeemedModal"
        ></component>

        <reward-redeemed-modal
            ref="rewardRedeemedModal"
        ></reward-redeemed-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: "EventRewards",

        computed: {
            ...mapGetters([
                'availableSlotsCardText',
                'displaySlotApplicationCount',
                'currentSlotApplicationCount',
                'isAvailable',
                'redeemTitle',
                'rewards',
                'user'
            ]),

            eventRewards() {
                return this.rewards.filter(reward => {
                    return (reward.type.title_code === 'event');
                });
            }
        },

        methods: {
            ...mapActions(['fetchUser']),

            cannotRedeemTitle(reward) {
                if (reward.requires_available_slot) {
                    return "Applications Closed";
                }

                return "Unavailable";
            },

            rewardModalType(reward) {
                if (reward.type.title === 'Merchandise') {
                    return 'redeem-merchandise-reward-modal';
                }
                if (reward.requires_available_slot) {
                    return 'redeem-reward-large-modal';
                }
                return 'redeem-reward-small-modal';
            },

            showRedeemedModal(reward) {
                this.fetchUser();
                this.$refs.rewardRedeemedModal.open(reward);
            }
        }
    }
</script>

<style scoped>

</style>
