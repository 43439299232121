<template>
    <div class="admin-container">
        <template v-if="!loading">
            <div class="admin-title row">
                <div class="col-md-9">
                    <h2>{{ rewardSlots.reward.title }} Reward Slots</h2>
                    <p class="muted">Slots Opened: {{ rewardSlots.slots_opened }}</p>
                    <p>Current Status: <span class="text-capitalize font-weight-bold" :class="statusColour">{{ currentStatusText }}</span></p>
                    <div class="form-check">
                        <input id="display-slot-quantity"
                               class="form-check-input"
                               type="checkbox"
                               v-model="rewardSlots.display_slot_quantity"
                               @click="toggleQuantityDisplay()"
                               :disabled="togglingQuantityDisplay">
                        <label class="form-check-label font-weight-normal text-md" for="display-slot-quantity">
                            Display the slot quantity to users
                        </label>
                    </div>
                </div>

                <div class="col-3" v-if="isPaused || isAcceptingApplications">
                    <button class="btn btn-block btn-warning" v-if="!isPaused" @click="updateSlots({status: 'paused'})">
                        Pause Applications
                    </button>
                    <button class="btn btn-block btn-primary" v-if="!isAcceptingApplications" @click="updateSlots({status: 'accepting_applications'})">
                        Accept Applications
                    </button>
                    <button class="btn btn-block btn-success" :disabled="!canConfirmSelectedApplications" @click="openConfirmUpdateRewardSlotsModal('confirm')">
                        Confirm Applications
                    </button>
                    <button class="btn btn-block btn-danger" @click="openConfirmUpdateRewardSlotsModal('cancel')">
                        Cancel Slots
                    </button>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-12">
                    <h3>
                        {{ rewardSlots.quantity }} {{ availableSlotText }} {{ pluralize('Slot', rewardSlots.quantity) }}
                        <a href="#" class="text-sm" v-if="canBeUpdated" @click="openChangeSlotQuantityModal()">Change</a>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4" v-for="(slot, key) in rewardSlots.user_reward_slots" :key="key">
                    <router-link class="card-stat bg-success" v-if="slot.is_filled_slot"
                                 :to="{ name: 'reward', params: { uuid: slot.user_reward_id } }">
                        <div class="text-stat">
                            <h5>{{ slot.user_name }}</h5>
                            <p>{{ slot.supporter_level }}</p>
                        </div>
                        <i class="fal stat-bg-icon fa-circle-check"></i>
                    </router-link>
                    <div class="card-stat bg-info" v-if="!slot.is_filled_slot">
                        <div class="text-stat">
                            <h5>Slot Available</h5>
                            <p>This slot has not yet been assigned to an application</p>
                        </div>
                        <i class="fal stat-bg-icon fa-circle-pause"></i>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-12">
                    <h3>{{ rewardSlots.total_applications }} {{ pluralize('Application', rewardSlots.total_applications) }}</h3>
                </div>
            </div>
            <div class="table-container" v-if="rewardSlots.applications.length">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="cursor-pointer" @click="sortOrder('user_name', 'text')">
                                User <span class="fas ml-auto" :class="orderByDirectionArrow('user_name')"></span>
                            </th>
                            <th class="cursor-pointer" @click="sortOrder('supporter_level', 'text')">
                                Tier <span class="fas ml-auto" :class="orderByDirectionArrow('supporter_level')"></span>
                            </th>
                            <th class="cursor-pointer" @click="sortOrder('total_slot_rewards', 'num')">
                                Total Delivered Rewards <span class="fas ml-auto" :class="orderByDirectionArrow('total_slot_rewards')"></span>
                            </th>
                            <th class="cursor-pointer" @click="sortOrder('last_reward_date_timestamp', 'num')">
                                Last Reward Delivered <span class="fas ml-auto" :class="orderByDirectionArrow('last_reward_date_timestamp')"></span>
                            </th>
                            <th class="cursor-pointer" @click="sortOrder('total_failed_applications', 'num')">
                                Total Failed Applications <span class="fas ml-auto" :class="orderByDirectionArrow('total_failed_applications')"></span>
                            </th>
                            <th class="edit-column">Details</th>
                            <th class="edit-column" v-if="canBeUpdated">Select Application</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(application, i) in orderedApplications" :key="i" :class="applicationStatusColour(application)">
                            <td>{{ application.user_name }}</td>
                            <td>{{ application.supporter_level }}</td>
                            <td>{{ application.total_slot_rewards }}</td>
                            <td>{{ application.last_reward_date }}</td>
                            <td>{{ application.total_failed_applications }}</td>
                            <td class="edit-column">
                                <button class="btn btn-info btn-sm" @click="openApplicationInfoModal(application)">Details</button>
                            </td>
                            <td class="edit-column" v-if="canBeUpdated">
                                <button class="btn btn-primary btn-sm" @click="toggleSelectedApplication(application.application_id)">
                                    {{ applicationIsSelected(application.application_id) ? 'Deselect Application' : 'Select Application' }}
                                </button>
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
            <empty-state
                v-if="!rewardSlots.applications.length"
                icon="empty-set"
                title=""
                subtitle="There are currently no applications for these reward slots">
            </empty-state>
        </template>

        <empty-state
            v-if="loading"
            icon="hourglass"
            title="Hang On A Sec..."
            subtitle="We're just loading the slot details.">
        </empty-state>

        <change-slot-quantity-modal
            :quantity="rewardSlots.quantity"
            ref="changeSlotQuantityModal"
            @update="updateSlots"
        ></change-slot-quantity-modal>

        <confirm-update-reward-slots-modal
            ref="confirmUpdateRewardSlotsModal"
            @update="updateSlots"
            @confirm="confirmSlots"
        ></confirm-update-reward-slots-modal>

        <application-info-modal
            :rewardSlotUuid="uuid"
            ref="applicationInfoModal"
        ></application-info-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "RewardSlot",

    props: ['uuid'],

    data() {
        return {
            loading: true,
            updating: false,
            togglingQuantityDisplay: false,

            rewardSlots: [],
            selectedApplications: [],

            orderBy: {
                'type': null,
                'key': null,
                'direction': null
            }
        }
    },

    mounted() {
        this.fetchRewardSlots();
    },

    computed: {
        ...mapGetters(['pluralize']),

        isPaused() {
            return this.rewardSlots.status === 'paused';
        },

        isAcceptingApplications() {
            return this.rewardSlots.status === 'accepting_applications';
        },

        isComplete() {
            return this.rewardSlots.status === 'complete';
        },

        isCancelled() {
            return this.rewardSlots.status === 'cancelled';
        },

        canBeUpdated() {
            return (!this.isComplete && !this.isCancelled)
        },

        canConfirmSelectedApplications() {
            return this.selectedApplications.length === this.rewardSlots.quantity;
        },

        statusColour() {
            switch (this.rewardSlots.status) {
                case 'paused':
                    return 'text-warning';
                case 'accepting_applications':
                    return 'text-primary';
                case 'complete':
                    return 'text-success';
                case 'cancelled':
                    return 'text-danger';
            }
        },

        currentStatusText() {
            if (this.isAcceptingApplications) {
                return 'Accepting Applications';
            }
            return this.rewardSlots.status;
        },

        availableSlotText() {
            if (this.isComplete) return 'Assigned';
            if (this.isCancelled) return 'Cancelled';
            return 'Available';
        },

        orderedApplications() {
            if (!this.orderBy.key) {
                return this.rewardSlots.applications;
            }
            if (this.orderBy.type === 'num') {
                if (this.orderBy.direction === 'asc') {
                    return this.rewardSlots.applications.sort((a, b) => a[this.orderBy.key] - b[this.orderBy.key]);
                } else {
                    return this.rewardSlots.applications.sort((a, b) => b[this.orderBy.key] - a[this.orderBy.key]);
                }
            } else {
                if (this.orderBy.direction === 'asc') {
                    return this.rewardSlots.applications.sort((a, b) => a[this.orderBy.key].localeCompare(b[this.orderBy.key]));
                } else {
                    return this.rewardSlots.applications.sort((a, b) => b[this.orderBy.key].localeCompare(a[this.orderBy.key]));
                }
            }
        }
    },

    methods: {
        ...mapActions(['displayToast']),

        fetchRewardSlots() {
            this.loading = true;
            axios.get(route('web.rewardSlots.rewardSlot.show', {rewardSlotUuid: this.uuid}))
                .then(response => {
                    this.rewardSlots = response.data;
                    this.loading = false;

                }).catch(errors => {
                    this.displayToast({text: errors.response.data, type: 'danger'});
                });
        },

        toggleQuantityDisplay() {
            if (this.togglingQuantityDisplay) return;
            this.togglingQuantityDisplay = true;

            let state = this.rewardSlots.display_slot_quantity === false;

            axios.put(route('web.rewardSlots.rewardSlot.update', {rewardSlotUuid: this.uuid}), {
                display_slot_quantity: state

            }).then(response => {
                this.rewardSlots = response.data;
                this.togglingQuantityDisplay = false;

            }).catch(errors => {
                this.displayToast({text: errors.response.data, type: 'danger'});
                this.togglingQuantityDisplay = false;
            });
        },

        updateSlots(params) {
            if (this.updating) return;
            this.updating = true;

            axios.put(route('web.rewardSlots.rewardSlot.update', {rewardSlotUuid: this.uuid}), params)
                .then(response => {
                    this.rewardSlots = response.data;
                    this.updating = false;

                }).catch(errors => {
                    this.displayToast({text: errors.response.data, type: 'danger'});
                    this.updating = false;
                });
        },

        confirmSlots() {
            console.log('here');
            if (this.loading) return;
            this.loading = true;

            axios.put(route('web.rewardSlots.rewardSlot.update', {rewardSlotUuid: this.uuid}), {
                status: 'complete',
                selected_applications: this.selectedApplications,
                send_failure_emails: true

            }).then(response => {
                    this.rewardSlots = response.data;
                    this.loading = false;

            }).catch(errors => {
                this.displayToast({text: errors.response.data, type: 'danger'});
                this.loading = false;
            });
        },

        toggleSelectedApplication(applicationId) {
            if (this.applicationIsSelected(applicationId)) {
                const index = this.selectedApplications.indexOf(applicationId);
                if (index > -1) {
                    this.selectedApplications.splice(index, 1);
                }
            } else {
                this.selectedApplications.push(applicationId);
            }
        },

        applicationIsSelected(applicationId) {
            return this.selectedApplications.includes(applicationId);
        },

        applicationStatusColour(application) {
            if (application.is_successful_application) {
                return 'bg-success';
            }
            if (!application.is_successful_application && !this.canBeUpdated) {
                return 'bg-danger'
            }
            return this.applicationIsSelected(application.application_id) ? 'bg-success' : 'bg-light';
        },

        openChangeSlotQuantityModal() {
            this.$refs.changeSlotQuantityModal.open();
        },

        openConfirmUpdateRewardSlotsModal(type) {
            this.$refs.confirmUpdateRewardSlotsModal.open(type);
        },

        openApplicationInfoModal(application) {
            this.$refs.applicationInfoModal.open(application);
        },

        sortOrder(dataKey, type) {
            this.orderBy.type = type;
            if (
                !this.orderBy.direction ||
                this.orderBy.key !== dataKey ||
                this.orderBy.direction === 'asc'
            ) {
                this.orderBy.direction = 'desc';
            } else {
                this.orderBy.direction = 'asc';
            }
            this.orderBy.key = dataKey;
        },

        orderByDirectionArrow(dataKey) {
            if (dataKey !== this.orderBy.key) {
                return 'fa-sort';
            }
            return (this.orderBy.direction === 'asc') ? 'fa-sort-up' : 'fa-sort-down';
        }
    }
}
</script>

<style scoped>

</style>
