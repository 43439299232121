<template>
    <b-modal
            content-class="shadow"
            id="merchandise-line-modal"
            :title="modalHeader"
            size="lg"
            no-stacking>

        <h3 v-if="lineTitle">{{ lineTitle }}</h3>

        <b-tabs pills class="merchandise-line-tabs">
            <!-- Line Details -->
            <b-tab title="Details" @click="setTab('details')" :active="tabIsActive('details')">
                <div class="row">
                    <div class="col-md-12 mb-3">
                        <label for="title">Title:</label>
                        <b-form-input
                                id="title"
                                v-model="lineData.title"
                        ></b-form-input>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="description">Description: <span class="text-muted text-sm">Optional</span></label>
                        <b-form-textarea
                                id="description"
                                rows="3"
                                max-rows="6"
                                v-model="lineData.description"
                        ></b-form-textarea>
                    </div>

                    <div class="col-md-12 mb-3">
                        <label for="formats">Formats:</label>
                        <b-form-select id="formats" v-model="lineData.format_ids" :options="selectFormats" multiple :select-size="4"></b-form-select>
                    </div>
                </div>
            </b-tab>

            <!-- Line Images -->
            <b-tab title="Images" v-if="line" @click="setTab('images')" :active="tabIsActive('images')">
                <template v-if="line && line.images.length > 0">
                    <div class="reference-grid">
                        <div v-for="image in line.images" class="reference-grid-item">
                            <a :href="image.path" target="_blank">
                                <img :src="image.path" alt="Reward illustration">
                            </a>
                            <button @click="triggerFileDelete(image.id)" class="btn btn-danger btn-sm reference-delete"><i class="fa fa-times"></i></button>
                        </div>
                    </div>
                </template>

                <template>
                    <h3>Upload an image</h3>
                    <dropzone
                            ownerClass="MerchandiseLine"
                            :ownerId="line.id"
                            type="merchandise"
                            uploadText="Upload Merchandise Images"
                            @uploadComplete="uploadComplete"
                    ></dropzone>
                </template>
            </b-tab>
        </b-tabs>

        <template #modal-footer>
            <b-button size="sm" variant="default" @click="close()">
                Cancel
            </b-button>
            <b-button v-if="line" size="sm" variant="danger" @click="deleteLine()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="deleting"></i> Delete
            </b-button>
            <b-button size="sm" variant="success" @click="saveLine()" :disabled="deleting || saving">
                <i class="fa fa-pulse fa-spinner" v-if="saving"></i> Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: "MerchandiseLineModal",

        props: {
            formats: {
                type: Array,
                default: []
            }
        },

        data() {
            return {
                line: null,

                lineData: {
                    title: null,
                    description: null,
                    active: true,
                    format_ids: []
                },

                tab: 'details',
                saving: false,
                deleting: false
            }
        },

        computed: {
            modalHeader() {
                return (!this.line) ? 'New Line' : 'Update Line';
            },

            lineTitle() {
                if (!this.line) return null;
                return this.line.title;
            },

            selectFormats() {
                let formats = [];
                for (let i = 0; i < this.formats.length; i++) {
                    formats.push({
                        value: this.formats[i].id,
                        text: this.formats[i].title
                    });
                }
                return formats;
            }
        },

        methods: {
            ...mapActions(['displayToast', 'deleteFile']),

            open(line) {
                this.setLineData(line);
                this.tab = 'details';
                this.$bvModal.show('merchandise-line-modal');
            },

            close() {
                this.$bvModal.hide('merchandise-line-modal');
            },

            saveLine() {
                if (this.saving) return;

                this.saving = true;

                let verb = 'post';
                let path = route('web.merchandise.lines.store');

                if (this.line) {
                    verb = 'put';
                    path = route('web.merchandise.lines.line.update', this.line.id);
                }

                axios[verb](path, this.lineData)
                    .then(response => {
                        this.displayToast({text: `The merchandise line was updated.`, type: 'success'});
                        this.$emit('reload');
                        this.setLineData(response.data);
                        this.saving = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            fetchLine() {
                axios.get(route('web.merchandise.lines.line.show', this.line.id))
                    .then(response => {
                        this.setLineData(response.data);
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.saving = false;
                    });
            },

            deleteLine() {
                if (this.deleting) return;

                if (!confirm("Are you sure you want to delete this merchandise line?")) {
                    return;
                }

                this.deleting = true;

                axios.delete(route('web.merchandise.lines.line.destroy', this.line.id))
                    .then(() => {
                        this.displayToast({text: `The merchandise line was deleted.`, type: 'success'});
                        this.$emit('reload');
                        this.close();
                        this.setLineData(null);
                        this.deleting = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.deleting = false;
                    });
            },

            triggerFileDelete(uuid) {
                this.deleteFile(uuid).then(() => {
                    this.fetchLine();
                    this.$emit('reload');
                })
            },

            uploadComplete() {
                this.fetchLine();
                this.$emit('reload');
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            },

            setLineData(line) {
                this.line = line;
                if (line) {
                    this.lineData = {
                        title: line.title,
                        description: line.description,
                        active: line.active,
                        format_ids: []
                    };

                    for (let i = 0; i < line.formats.length; i++) {
                        this.lineData.format_ids.push(line.formats[i].id);
                    }
                } else {
                    this.lineData = {
                        title: null,
                        description: null,
                        active: true,
                        format_ids: []
                    };
                }
                this.saving = false;
                this.deleting = false;
            }
        }
    }
</script>

<style scoped>

</style>