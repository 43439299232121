<template>
    <div class="col-10 offset-1">
        <div class="mb-5">
            <h2>StarStickers Guide</h2>
        </div>
        <div v-if="company.faqs.length > 0">
            <article class="faq" v-for="(faq, i) in company.faqs">
                <h6>{{ faq.title }}</h6>
                <div v-html="faq.faq"></div>
                <hr v-if="displayPageBreak(i)" class="divider">
            </article>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['company'])
        },

        methods: {
            displayPageBreak(i) {
                return i < (this.company.faqs.length - 1);
            }
        }
    }
</script>

<style scoped>

</style>
