<template>
    <b-modal
            body-class="p-0"
            content-class="shadow"
            hide-footer
            hide-header
            :id="reward.code"
            modal-class="reward-modal"
            no-stacking
            size="lg">

        <b-button class="modal-close fal fa-times" variant="danger" @click="close()"></b-button>
        <div class="d-flex flex-wrap">

            <!-- Image Carousel -->
            <div class="col-md-6 p-0">
                <b-carousel
                        v-model="slide"
                        controls
                        indicators
                        :interval="4000"
                        @sliding-start="onSlideStart"
                        @sliding-end="onSlideEnd"
                >
                    <b-carousel-slide
                            v-for="example in reward.examples"
                            :key="example.id"
                            :img-src="example.path">
                    </b-carousel-slide>
                </b-carousel>
            </div>

            <!-- Modal Content -->
            <div class="col-md-6 p-0">
                <div class="modal-body">
                    <h3>{{ reward.title }}</h3>
                    <h4 class="text-primary">{{ reward.points_cost }} Points</h4>
                    <div v-if="!requestingApplication" v-html="reward.description"></div>

                    <!-- Apply for reward -->
                    <template v-if="isAvailable(reward) && canRedeem(reward) && !requestingApplication">
                        <b-alert :show="hasErrors" variant="danger" class="d-flex">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </b-alert>
                        <div class="redemption-controls">
                            <b-button variant="success" block @click="requestApplication()">Apply For This Reward</b-button>
                        </div>
                    </template>

                    <!-- Confirm redemption -->
                    <template v-if="isAvailable(reward) && (canRedeem(reward) || applying) && requestingApplication">
                        <label>Before you apply</label>
                        <div v-html="reward.type.redemption_note"></div>
                        <div class="redemption-controls">
                            <table class="redemption-table">
                                <tr>
                                    <td>Reward Cost</td>
                                    <td class="cost reward-cost">{{ reward.points_cost }}</td>
                                </tr>
                                <tr>
                                    <td>Current Points</td>
                                    <td class="cost">{{ user.points }}</td>
                                </tr>
                                <tr>
                                    <td>Points After Reward</td>
                                    <td class="cost">{{ totalAfterRedemption(reward) }}</td>
                                </tr>
                            </table>
                            <b-button variant="success" class="mb-3" block @click="apply()" :disabled="applying">
                                <i v-if="applying" class="fa fa-pulse fa-spinner"></i>
                                Apply Now
                            </b-button>
                            <b-button variant="link" block @click="cancelRequest()">I've Changed My Mind</b-button>
                        </div>
                    </template>

                    <!-- Can't apply -->
                    <template v-if="isAvailable(reward) && !canRedeem(reward) && !applying">
                        <hr class="divider">

                        <empty-state
                                icon="paint-brush"
                                title=""
                                :subtitle="cannotRedeemMessage(reward)">
                        </empty-state>
                    </template>

                    <!-- No slots available -->
                    <template v-if="!isAvailable(reward)">
                        <hr class="divider">

                        <empty-state
                            icon="paint-brush"
                            title=""
                            subtitle="Applications are currently closed for this reward. Check back soon.">
                        </empty-state>
                    </template>

                    <!-- Temp close bookings -->
                    <template v-if="false">
                        <hr class="divider">

                        <empty-state
                                icon="paint-brush"
                                title=""
                                subtitle="We're temporarily halting reward bookings while people sign up to the service.">
                        </empty-state>
                    </template>

                </div>
            </div>

        </div>
    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props:['reward', 'showDates'],

        data: function() {
            return {
                errors: {},
                requestingApplication: false,
                applying: false,
                selectedPeriod: null,
                slide: 0,
                sliding: null
            }
        },

        computed: {
            ...mapGetters([
                'rewardSlots',
                'cannotRedeemMessage',
                'canRedeem',
                'rewardSlotForThisReward',
                'totalAfterRedemption',
                'user',
                'isAvailable'
            ]),

            hasErrors() {
                return Object.keys(this.errors).length > 0;
            }
        },

        methods: {
            ...mapActions(['applyForReward']),

            remainingAvailabilityText(availability) {
                if (!this.availabilityForThisReward(availability)) {
                    return "No availability for this reward";
                }

                return "Slots available for this reward";
            },

            apply() {
                if (this.applying) return;

                let rewardSlot = this.rewardSlotForThisReward(this.reward);

                if (!rewardSlot) {
                    this.errors = ["There are no available slots for this reward."];
                    this.requestingApplication = false;
                    return;
                }

                this.applying = true;

                this.applyForReward({
                    rewardSlot: rewardSlot
                }).then(() => {
                    this.$emit('redeemed', this.reward);
                    this.reset();
                }).catch(error => {
                    this.errors = error.data;
                    this.requestingApplication = false;
                    this.applying = false;
                });
            },

            requestApplication() {
                this.requestingApplication = true;
            },

            cancelRequest() {
                this.reset();
                this.requestingApplication = false;
            },

            onSlideStart(slide) {
                this.sliding = true
            },

            onSlideEnd(slide) {
                this.sliding = false
            },

            close() {
                this.$bvModal.hide(this.reward.code);
                this.reset();
            },

            reset() {
                this.errors = {};
                this.requestingApplication = false;
                this.selectedPeriod = null;
                this.applying = false;
            }
        }
    }
</script>

<style scoped>

</style>
