<template>
    <div>
        <template v-if="!loading">
            <!-- Top details -->
            <div class="row mb-3">
                <div class="col-md-5 offset-md-1">
                    <div class="media account-details">
                        <div class="media-left" v-b-modal.edit-avatar>
                            <b-button class="btn btn-info btn-rounded edit-button"><i class="fa fa-pencil"></i></b-button>
                            <img :src="thisUser.avatar.path" class="avatar">
                        </div>
                        <div class="media-body">
                            <h2>{{ thisUser.name }}</h2>
                            <h4>{{ thisUser.email }}</h4>
                        </div>
                    </div>
                </div>
                <div v-if="userId" class="col-md-5">
                    <div class="float-right">
                        <router-link :to="{name: 'user', params: { userId: userId }}" class="btn btn-primary">
                            <i class="fal fa-user"></i> User Details
                        </router-link>
                    </div>
                </div>
            </div>

            <!-- User name HIDDEN -->
            <div class="row" v-if="false">
                <div class="col-md-5 offset-md-1">
                    <b-form-group
                            label="First Name"
                            label-for="first-name"
                    >
                        <b-form-input id="first_name" v-model="editUser.first_name" size="lg" trim></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-5">
                    <b-form-group
                            label="Last Name"
                            label-for="last-name"
                    >
                        <b-form-input id="last-name" v-model="editUser.last_name" size="lg" trim></b-form-input>
                    </b-form-group>
                </div>
            </div>

            <!-- Admin details -->
            <div class="row" v-if="isCompanyAdmin">
                <div class="offset-md-6 col-md-5">
                    <b-form-group
                        label="Patreon Registration Date"
                        label-for="registration-date"
                        :invalid-feedback="invalidFeedback('joined_patreon')"
                        :state="state('joined_patreon')"
                    >
                        <b-form-datepicker
                            id="registration-date"
                            locale="en"
                            v-model="editUser.joined_patreon"
                            :state="state('joined_patreon')"
                        ></b-form-datepicker>
                    </b-form-group>
                </div>
            </div>


            <div class="row">
                <div class="col-md-10 offset-md-1">
                    <!-- User details -->
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                    label="Patreon Name"
                                    label-for="name"
                                    :invalid-feedback="invalidFeedback('name')"
                                    :state="state('name')"
                            >
                                <b-form-input id="name" v-model="editUser.name" size="lg" :state="state('name')" trim></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                    label="Patreon Email"
                                    label-for="email-address"
                                    :invalid-feedback="invalidFeedback('email')"
                                    :state="state('email')"
                            >
                                <b-form-input id="email-address" v-model="editUser.email" size="lg" :state="state('email')" type="email" trim></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <!-- Contact details -->
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                    label="Preferred Contact Method"
                                    label-for="contact-method"
                                    :invalid-feedback="invalidFeedback('contact_method')"
                                    :state="state('contact_method')"
                            >
                                <b-form-select v-model="editUser.contact_method" :options="contactMethodOptions"></b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                    :label="contactDetailsTitle"
                                    label-for="contact-details"
                                    :invalid-feedback="invalidFeedback('contact_details')"
                                    :state="state('contact_details')"
                            >
                                <b-form-input id="contact-details" v-model="editUser.contact_details" size="lg" :state="state('contact_details')" type="text" trim></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <!-- Password -->
                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group
                                    label="Enter password"
                                    label-for="password"
                                    :invalid-feedback="invalidFeedback('password')"
                                    :state="state('password')"
                            >
                                <b-form-input id="password" v-model="editUser.password" size="lg" :state="state('password')" type="password" trim></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                    label="Confirm password"
                                    label-for="password_confirm"
                                    :state="state('password')"
                            >
                                <b-form-input id="password_confirm" v-model="editUser.password_confirmation" size="lg" :state="state('password')" type="password" trim></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <!-- Patreon Deets HIDDEN -->
                    <div class="row" v-if="false">
                        <div class="col-md-6">
                            <b-form-group
                                    label="Patreon Name"
                                    label-for="patreon-name"
                                    :invalid-feedback="invalidFeedback('patreon_username')"
                                    :state="state('patreon_username')"
                            >
                                <b-form-input id="patreon-name" v-model="editUser.patreon_username" placeholder="optional" size="lg" :state="state('patreon_username')" trim></b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group
                                    label="Patreon Email"
                                    label-for="patreon-email"
                                    :invalid-feedback="invalidFeedback('patreon_email')"
                                    :state="state('patreon_email')"
                            >
                                <b-form-input id="patreon-email" v-model="editUser.patreon_email" placeholder="optional" size="lg" :state="state('patreon_email')" type="email" trim></b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <!-- Controls -->
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <button class="btn btn-success btn-lg" @click="update()" :disabled="saving">
                                <i class="fa fa-pulse fa-spinner" v-if="saving"></i>
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <edit-avatar-modal></edit-avatar-modal>

        </template>

        <empty-state
                v-if="loading"
                icon="hourglass-half"
                title="Loading..."
                subtitle="Getting the users details.">
        </empty-state>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchRequestedUser().then(() => {
                this.prepareDetails();
            });
        },

        props: {
            userId: {
                default: null
            }
        },

        data: function() {
            return {
                loading: true,
                requestedUser: null,
                editUser: {
                    first_name: null,
                    last_name: null,
                    name: null,
                    email: null,
                    contact_method: null,
                    contact_details: null,
                    password: null,
                    password_confirmation: null,
                    patreon_username: null,
                    patreon_email: null,
                    joined_patreon: null
                },
                contactMethodOptions: [
                    {value: 'email', text: 'Email'},
                    {value: 'discord', text: 'Discord'}
                ],
                saving: false,
                errors: {},
                tab: 'details'
            }
        },

        computed: {
            ...mapGetters(['user', 'isCompanyAdmin']),

            thisUser() {
                if (this.userId && this.requestedUser) {
                    return this.requestedUser;
                }
                return this.user;
            },

            nameValidate() {
                return {
                    state: this.state('name'),
                    message: this.invalidFeedback('name')
                };
            },

            emailValidate() {
                return {
                    state: this.state('email'),
                    message: this.invalidFeedback('email')
                };
            },

            contactDetailsTitle() {
                if (this.editUser.contact_method) {
                    switch (this.editUser.contact_method) {
                        case 'email':
                            return 'Contact Email';
                        case 'discord':
                            return 'Discord ID';
                    }
                }
                return 'Select a contact method';
            },

            passwordValidate() {
                return {
                    state: this.state('password'),
                    message: this.invalidFeedback('password')
                };
            },

            passwordConfirmationValidate() {
                return {
                    state: this.state('password'),
                };
            }
        },

        methods: {
            ...mapActions(['updateUser', 'displayToast']),

            fetchRequestedUser() {
                return new Promise((resolve) => {
                    if (!this.userId) {
                        this.loading = false;
                        return resolve();
                    }

                    axios.get(`/web/users/${this.userId}`)
                        .then(response => {
                            this.requestedUser = response.data;
                            this.loading = false;
                            resolve();
                        })
                        .catch(() => {
                            this.displayToast({text: 'Could not retrieve this user.', type: 'error'});
                            reject();
                        });
                });
            },

            prepareDetails() {
                this.editUser.first_name = this.thisUser.first_name;
                this.editUser.last_name = this.thisUser.last_name;
                this.editUser.name = this.thisUser.name;
                this.editUser.email = this.thisUser.email;
                this.editUser.contact_method = this.thisUser.contact_method;
                this.editUser.contact_details = this.thisUser.contact_details;
                this.editUser.joined_patreon = this.thisUser.joined_patreon;

                for (let i = 0; i < this.thisUser.supporter_type_credentials.length; i++) {
                    if (this.thisUser.supporter_type_credentials[i].supporter_type_code === 'patreon') {
                        this.editUser.patreon_username = this.thisUser.supporter_type_credentials[i].username;
                        this.editUser.patreon_email = this.thisUser.supporter_type_credentials[i].email;
                    }
                }
            },

            update() {
                this.errors = {};
                this.saving = true;
                this.updateUser({ uuid: this.thisUser.id, userDetails: this.editUser, isSelf: (!this.userId) })
                    .then(response => {
                        if (this.userId) {
                            this.requestedUser = response;
                        }
                        this.displayToast({text: 'Your details have been saved successfully.', type: 'success'});
                        this.saving = false;
                    })
                    .catch(errors => {
                        this.displayToast({text: 'There was a problem saving your details.', type: 'error'});
                        this.errors = errors.response.data;
                        this.saving = false;
                    })
            },

            invalidFeedback(field) {
                if (Object.keys(this.errors).length > 0) {
                    if (this.errors.hasOwnProperty(field)) {
                        return this.errors[field][0];
                    }
                }
                return '';
            },

            state(field) {
                if (Object.keys(this.errors).length > 0) {
                    if (this.errors.hasOwnProperty(field)) {
                        return false;
                    }
                }
                return null;
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            }
        }
    }
</script>

<style scoped>

</style>
