<template>
    <div :class="'img-' + size" class="status-icon info-animated">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2"><circle cx="65.1" cy="65.1" r="62.1" fill="none" stroke="#00BBDC" stroke-miterlimit="10" stroke-width="6" class="path circle"/><path fill="none" stroke="#00BBDC" stroke-linecap="round" stroke-miterlimit="10" stroke-width="6" d="M66 50v45M66 32v2" class="path line"/></svg>
    </div>
</template>

<script>
    export default {
        name: "InfoAnimated",

        props: {
            size: {
                type: String,
                default: '4x'
            }
        }
    }
</script>
