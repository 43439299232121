<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Patron Rewards
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" v-if="userRewards.total_pending">
                <div @click="showPending()" class="cursor-pointer">
                    <b-alert show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ userRewards.total_pending }} pending rewards. Click to view.
                    </b-alert>
                </div>
            </div>
            <div class="col-md-6" v-if="userRewards.total_in_progress">
                <div @click="showInProgress()" class="cursor-pointer">
                    <b-alert show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ userRewards.total_in_progress }} in progress rewards. Click to view.
                    </b-alert>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <data-table ref="datatable"
                            :data="userRewards"
                            :loading="loading"
                            idField="uuid"
                            :displayId="false"
                            :filters="filters"
                            editButtonText="View"
                            edit-route="/reward"
                            setStateColourOn="status"
                            successStateOn="Complete"
                            warningStateOn="Pending,In Progress"
                            dangerStateOn="Cancelled"
                            dateFormat="MMMM YYYY"
                            @reload="fetchUserRewards"
                ></data-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        this.createFilters();
    },

    data() {
        return {
            loading: true,
            userRewards: {},
            filters: []
        };
    },

    methods: {
        fetchUserRewards(queryString) {
            this.loading = true;
            axios.get(route('web.userRewards.index') + queryString)
                .then(response => {
                    this.userRewards = response.data;
                    this.loading = false;
                });
        },

        createFilters() {
            let filters = [];

            filters.push({
                title: 'Reward Type',
                columnName: 'reward_types.id',
                dataLabel: 'title',
                data: [
                    {id: 1, title: 'Illustration'},
                    {id: 2, title: 'Mentorship'},
                    {id: 3, title: 'Token'}
                ]
            },{
                title: 'Reward Status',
                columnName: 'user_rewards.reward_status_id',
                dataLabel: 'title',
                data: [
                    {id: 1, title: 'Pending'},
                    {id: 2, title: 'Accepted'},
                    {id: 3, title: 'In Progress'},
                    {id: 4, title: 'Complete'},
                    {id: 5, title: 'On Hold'},
                    {id: 6, title: 'Cancelled'}
                ]
            });

            this.filters = filters;
        },

        showPending() {
            this.$refs.datatable.triggerSelect(1, {id: 1, title: 'Pending'});
        },

        showInProgress() {
            this.$refs.datatable.triggerSelect(1, {id: 3, title: 'In Progress'});
        }
    }
}
</script>

<style scoped>

</style>
