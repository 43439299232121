const state = {
    user_state: 'loading',
    suspended_user_points: 0,
    user: {
        id: null,
        hook_id: null,
        name: '',
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        avatar: null,
        points: 0,
        total_rewards: 0,
        current_rewards: [],
        current_applications: [],
        bundle_run_purchases: [],
        companies: [],
        supporter_types: [],
        supporter_type_credentials: [],
        is_admin: false,
        is_company_admin: false,
        status: ''
    },
    histories: [],
    point_events: [],
    rewards: []
};

const getters = {
    user: state => {
        return state.user;
    },

    userState: state => {
        return state.user_state;
    },

    suspendedUserPoints: state => {
        return state.suspended_user_points;
    },

    userHistories: state => {
        return state.histories;
    },

    userPointEvents: state => {
        return state.point_events;
    },

    userRewards: state => {
        return state.rewards;
    },

    isAdmin: state => {
        return state.user.is_admin;
    },

    isCompanyAdmin: state => {
        return (state.user.is_admin || state.user.is_company_admin);
    }
};

const actions = {
    fetchUser({commit, dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/users/me`)
                .then(response => {
                    dispatch('setUser', response.data).then(() => {

                        dispatch('beginUserListener');

                        resolve();
                    });
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        commit('CLEAR_USER');

                        if (
                            error.response.data[0] === 'User is suspended in this company.' ||
                            error.response.data[1] === 'User is suspended in this company.'
                        ) {
                            commit('SET_USER_SUSPENDED');
                            commit('SET_SUSPENDED_USER_POINTS', error.response.data[0]);
                        } else {
                            commit('SET_USER_PENDING');
                        }
                    }
                    reject(error);
                });
        });
    },

    setUser({commit}, user) {
        return new Promise((resolve) => {
            commit('SET_USER', user);
            resolve();
        });
    },

    updateUser({commit, dispatch}, { uuid, userDetails, isSelf }) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/users/${uuid}`, userDetails)
                .then(response => {
                    if (!isSelf) {
                        return resolve(response.data);
                    }
                    dispatch('setUser', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        commit('CLEAR_USER');

                        if (
                            error.response.data[0] === 'User is suspended in this company.' ||
                            error.response.data[1] === 'User is suspended in this company.'
                        ) {
                            commit('SET_USER_SUSPENDED');
                            commit('SET_SUSPENDED_USER_POINTS', error.response.data[0]);
                        } else {
                            commit('SET_USER_PENDING');
                        }
                    }
                    reject(error);
                });
        });
    },

    updateUserAddress({commit, dispatch}, { uuid, address, isSelf }) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/users/${uuid}/address`, address)
                .then(response => {
                    if (!isSelf) {
                        return resolve(response.data);
                    }
                    dispatch('setUser', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        commit('CLEAR_USER');

                        if (
                            error.response.data[0] === 'User is suspended in this company.' ||
                            error.response.data[1] === 'User is suspended in this company.'
                        ) {
                            commit('SET_USER_SUSPENDED');
                            commit('SET_SUSPENDED_USER_POINTS', error.response.data[0]);
                        } else {
                            commit('SET_USER_PENDING');
                        }
                    }
                    reject(error);
                });
        });
    },

    fetchCompanyUser({dispatch, getters}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/company-users/${getters.user.id}/active`)
                .then(response => {
                    dispatch('setCompanyUser', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setCompanyUser({commit}, companyUser) {
        return new Promise((resolve) => {
            commit('SET_COMPANY_USER', companyUser);
            resolve();
        });
    },

    clearUser({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_USER');
            resolve();
        });
    },

    beginUserListener({getters, dispatch}) {
        window.Echo.channel(`userAggregated-${getters.user.hook_id}`).listen('.aggregated', e => {
            dispatch('setUser', e);
        });
    }
};

const mutations = {
    SET_USER(state, user) {
        state.user_state = 'active';
        state.user = user;
    },

    SET_USER_PENDING(state) {
        state.user_state = 'pending';
    },

    SET_USER_SUSPENDED(state) {
        state.user_state = 'suspended';
    },

    SET_SUSPENDED_USER_POINTS(state, points) {
        if (isNaN(parseInt(points, 10))) {
            points = 0;
        }
        state.suspended_user_points = points;
    },

    SET_COMPANY_USER(state, companyUser) {
        state.histories = companyUser.histories;
        state.point_events = companyUser.point_events;
        state.rewards = companyUser.rewards;
    },

    CLEAR_USER(state) {
        state.user_state = 'loading';
        state.user = {
            id: null,
            hook_id: null,
            name: '',
            first_name: '',
            last_name: '',
            full_name: '',
            email: '',
            avatar: null,
            points: 0,
            total_rewards: 0,
            current_rewards: [],
            current_applications: [],
            bundle_run_purchases: [],
            companies: [],
            supporter_types: [],
            supporter_type_credentials: [],
            is_admin: false
        };
        state.histories = [];
        state.point_events = [];
        state.rewards = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
