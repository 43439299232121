const state = {
    formats: [],
    lines: [],
    bundles: [],
    bundleRuns: []
};

const getters = {
    formats: state => {
        return state.formats;
    },

    lines: state => {
        return state.lines;
    },

    bundles: state => {
        return state.bundles;
    },

    bundleRuns: state => {
        return state.bundleRuns;
    },

    availableFormatIdsByLineId: state => lineId => {
        let formatIds = [];

        for (let i = 0; i < state.lines.length; i++) {
            if (!lineId || state.lines[i].id === lineId) {
                for (let j = 0; j < state.lines[i].formats.length; j++) {
                    formatIds.push(state.lines[i].formats[j].id);
                }
            }
        }

        return formatIds;
    },

    availableLineIdsByFormatId: state => formatId => {
        let lineIds = [];

        for (let i = 0; i < state.lines.length; i++) {
            for (let j = 0; j < state.lines[i].formats.length; j++) {
                if (!formatId || state.lines[i].formats[j].id === formatId) {
                    lineIds.push(state.lines[i].id);
                }
            }
        }

        return lineIds;
    },

    formatsByLine: (getters, rootGetters) => (lineId, nullText) => {
        let formats = [{value: null, text: nullText}];

        let formatIds = rootGetters.availableFormatIdsByLineId(lineId);

        for (let i = 0; i < rootGetters.formats.length; i++) {

            if (formatIds.includes(rootGetters.formats[i].id)) {
                formats.push({
                    value: rootGetters.formats[i].id,
                    text: rootGetters.formats[i].title
                });
            }
        }

        return formats;
    },

    linesByFormat: (getters, rootGetters) => (formatId, nullText) => {
        let lines = [{value: null, text: nullText}];

        let lineIds = rootGetters.availableLineIdsByFormatId(formatId);

        for (let i = 0; i < rootGetters.lines.length; i++) {

            if (lineIds.includes(rootGetters.lines[i].id)) {
                lines.push({
                    value: rootGetters.lines[i].id,
                    text: rootGetters.lines[i].title
                });
            }
        }

        return lines;
    }
};

const actions = {
    fetchFormats({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(route('web.merchandise.formats.index'))
                .then(response => {
                    dispatch('setFormats', response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchLines({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(route('web.merchandise.lines.index'))
                .then(response => {
                    dispatch('setLines', response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchBundles({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(route('web.merchandise.bundles.index'))
                .then(response => {
                    dispatch('setBundles', response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchBundleRuns({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(route('web.merchandise.bundles.runs.index'))
                .then(response => {
                    dispatch('setBundleRuns', response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setFormats({commit}, formats) {
        return new Promise((resolve) => {
            commit('SET_FORMATS', formats);
            resolve();
        });
    },

    setLines({commit}, lines) {
        return new Promise((resolve) => {
            commit('SET_LINES', lines);
            resolve();
        });
    },

    setBundles({commit}, bundles) {
        return new Promise((resolve) => {
            commit('SET_BUNDLES', bundles);
            resolve();
        });
    },

    setBundleRuns({commit}, bundleRuns) {
        return new Promise((resolve) => {
            commit('SET_BUNDLE_RUNS', bundleRuns);
            resolve();
        });
    },

    clearMerchandise({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_MERCHANDISE');
            resolve();
        });
    }
};

const mutations = {
    SET_FORMATS(state, formats) {
        state.formats = formats;
    },

    SET_LINES(state, lines) {
        state.lines = lines;
    },

    SET_BUNDLES(state, bundles) {
        state.bundles = bundles;
    },

    SET_BUNDLE_RUNS(state, bundleRuns) {
        state.bundleRuns = bundleRuns;
    },

    CLEAR_MERCHANDISE(state) {
        state.formats = [];
        state.lines = [];
        state.bundles = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
