<template>
    <div class="page-content">
        <div class="container">
            <empty-state
                    icon="hourglass"
                    title="Hang On A Sec..."
                    subtitle="We're just loading your details. Won't be a moment.">
            </empty-state>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LoadingUser"
    }
</script>

<style scoped>

</style>
