const state = {
    rewards: [],
    onyricaRewards: [],
    rewardSlots: [],
    activeBundleRuns: []
};

const getters = {
    rewards: state => {
        return state.rewards;
    },

    onyricaRewards: state => {
        return state.onyricaRewards;
    },

    rewardSlots: state => {
        return state.rewardSlots;
    },

    rewardSlotForThisReward: (getters, rootGetters) => reward => {
        for (let i = 0; i < rootGetters.rewardSlots.length; i++) {
            if (rootGetters.rewardSlots[i].reward_id === reward.id && rootGetters.rewardSlots[i].slots_available > 0) {
                return rootGetters.rewardSlots[i];
            }
        }
        return null;
    },

    activeBundleRuns: state => {
        return state.activeBundleRuns;
    },

    activeBundleRunsForThisReward: (getters, rootGetters) => reward => {
        for (let i = 0; i < rootGetters.activeBundleRuns.length; i++) {
            if (rootGetters.activeBundleRuns[i].reward_id === reward.id) {
                return rootGetters.activeBundleRuns[i];
            }
        }
        return null;
    },

    isAvailable: (getters, rootGetters) => reward => {
        // if (!rootGetters.canRedeem(reward)) {
        //     return false;
        // }
        if (!reward.requires_available_slot) {
            if (reward.limited_item) {
                for (let i = 0; i < rootGetters.activeBundleRuns.length; i++) {
                    if (rootGetters.activeBundleRuns[i].reward_id === reward.id && rootGetters.activeBundleRuns[i].remaining > 0) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        }
        for (let i = 0; i < rootGetters.rewardSlots.length; i++) {
            if (rootGetters.rewardSlots[i].reward_id === reward.id && rootGetters.rewardSlots[i].slots_available > 0) {
                return true;
            }
        }
        return false;
    },

    canRedeem: (getters, rootGetters) => reward => {
        if (rootGetters.user.points < reward.points_cost) {
            return false;
        }

        if (!reward.requires_available_slot) {
            if (reward.limited_item) {
                let bundleRunPurchases = rootGetters.user.bundle_run_purchases;

                for (let i = 0; i < rootGetters.activeBundleRuns.length; i++) {
                    if (
                        rootGetters.activeBundleRuns[i].reward_id === reward.id &&
                        rootGetters.activeBundleRuns[i].remaining > 0 &&
                        (
                            !bundleRunPurchases[rootGetters.activeBundleRuns[i].run_id] ||
                            bundleRunPurchases[rootGetters.activeBundleRuns[i].run_id] < rootGetters.activeBundleRuns[i].max_per_user
                        )
                    ) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        }

        let currentRewards = rootGetters.user.current_rewards;
        let currentApplications = rootGetters.user.current_applications;

        if (
            (!currentRewards || currentRewards.length === 0) &&
            (!currentApplications || currentApplications.length === 0)
        ) {
            return true;
        }

        if (reward.locks_rewards) {
            for (let i = 0; i < currentRewards.length; i++) {
                if (currentRewards[i].reward_type === reward.type.title) {
                    return false;
                }
            }
            for (let i = 0; i < currentApplications.length; i++) {
                if (currentApplications[i].reward_type === reward.type.title) {
                    return false;
                }
            }
        }
        return true;
    },

    redeemTitle: (getters, rootGetters) => reward => {
        if (reward.type.title === 'Merchandise') {
            if (reward.limited_item) {
                let bundleRunPurchases = rootGetters.user.bundle_run_purchases;

                for (let i = 0; i < rootGetters.activeBundleRuns.length; i++) {
                    if (rootGetters.activeBundleRuns[i].reward_id === reward.id && rootGetters.activeBundleRuns[i].display_quantity_remaining) {
                        return `${rootGetters.activeBundleRuns[i].remaining} Remaining`;
                    }
                }
            }
            return "Redeem Merchandise";
        }
        return rootGetters.availableSlotsCardText(reward);
    },

    availableSlotsCardText: (getters, rootGetters) => reward => {
        if (!reward.requires_available_slot) {
            return 'Redeem Reward';
        }

        for (let i = 0; i < rootGetters.rewardSlots.length; i++) {
            if (rootGetters.rewardSlots[i].reward_id === reward.id && rootGetters.rewardSlots[i].slots_available > 0) {
                if (rootGetters.rewardSlots[i].display_slot_quantity) {
                    if (rootGetters.rewardSlots[i].slots_available === 1) {
                        return `${rootGetters.rewardSlots[i].slots_available} Slot Available`;
                    }
                    return `${rootGetters.rewardSlots[i].slots_available} Slots Available`;
                }

                return "Apply For Reward";
            }
        }

        if (reward.requires_available_slot) {
            return "Applications Closed";
        }

        return "Unavailable";
    },

    displaySlotApplicationCount: (getters, rootGetters) => reward => {
        if (!reward.requires_available_slot) {
            return false;
        }

        for (let i = 0; i < rootGetters.rewardSlots.length; i++) {
            if (rootGetters.rewardSlots[i].reward_id === reward.id && rootGetters.rewardSlots[i].slots_available > 0) {
                return rootGetters.rewardSlots[i].display_slot_quantity;
            }
        }

        return false;
    },

    currentSlotApplicationCount: (getters, rootGetters) => reward => {
        for (let i = 0; i < rootGetters.rewardSlots.length; i++) {
            if (rootGetters.rewardSlots[i].reward_id === reward.id && rootGetters.rewardSlots[i].slots_available > 0) {
                if (rootGetters.rewardSlots[i].display_slot_quantity) {
                    return rootGetters.rewardSlots[i].total_applications;
                }

                return 0;
            }
        }
        return 0;
    },

    cannotRedeemMessage: (getters, rootGetters) => reward => {
        if (!reward.requires_available_slot) {
            if (reward.limited_item) {
                let bundleRunPurchases = rootGetters.user.bundle_run_purchases;

                for (let i = 0; i < rootGetters.activeBundleRuns.length; i++) {
                    if (rootGetters.activeBundleRuns[i].reward_id === reward.id) {
                        if (
                            rootGetters.activeBundleRuns[i].remaining > 0 &&
                            (
                                bundleRunPurchases[rootGetters.activeBundleRuns[i].run_id] &&
                                bundleRunPurchases[rootGetters.activeBundleRuns[i].run_id] < rootGetters.activeBundleRuns[i].max_per_user
                            )
                        ) {
                            return null;
                        }
                        return 'You cannot purchase any more of this limited item.';
                    }
                }
                return 'This merchandise reward is currently unavailable.';
            }
            return null;
        }

        let currentRewards = rootGetters.user.current_rewards;
        let currentApplications = rootGetters.user.current_applications;

        if (reward.locks_rewards) {
            if (currentRewards.length > 0) {
                for (let i = 0; i < currentRewards.length; i++) {
                    if (currentRewards[i].reward_type === reward.type.title) {
                        return 'You already have a pending reward of that type.';
                    }
                }
            }
            if (currentApplications.length > 0) {
                for (let i = 0; i < currentApplications.length; i++) {
                    if (currentApplications[i].reward_id === reward.id) {
                        return "You've already applied for this reward.";
                    }
                    if (currentApplications[i].reward_type === reward.type.title) {
                        return "You've already applied for a reward of that type.";
                    }
                }
            }
        }

        if (rootGetters.user.points < reward.points_cost) {
            let pointsReminingToBuy = reward.points_cost - rootGetters.user.points;
            if (reward.requires_available_slot) {
                return `You need ${pointsReminingToBuy} more points to apply for this reward.`;
            } else {
                return `You need ${pointsReminingToBuy} more points to redeem this reward.`;
            }
        }

        return null;
    },

    totalAfterRedemption: (getters, rootGetters) => reward => {
        if (!rootGetters.canRedeem(reward)) {
            return 0;
        }
        return (rootGetters.user.points - reward.points_cost);
    },
};

const actions = {
    fetchRewards({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/rewards`)
                .then(response => {
                    dispatch('setRewards', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchOnyricaRewards({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(`/onyrica-rewards`)
                .then(response => {
                    commit('SET_ONYRICA_REWARDS', response.data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchRewardSlots({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/reward-slots/active-slots`)
                .then(response => {
                    dispatch('setRewardSlots', response.data).then(() => {

                        dispatch('beginRewardSlotListener');

                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchActiveBundleRuns({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/merchandise/bundles/runs/active-bundle-runs`)
                .then(response => {
                    dispatch('setActiveBundleRuns', response.data).then(() => {

                        dispatch('beginActiveBundleRunsListener');

                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setRewards({commit}, rewards) {
        return new Promise((resolve) => {
            commit('SET_REWARDS', rewards);
            resolve();
        });
    },

    setRewardSlots({commit}, rewardSlots) {
        return new Promise((resolve) => {
            commit('SET_REWARD_SLOTS', rewardSlots);
            resolve();
        });
    },

    setActiveBundleRuns({commit}, activeBundleRuns) {
        return new Promise((resolve) => {
            commit('SET_ACTIVE_BUNDLE_RUNS', activeBundleRuns);
            resolve();
        });
    },

    clearRewards({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_REWARDS');
            resolve();
        });
    },

    clearRewardSlots({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_REWARD_SLOTS');
            resolve();
        });
    },

    clearActiveBundleRuns({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_ACTIVE_BUNDLE_RUNS');
            resolve();
        });
    },

    redeemReward({dispatch}, {reward, merchandiseItems = [], address = []}) {
        return new Promise((resolve, reject) => {
            if (!reward || !reward.id) {
                reject('No reward selected.');
                return;
            }

            if (reward.requires_available_slot) {
                reject('You can only apply for this type of reward.');
                return;
            }

            let postData = {
                reward_uuid: reward.id,
                merchandise_items: merchandiseItems,
                address: address
            };

            axios.post(`/web/user-rewards`, postData)
                .then(response => {
                    resolve();
                })
                .catch(error => {
                    reject(error.response);
                });
        });
    },

    applyForReward({dispatch}, {rewardSlot}) {
        return new Promise((resolve, reject) => {
            if (!rewardSlot || !rewardSlot.id) {
                reject('No reward slot selected.');
                return;
            }

            axios.post(`/web/reward-slots/${rewardSlot.id}/apply`)
                .then(response => {
                    resolve();
                })
                .catch(error => {
                    reject(error.response);
                });
        });
    },

    cancelReward({dispatch}, reward_uuid) {
        return new Promise((resolve, reject) => {
            if (!reward_uuid) {
                reject('No reward selected.');
                return;
            }

            axios.delete(`/web/user-rewards/${reward_uuid}`)
                .then(response => {
                    resolve();
                })
                .catch(errors => {
                    reject(error.response);
                });
        });
    },

    beginRewardSlotListener({getters, rootGetters, dispatch}) {
        window.Echo.channel(`rewardSlotsUpdated-${rootGetters.company.hook_id}`).listen('.rewardSlotsUpdated', e => {
            dispatch('fetchRewardSlots');
        });
    },

    beginActiveBundleRunsListener({getters, rootGetters, dispatch}) {
        window.Echo.channel(`activeBundleRunsUpdated-${rootGetters.company.hook_id}`).listen('.activeBundleRunsUpdated', e => {
            dispatch('fetchActiveBundleRuns');
        });
    }
};

const mutations = {
    SET_REWARDS(state, rewards) {
        state.rewards = rewards;
    },

    SET_ONYRICA_REWARDS(state, rewards) {
        state.onyricaRewards = rewards;
    },

    SET_REWARD_SLOTS(state, rewardSlots) {
        state.rewardSlots = rewardSlots;
    },

    SET_ACTIVE_BUNDLE_RUNS(state, activeBundleRuns) {
        state.activeBundleRuns = activeBundleRuns;
    },

    CLEAR_REWARDS(state) {
        state.rewards = [];
    },

    CLEAR_REWARD_SLOTS(state) {
        state.rewardSlots = [];
    },

    CLEAR_ACTIVE_BUNDLE_RUNS(state) {
        state.activeBundleRuns = [];
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
