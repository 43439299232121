<template>
    <b-modal
        content-class="shadow"
        hide-footer
        hide-header
        id="reward-cancel"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('reward-cancel')"></b-button>

        <h3>Cancel This Reward</h3>

        <p>Are you sure you want to cancel this reward? You'll need to book it again if you change your mind.</p>

        <p>Once you cancel the reward your points will be refunded to your account.</p>

        <b-alert :show="hasErrors" variant="danger" class="d-flex">
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
        </b-alert>

        <b-button variant="danger" class="mt-3" block @click="triggerCancelReward()" :disabled="cancelling">
            <i v-if="cancelling" class="fa fa-pulse fa-spinner"></i>
            Yes, Cancel My Reward
        </b-button>
        <b-button variant="primary" class="mt-3" block @click="$bvModal.hide('reward-cancel')">No Thanks</b-button>

    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        props: {
            userReward: {
                type: Object,
                default: {}
            }
        },

        data: function() {
            return {
                cancelling: false,
                errors: []
            }
        },

        computed: {
            hasErrors() {
                return this.errors.length > 0;
            }
        },

        methods: {
            ...mapActions(['cancelUserReward']),

            triggerCancelReward() {
                this.cancelling = true;
                this.cancelUserReward(this.userReward.id)
                    .then(() => {
                        this.$emit('updated');
                        this.close();
                        this.cancelling = false;
                    }).catch(errors => {
                        this.errors = errors.response.data;
                        this.cancelling = false;
                    });
            },

            close() {
                this.$bvModal.hide('reward-cancel');
            }
        }
    }
</script>

<style scoped>

</style>
