<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Merchandise Bundles
                </h3>
            </div>
        </div>
        <!-- MERCHANDISE LINES -->
        <b-tabs pills class="merchandise-line-tabs">
            <b-tab :title="`${activeBundles.length} Active Bundles`" @click="setTab('active')" :active="tabIsActive('active')">
                <div class="row">
                    <div class="col-md-12">
                        <div class="merchandise-line-grid mb-5">
                            <b-card
                                    @click="openBundleModal(null)"
                                    class="merchandise-card-new mb-5"
                                    align="center"
                                    no-body
                                    tag="article">
                                <h5>
                                    <i class="fa fa-plus"></i> New Bundle
                                </h5>
                            </b-card>

                            <b-card
                                    @click="openBundleModal(bundle)"
                                    class="merchandise-card mb-5"
                                    v-for="bundle in activeBundles"
                                    :key="bundle.id"
                                    align="center"
                                    no-body
                                    tag="article">
                                <div class="merchandise-line-img-container" v-if="bundleImage(bundle)">
                                    <b-card-img
                                            :src="bundleImage(bundle)"
                                            :alt="bundle.title"
                                            top>
                                    </b-card-img>
                                </div>
                                <p v-if="!bundleImage(bundle)" class="merchandise-line-no-image">
                                    No Image
                                </p>
                                <div class="merchandise-line-body">
                                    <b-card-title class="merchandise-line-title pt-2">
                                        {{ bundle.title }}
                                    </b-card-title>
<!--                                    <p class="text-sm mb-1">-->
<!--                                        <strong>{{ bundle.points_cost }}</strong> points.-->
<!--                                    </p>-->
                                    <p class="text-sm mb-1">
                                        Contains <strong>{{ bundle.items.length }}</strong> items.
                                    </p>
                                </div>
                            </b-card>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab :title="`${inactiveBundles.length} Inactive Bundles`" @click="setTab('inactive')" :active="tabIsActive('inactive')">
                <div class="row">
                    <div class="col-md-12">
                        <div class="merchandise-line-grid mb-5">
                            <b-card
                                    @click="openBundleModal(bundle)"
                                    class="merchandise-card mb-5"
                                    v-for="bundle in inactiveBundles"
                                    :key="bundle.id"
                                    align="center"
                                    no-body
                                    tag="article">
                                <div class="merchandise-line-img-container" v-if="bundleImage(bundle)">
                                    <b-card-img
                                            :src="bundleImage(bundle)"
                                            :alt="bundle.title"
                                            top>
                                    </b-card-img>
                                </div>
                                <p v-if="!bundleImage(bundle)" class="merchandise-line-no-image">
                                    No Image
                                </p>
                                <div class="merchandise-line-body">
                                    <b-card-title class="merchandise-line-title pt-2">
                                        {{ bundle.title }}
                                    </b-card-title>
<!--                                    <p class="text-sm mb-0">-->
<!--                                        <strong>{{ bundle.points_cost }}</strong> points.-->
<!--                                    </p>-->
                                    <p class="text-sm mb-1">
                                        Contains <strong>{{ bundle.items.length }}</strong> items.
                                    </p>
                                </div>
                            </b-card>
                        </div>
                    </div>
                </div>
            </b-tab>

            <b-tab :title="`${activeBundleRuns} Active Bundle Runs`" @click="setTab('bundleRuns')" :active="tabIsActive('bundleRuns')">
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-success float-right mb-3" @click="openBundleRunModal(null)">Create New Limited Bundle Run</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-container">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Bundle</th>
                                        <th>Is Active</th>
                                        <th>Qty In Run</th>
                                        <th>Max Per User</th>
                                        <th>Purchased</th>
                                        <th>Date Created</th>
                                        <th>Edit</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="bundleRun in bundleRuns" :key="bundleRun.id" :class="{ 'bg-success': bundleRun.active }">
                                        <td>{{ bundleRun.bundle_title }}</td>
                                        <td>{{ bundleRun.active ? "Yes" : "No" }}</td>
                                        <td>{{ bundleRun.quantity }}</td>
                                        <td>{{ bundleRun.max_per_user }}</td>
                                        <td>{{ bundleRun.purchased }}</td>
                                        <td>{{ bundleRun.created_at }}</td>
                                        <td>
                                            <button class="btn btn-primary btn-sm" @click="openBundleRunModal(bundleRun)" :disabled="deleting === bundleRun.id">Edit</button>
                                        </td>
                                        <td>
                                            <button class="btn btn-danger btn-sm" @click="deleteBundleRun(bundleRun)" :disabled="deleting === bundleRun.id">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </b-tab>
        </b-tabs>

        <merchandise-bundle-modal
            :formats="formats"
            :lines="lines"
            ref="merchandiseBundleModal"
            @reload="fetchBundles()"
        ></merchandise-bundle-modal>

        <merchandise-bundle-run-modal
            :bundles="bundles"
            ref="merchandiseBundleRunModal"
            @reload="fetchBundleRuns()"
        ></merchandise-bundle-run-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "CompanyMerchandiseBundles",

        data() {
            return {
                tab: 'active',
                deleting: null
            }
        },

        computed: {
            ...mapGetters(['formats', 'lines', 'bundles', 'bundleRuns']),

            activeBundles() {
                let bundles = [];
                for (let i = 0; i < this.bundles.length; i++) {
                    if (this.bundles[i].active) {
                        bundles.push(this.bundles[i]);
                    }
                }
                return bundles;
            },

            inactiveBundles() {
                let bundles = [];
                for (let i = 0; i < this.bundles.length; i++) {
                    if (!this.bundles[i].active) {
                        bundles.push(this.bundles[i]);
                    }
                }
                return bundles;
            },

            activeBundleRuns() {
                let activeBundles = 0;
                for (let i = 0; i < this.bundleRuns.length; i++) {
                    if (this.bundleRuns[i].active) {
                        activeBundles++;
                    }
                }
                return activeBundles;
            }
        },

        mounted() {
            this.fetchFormats();
            this.fetchLines();
            this.fetchBundles();
            this.fetchBundleRuns();
        },

        methods: {
            ...mapActions(['fetchFormats', 'fetchLines', 'fetchBundles', 'fetchBundleRuns', 'displayToast']),

            bundleImage(bundle) {
                if (!bundle.images.length) {
                    return null;
                }
                return bundle.images[0].path;
            },

            openBundleModal(bundle) {
                this.$refs.merchandiseBundleModal.open(bundle);
            },

            openBundleRunModal(bundleRun) {
                this.$refs.merchandiseBundleRunModal.open(bundleRun);
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            },

            deleteBundleRun(bundleRun) {
                if (this.deleting === bundleRun.id) return;

                this.deleting = bundleRun.id;

                axios.delete(route('web.merchandise.bundles.runs.run.destroy', bundleRun.id))
                    .then(() => {
                        this.displayToast({text: `The limited bundle run was deleted.`, type: 'success'});
                        this.fetchBundleRuns();
                        this.deleting = null;
                    })
                    .catch(errors => {
                        this.displayToast({text: errors.response.data, type: 'danger'});
                        this.deleting = null;
                    });
            }
        }
    }
</script>
