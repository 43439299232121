<template>
    <b-modal
        content-class="shadow"
        hide-footer
        hide-header
        id="reward-status-update"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('reward-status-update')"></b-button>

        <h3>Update Reward Status</h3>

        <p>Changing the status will send a notification to the reward owner.</p>

        <b-alert :show="hasErrors" variant="danger" class="d-flex">
            <ul>
                <li v-for="error in errors">{{ error }}</li>
            </ul>
        </b-alert>

        <multiselect
            v-model="status"
            :options="filteredStatuses"
            label="title"
            track-by="title"
            :allowEmpty="false"
        >
        </multiselect>

        <b-button variant="success" class="mt-3" block @click="triggerUpdateStatus()" :disabled="updating">
            <i v-if="updating" class="fa fa-pulse fa-spinner"></i>
            Update Status
        </b-button>
        <b-button variant="primary" class="mt-3" block @click="$bvModal.hide('reward-status-update')">Cancel</b-button>

    </b-modal>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        props: {
            userReward: {
                type: Object,
                default: {}
            }
        },

        data: function() {
            return {
                status: null,
                updating: false,
                errors: []
            }
        },

        computed: {
            ...mapGetters(['rewardStatuses']),

            filteredStatuses() {
                if (!this.userReward) {
                    return this.rewardStatuses;
                }

                let vm = this;
                return this.rewardStatuses.filter(status => {
                    if (!vm.userReward.reward || vm.userReward.reward.type === 'Illustration') {
                        return true;
                    }

                    switch (status.code) {
                        case 'in-progress':
                        case 'on-hold':
                            return false;
                        default:
                            return true;
                    }
                });
            },

            hasErrors() {
                return this.errors.length > 0;
            }
        },

        methods: {
            ...mapActions(['displayToast', 'updateUserRewardStatus']),

            triggerUpdateStatus() {
                if (this.status.code === this.userReward.reward_status.code) {
                    return;
                }

                this.updating = true;
                this.updateUserRewardStatus({ uuid: this.userReward.id, statusCode: this.status.code })
                    .then(() => {
                        this.displayToast( {text: 'The reward status was successfully updated.', type: 'success'});
                        this.$emit('updated');
                        this.close();
                        this.updating = false;
                    }).catch(errors => {
                        this.errors = errors.response.data;
                        this.updating = false;
                    });
            },

            open() {
                this.status = this.userReward.reward_status;
                this.$bvModal.show('reward-status-update');
            },

            close() {
                this.$bvModal.hide('reward-status-update');
            }
        }
    }
</script>

<style scoped>

</style>
