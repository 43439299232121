<template>
    <b-modal
        content-class="shadow"
        hide-footer
        hide-header
        id="company-user-edit"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('company-user-edit')"></b-button>

        <template v-if="user">
            <h3>Update {{ user.name }}</h3>

            <b-alert :show="hasErrors" variant="danger" class="d-flex">
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </b-alert>

            <p>Updating the Patreon tier will create a new supporter level notification for the user.</p>

            <multiselect
                class="multiselect"
                v-model="level"
                :options="levels"
                label="title"
                track-by="code"
                :allowEmpty="false"
            >
            </multiselect>

            <input v-if="user.status === 'pending'" class="form-control" type="number" v-model="legacy_points" />

            <!-- Update patreon level button -->
            <b-button variant="success" class="mt-3" block @click="updatePatreonLevel()" :disabled="updating">
                <i v-if="updating" class="fa fa-pulse fa-spinner"></i>
                Update User
            </b-button>

            <hr/>

            <b-form-checkbox
                id="send-email"
                v-model="sendEmailAlert"
                v-if="user.active"
            >
                Email the user that their account is being disabled
            </b-form-checkbox>

            <!-- Toggle user activate state button -->
            <b-button :variant="toggleActiveStateType" class="mt-3" block @click="toggleActiveState()" :disabled="toggling">
                <i v-if="toggling" class="fa fa-pulse fa-spinner"></i>
                {{ toggleActiveStateText }}
            </b-button>

            <hr/>

            <!-- Go to user settings -->
            <b-button variant="primary" class="mt-3" block :to="{ name: 'userSettings', params: { userId: user.uuid } }" target="_blank">
                View User
            </b-button>

            <!-- Close modal button -->
            <b-button variant="primary" class="mt-3" block @click="$bvModal.hide('company-user-edit')">Cancel</b-button>
        </template>

        <template v-if="!user">
            Error: No user has been set
        </template>

    </b-modal>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchSupporterLevels();
        },

        data() {
            return {
                user: null,
                level: null,
                legacy_points: 0,
                sendEmailAlert: false,
                levels: [],
                errors: [],
                updating: false,
                toggling: false
            }
        },

        computed: {
            hasErrors() {
                return this.errors.length > 0;
            },

            toggleActiveStateType() {
                return (this.user.active) ? 'danger' : 'success';
            },

            toggleActiveStateText() {
                return (this.user.active) ? 'Deactivate User' : 'Activate user';
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            open(user) {
                this.user = user;
                this.sendEmailAlert = false;
                this.errors = [];
                this.setSupporterLevel();
                this.$bvModal.show('company-user-edit');
            },

            fetchSupporterLevels() {
                axios.get(route('web.companySupporterTypes.patreonLevels'))
                    .then(response => {
                        this.levels = response.data;
                    })
            },

            reloadParent() {
                this.$emit('reload');
            },

            setSupporterLevel() {
                let supporterLevel = this.user.supporter_level;
                if (typeof supporterLevel === 'undefined' && Object.keys(this.user.supporter_levels).length) {
                    supporterLevel = this.user.supporter_levels.patreon.levels[0].title;
                }

                for (let i = 0; i < this.levels.length; i++) {
                    if (this.levels[i].title === supporterLevel) {
                        return this.level = this.levels[i];
                    }
                }
                this.level = null;
            },

            toggleActiveState() {
                this.toggling = true;
                axios.put(`/web/company-users/${this.user.uuid}/toggle-active`, {send_email: this.sendEmailAlert})
                    .then(response => {
                        this.user = response.data;
                        this.setSupporterLevel();
                        this.reloadParent();
                        this.toggling = false;
                        this.displayToast({text: `Active state updated successfully.`, type: 'success'});
                    })
                    .catch(errors => {
                        this.errors = errors.response.data;
                        this.toggling = false;
                        this.displayToast({text: `Could not update the active state.`, type: 'danger'});
                    });
            },

            updatePatreonLevel() {
                this.errors = [];
                this.updating = true;
                axios.put(`/web/company-users/${this.user.uuid}/supporter-level`, { supporter_type_level: this.level.id, legacy_points: this.legacy_points })
                    .then(response => {
                        this.user = response.data;
                        this.setSupporterLevel();
                        this.reloadParent();
                        this.updating = false;
                        this.displayToast({text: `Supporter level updated successfully.`, type: 'success'});
                    })
                    .catch(errors => {
                        this.errors = errors.response.data;
                        this.updating = false;
                        this.displayToast({text: `Could not update the supporter level.`, type: 'danger'});
                    });
            }
        }
    }
</script>

<style scoped>
    .multiselect {
        z-index: 99999;
    }
</style>
