import Vue from 'vue';
import Vuex from 'vuex';
import persisted from 'vuex-persistedstate';

import company from './modules/company';
import globals from './modules/globals';
import merchandise from './modules/merchandise';
import rewards from './modules/rewards';
import user from './modules/user';
import userReward from './modules/userReward';

Vue.use(Vuex);

let store = new Vuex.Store({
    plugins: [
        persisted({
            paths: [
                'company',
                'globals',
                'merchandise',
                'rewards',
                'user',
                'userReward'
            ]
        })
    ],
    state: {},
    modules: {
        company,
        globals,
        merchandise,
        rewards,
        user,
        userReward
    },
    getters: {},
    mutations: {},
    actions: {}
});

export default store;