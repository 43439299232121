import { render, staticRenderFns } from "./ConfirmUpdateRewardSlotsModal.vue?vue&type=template&id=d37f3234&scoped=true&"
import script from "./ConfirmUpdateRewardSlotsModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmUpdateRewardSlotsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d37f3234",
  null
  
)

export default component.exports