import Vue from 'vue';
import router from './routes';
import store from './vuex/store';
import VueRouter from 'vue-router';
import VueToasted from 'vue-toasted';
import { MonthPicker } from 'vue-month-picker';
import { MonthPickerInput } from 'vue-month-picker';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

window.Vue = require('vue');

require('./components');

Vue.use(VueRouter);

Vue.use(VueToasted, {
    iconPack : 'fontawesome'
});

Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

require('./bootstrap');

window.App = new Vue({
    el: '#app',
    store:store,
    router:router
});