<template>
    <div>
        <b-navbar>
            <div class="container pb-0 pt-3">
                <!-- Site name -->
                <b-navbar-brand :to="{name: 'index'}" class="text-decoration-none">
                    <img id="company-logo" src="https://starstickers.s3.eu-west-2.amazonaws.com/site/starstickers-logo.png" alt="StarStickers by Onyrica" />
<!--                    <h1 class="mb-0 mt-2 text-secondary font-weight-light">STARSTICKERS <span class="text-primary">|</span> <span class="text-primary text-xl" style="vertical-align: center;">by Onyrica</span></h1>-->
                </b-navbar-brand>

                <!-- Account menu -->
                <b-navbar-nav class="ml-auto">
                    <b-dropdown size="lg"  variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                            <img :src="user.avatar.path" class="avatar"> <i class="fa fa-caret-down"></i>
                        </template>
                        <b-dropdown-header>
                            <h6 class="mb-0">Hello {{ user.name }}</h6>
                        </b-dropdown-header>

                        <b-dropdown-divider></b-dropdown-divider>

                        <b-dropdown-item :to="{name: 'index'}">
                            <i class="fal fa-home fa-fw"></i> Home
                        </b-dropdown-item>

                        <b-dropdown-item
                                v-if="user.current_rewards && user.current_rewards.length"
                                :to="{name: 'reward', params: {uuid: user.current_rewards[0].id}}">
                            <i class="fal fa-paint-brush fa-fw"></i> In-progress Rewards
                        </b-dropdown-item>
                        <template v-if="!user.current_rewards || !user.current_rewards.length">
                            <b-dropdown-item
                                    id="in-progress-option">
                                <i class="fal fa-paint-brush fa-fw"></i> In-progress Rewards
                            </b-dropdown-item>
                            <b-tooltip target="in-progress-option" triggers="hover" variant="info" noninteractive>
                                Welp, looks like there’s nothing here… yet!
                            </b-tooltip>
                        </template>

                        <b-dropdown-item :to="{name: 'me', params: {startTab: 'points'}}">
                            <i class="fal fa-star fa-fw"></i> Point History
                        </b-dropdown-item>
                        <b-dropdown-item :to="{name: 'me', params: {startTab: 'rewards'}}">
                            <i class="fal fa-gift fa-fw"></i> Reward History
                        </b-dropdown-item>
                        <b-dropdown-item :to="{name: 'me', params: {startTab: 'history'}}">
                            <i class="fab fa-patreon fa-fw"></i> Patreon History
                        </b-dropdown-item>
                        <b-dropdown-item :to="{name: 'mySettings'}">
                            <i class="fal fa-user-edit fa-fw"></i> Edit Account
                        </b-dropdown-item>
                        <b-dropdown-item
                                :to="{name: 'companyDashboard'}"
                                v-if="isCompanyAdmin">
                            <i class="fal fa-building fa-fw"></i> Edit Company</b-dropdown-item>
                        <b-dropdown-item @click="logout()">
                            <i class="fal fa-power-off fa-fw"></i> Log Out
                        </b-dropdown-item>
                    </b-dropdown>
                </b-navbar-nav>
            </div>
        </b-navbar>

        <div class="page-header container">
            <div class="row home-stats" v-if="!isCompanyAdmin">

                <!-- Stats -->
                <div class="col-6 col-lg-3">
                    <router-link :to="{name: 'me', params: {startTab: 'points'}}" class="card-stat bg-primary">
                        <div class="text-stat">
                            <h5>Current points</h5>
                            <h4>{{ user.points }}</h4>
                        </div>
                        <i class="far fa-star stat-bg-icon"></i>
                    </router-link>
                </div>
                <div class="col-6 col-lg-3">
                    <router-link :to="{name: 'me', params: {startTab: 'history'}}" class="card-stat bg-primary">
                        <div class="text-stat">
                            <h5>Current tier</h5>
                            <h4>{{ patreonDetails.title }} <small>({{ patreonDetails.points }}pt/month)</small></h4>
                        </div>
                        <i class="fab fa-patreon stat-bg-icon"></i>
                    </router-link>
                </div>
                <div class="col-6 col-lg-3">
                    <router-link :to="currentRewardLink" class="card-stat bg-primary">
                        <div class="text-stat">
                            <h5>In-progress rewards</h5>
                            <h4>{{ currentReward }}</h4>
                        </div>
                        <i class="far fa-paint-brush stat-bg-icon"></i>
                    </router-link>
                </div>
                <div class="col-6 col-lg-3">
                    <router-link :to="{name: 'me', params: {startTab: 'applications'}}" class="card-stat bg-primary">
                        <div class="text-stat">
                            <h5>Active applications</h5>
                            <h4>{{ activeApplication }}</h4>
                        </div>
                        <i class="far fa-box-ballot stat-bg-icon"></i>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-6 offset-lg-3 col-lg-3">
                    <router-link :to="{name: 'guide'}" class="btn btn-block btn-secondary btn-sm">StarStickers Guide</router-link>
                </div>
                <div class="col-6 col-lg-3">
                    <a :href="companyUrl" target="_blank" class="btn btn-block btn-danger btn-sm">Onyrica on Patreon</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "PageHeader",

        computed: {
            ...mapGetters([
                'company',
                'user',
                'isCompanyAdmin'
            ]),

            patreonDetails() {
                if (!this.user.id) {
                    return '';
                }
                return this.user.supporter_types['patreon'].levels[0];
            },

            patreonCompanyDetails() {
                if (!this.company || !this.company.supporter_types.patreon) {
                    return null;
                }
                return this.company.supporter_types.patreon;
            },

            companyUrl() {
                if (!this.patreonCompanyDetails) {
                    return '#';
                }
                return this.patreonCompanyDetails.company_url;
            },

            currentReward() {
                if (!this.user.current_rewards || !this.user.current_rewards.length) {
                    return 'None';
                }
                for (let i = 0; i < this.user.current_rewards.length; i++) {
                    if (this.user.current_rewards[i].reward_type === 'Illustration') {
                        return this.user.current_rewards[i].reward;
                    }
                }
                return this.user.current_rewards[0].reward;
            },

            currentRewardLink() {
                if (!this.user.current_rewards || !this.user.current_rewards.length) {
                    return {name: 'me', params: {startTab: 'rewards'}};
                }
                for (let i = 0; i < this.user.current_rewards.length; i++) {
                    if (this.user.current_rewards[i].reward_type === 'Illustration') {
                        return {name: 'reward', params: {uuid: this.user.current_rewards[i].id}};
                    }
                }
                return {name: 'reward', params: {uuid: this.user.current_rewards[0].id}};
            },

            activeApplication() {
                if (!this.user.current_applications || !this.user.current_applications.length) {
                    return 'None';
                }
                return this.user.current_applications[0].reward_title;
            }
        },

        methods: {
            ...mapActions([
                'clearRewardSlots',
                'clearActiveBundleRuns',
                'clearCompany',
                'clearMerchandise',
                'clearRewards',
                'clearUser'
            ]),

            logout() {
                Promise.all([
                    this.clearRewardSlots(),
                    this.clearActiveBundleRuns(),
                    this.clearCompany(),
                    this.clearMerchandise(),
                    this.clearRewards(),
                    this.clearUser()
                ]).then(() => {
                    window.location = '/logout';
                });
            }
        }
    }
</script>

<style scoped>

</style>
