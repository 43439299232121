<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Patrons
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div @click="showInactiveUsers()" class="cursor-pointer">
                    <b-alert v-if="companyUsers.total_inactive_users" show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ companyUsers.total_inactive_users }} inactive patrons. Click to view.
                    </b-alert>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <data-table ref="datatable"
                            :data="companyUsers"
                            :loading="loading"
                            :displayId="false"
                            :filters="filters"
                            editButtonText="Edit User"
                            setStateColourOn="active"
                            dangerStateOn="0"
                            @reload="fetchCompanyUsers"
                            @editlistener="showEditUserModal"
                ></data-table>
            </div>
        </div>

        <company-user-edit ref="companyUserEdit" @reload="reload"></company-user-edit>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.createFilters();
        },

        data() {
            return {
                loading: true,
                companyUsers: {},
                filters: []
            };
        },

        methods: {
            fetchCompanyUsers(queryString) {
                this.loading = true;
                axios.get(route('web.companyUsers.index') + queryString)
                    .then(response => {
                        this.companyUsers = response.data;
                        this.loading = false;
                    });
            },

            reload() {
                this.$refs.datatable.reloadData(this.companyUsers.current_page);
            },

            createFilters() {
                let filters = [];

                filters.push({
                    title: 'Active State',
                    columnName: 'company_users.active',
                    dataLabel: 'title',
                    data: [
                        {id: 1, title: 'Active'},
                        {id: 0, title: 'Inactive'}
                    ]
                });

                this.filters = filters;
            },

            showEditUserModal(user) {
                this.$refs.companyUserEdit.open(user);
            },

            showInactiveUsers() {
                this.$refs.datatable.triggerSelect(0, {id: 0, title: 'Inactive'});
            }
        }
    }
</script>

<style scoped>

</style>
