<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-9">
                <h3>
                    Reward Slots
                </h3>
            </div>
            <div class="col-md-3">
                <b-button variant="success" class="float-right" block @click="showCreateRewardSlotModal()">Create New Reward Slot</b-button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6" v-if="rewardSlots.total_paused">
                <div @click="showPaused()" class="cursor-pointer">
                    <b-alert show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ rewardSlots.total_paused }} paused rewards slots. Click to view.
                    </b-alert>
                </div>
            </div>
            <div class="col-md-6" v-if="rewardSlots.total_active">
                <div @click="showActive()" class="cursor-pointer">
                    <b-alert show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ rewardSlots.total_active }} active reward slots. Click to view.
                    </b-alert>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <data-table ref="datatable"
                            :data="rewardSlots"
                            :loading="loading"
                            idField="uuid"
                            :displayId="false"
                            :filters="filters"
                            editButtonText="View"
                            edit-route="/company/reward-slots"
                            setStateColourOn="status"
                            successStateOn="Accepting Applications"
                            dateFormat="MMMM YYYY"
                            @reload="fetchRewardSlots"
                ></data-table>
            </div>
        </div>

        <create-reward-slots-modal
            ref="createRewardSlots"
            :availableRewards="availableRewards"
            @reload="reload"
        ></create-reward-slots-modal>
    </div>
</template>

<script>
export default {
    mounted() {
        this.createFilters();
    },

    data() {
        return {
            loading: true,
            rewardSlots: {},
            filters: [],
            availableRewards: []
        };
    },

    methods: {
        fetchRewardSlots(queryString) {
            this.loading = true;
            axios.get(route('web.rewardSlots.index') + queryString)
                .then(response => {
                    this.rewardSlots = response.data;
                    this.availableRewards = response.data.available_rewards;
                    this.loading = false;
                });
        },

        createFilters() {
            let filters = [];

            filters.push({
                title: 'Reward Slot Status',
                columnName: 'reward_slots.status',
                dataLabel: 'title',
                data: [
                    {id: 'paused', title: 'Paused'},
                    {id: 'accepting_applications', title: 'Accepting Applications'},
                    {id: 'complete', title: 'Complete'},
                    {id: 'cancelled', title: 'Cancelled'}
                ]
            });

            this.filters = filters;
        },

        showPaused() {
            this.$refs.datatable.triggerSelect(0, {id: 'paused', title: 'Paused'});
        },

        showActive() {
            this.$refs.datatable.triggerSelect(0, {id: 'accepting_applications', title: 'Accepting Applications'});
        },

        showCreateRewardSlotModal() {
            this.$refs.createRewardSlots.open();
        },

        reload() {
            this.$refs.datatable.reloadData(this.rewardSlots.current_page);
        },
    }
}
</script>

<style scoped>

</style>
