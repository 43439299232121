<template>
    <div class="page-container">

        <template v-if="!user.id && userState === 'loading'">
            <loading-user></loading-user>
        </template>

        <template v-if="userState === 'pending' || userState === 'suspended'">
            <user-inactive></user-inactive>
        </template>

        <template v-if="user.id && userState === 'active'">
            <!-- Nav -->
            <page-header></page-header>

            <!-- Content -->
            <div class="page-content">
                <div class="container">
                    <router-view :key="$route.fullPath"></router-view>
                </div>
            </div>

            <!-- Footer -->
            <page-footer></page-footer>
        </template>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchUser()
                .then(() => {
                    if (!this.user.id) {
                        window.location = '/logout';
                    }
                });
        },

        computed: {
            ...mapGetters(['user', 'userState'])
        },

        methods: {
            ...mapActions(['fetchUser'])
        }
    }
</script>

<style lang="scss">
</style>
