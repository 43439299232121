<template>
    <b-modal
            content-class="shadow"
            hide-footer
            hide-header
            id="change-slot-quantity-modal"
            no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('change-slot-quantity-modal')"></b-button>

        <h3>Change Slot Quantity</h3>

        <div class="form-group">
            <label for="quantity" class="col-form-label">Quantity of Slots</label>
            <input id="quantity" class="form-control" type="number" min="1"  v-model="updatedQuantity" />
        </div>

        <b-button variant="success" class="mt-3" block @click="updateQuantity()">
            Update Quantity
        </b-button>
    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "ChangeSlotQuantityModal",

        props: {
            quantity: {
                type: Number,
                required: true,
                default: 1
            }
        },

        data() {
            return {
                updatedQuantity: 1
            }
        },

        computed: {
            ...mapGetters(['user']),

            hasErrors() {
                return Object.keys(this.errors).length > 0;
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            open() {
                this.updatedQuantity = this.quantity;
                this.$bvModal.show('change-slot-quantity-modal');
            },

            updateQuantity() {
                this.$emit('update', {quantity: this.updatedQuantity});
                this.$bvModal.hide('change-slot-quantity-modal')
            }
        }
    }
</script>

<style scoped>

</style>
