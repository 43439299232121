<template>
    <b-modal
            hide-header
            content-class="shadow"
            id="application-info-modal"
            title="Applicant Information"
            no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="close()"></b-button>

        <div v-if="application">
            <h3>{{ application.user_name }}</h3>
            <p>Tier: <strong>{{ application.supporter_level }}</strong></p>
            <p>Joined: <strong>{{ application.joined_date }}</strong></p>
            <p>Points: <strong>{{ application.current_points }}</strong></p>

            <b-tabs pills>
                <!-- Recent Rewards -->
                <b-tab title="Recent Rewards" @click="setTab('rewards')" :active="tabIsActive('rewards')">
                    <div class="row" v-if="application.recent_rewards.length">
                        <div class="col-md-12">
                            <ul class="list-group">
                                <li class="list-group-item" v-for="(reward, key) in application.recent_rewards" :key="key">
                                    <h5>{{ reward.title }}<span class="text-muted text-sm"> - {{ reward.type }}</span></h5>
                                    <p class="text-sm mb-1">Date Booked: <strong>{{ reward.date_booked }}</strong></p>
                                    <p class="text-sm mb-0" v-if="!reward.reward_completed">Status: <strong>{{ reward.status }}</strong></p>
                                    <p class="text-sm mb-0" v-if="reward.reward_completed">Date Completed: <strong>{{ reward.date_completed }}</strong></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <empty-state
                        v-if="!application.recent_rewards.length"
                        icon="empty-set"
                        title=""
                        subtitle="This user has not yet received any rewards">
                    </empty-state>
                </b-tab>

                <!-- Previous Applications -->
                <b-tab title="Previous Applications" @click="setTab('applications')" :active="tabIsActive('applications')">
                    <div class="row" v-if="!hasNoRecentApplications">
                        <div class="col-md-12">
                            <ul class="list-group">
                                <li class="list-group-item"
                                    :class="recentApplication.was_successful_application ? 'list-group-item-success' : ''"
                                    v-for="(recentApplication, key) in application.recent_applications" :key="key"
                                >
                                    <h5>{{ recentApplication.title }}<span class="text-muted text-sm"> - {{ recentApplication.type }}</span></h5>
                                    <p class="text-sm mb-1">Date Applied: <strong>{{ recentApplication.date_applied }}</strong></p>
                                    <p class="text-sm mb-1">Slot Status: <strong>{{ recentApplication.slot_status }}</strong></p>
                                    <p class="text-sm mb-0">Was Successful: <strong>{{ recentApplication.was_successful_application ? 'Yes' : 'No' }}</strong></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <empty-state
                        v-if="hasNoRecentApplications"
                        icon="empty-set"
                        title=""
                        subtitle="This user has not yet applied for any other rewards">
                    </empty-state>
                </b-tab>
            </b-tabs>
        </div>

        <template #modal-footer>
            <b-button variant="primary" class="mt-3" block @click="close()">Close</b-button>
        </template>
    </b-modal>
</template>

<script>
    export default {
        name: "ApplicationInfoModal",

        props: ['rewardSlotUuid'],

        data() {
            return {
                application: null,
                tab: 'rewards'
            }
        },

        computed: {
            hasNoRecentApplications() {
                return (
                    !this.application.recent_applications.length ||
                    (
                        this.application.recent_applications.length === 1 &&
                        this.application.recent_applications[0].reward_slot_id === this.rewardSlotUuid
                    )
                );
            }
        },

        methods: {
            open(application) {
                this.application = application;
                this.tab = 'rewards';
                this.$bvModal.show('application-info-modal');
            },

            close() {
                this.$bvModal.hide('application-info-modal');
            },

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'rewards') {
                    return true;
                }
                return tab === this.tab;
            }
        }
    }
</script>

<style scoped>

</style>
