<template>
    <div class="col-12">
        <template v-if="company.faqs.length > 0">
            <div class="text-center">
                <b-button v-b-toggle.faq-list variant="outline-primary">StarStickers Guide</b-button>
            </div>
            <b-collapse id="faq-list" class="mt-4">
                <article class="faq" v-for="(faq, i) in company.faqs">
                    <h6>{{ faq.title }}</h6>
                    <div v-html="faq.faq"></div>
                    <hr v-if="displayPageBreak(i)" class="divider">
                </article>
                <div class="text-center">
                    <b-button v-b-toggle.faq-list><i class="fal fa-chevron-up"></i></b-button>
                </div>
            </b-collapse>
        </template>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "Faqs",

        computed: {
            ...mapGetters(['company'])
        },

        methods: {
            displayPageBreak(i) {
                return i < (this.company.faqs.length - 1);
            }
        }
    }
</script>

<style scoped>

</style>
