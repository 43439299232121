<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-9">
                <h3>
                    Point Events
                </h3>
            </div>
            <div class="col-md-3">
                <b-button variant="success" class="float-right" block @click="showManagePointEventModal(null)">Create New Point Event</b-button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <data-table ref="datatable"
                            :data="pointEvents"
                            :loading="loading"
                            :displayId="false"
                            :filters="filters"
                            editButtonText="Edit Point Event"
                            @reload="fetchPointEvents"
                            @editlistener="showManagePointEventModal"
                ></data-table>
            </div>
        </div>

        <manage-point-event
                ref="managePointEvent"
                :users="users"
                :pointEventTypes="pointEventTypes"
                @reload="reload"
        ></manage-point-event>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: true,
                pointEvents: {},
                filters: [],
                pointEventTypes: [],
                users: [],
            };
        },

        methods: {
            fetchPointEvents(queryString) {
                this.loading = true;
                axios.get(route('web.pointEvents.index') + queryString)
                    .then(response => {
                        this.pointEvents = response.data;
                        this.pointEventTypes = response.data.point_event_types;
                        this.users = response.data.users;
                        this.createFilter(response.data.point_event_types);
                        this.loading = false;
                    });
            },

            reload() {
                this.$refs.datatable.reloadData(this.pointEvents.current_page);
            },

            createFilter(data) {
                this.filters = [
                    {
                        title: 'Point Event Type',
                        columnName: 'point_events.point_event_type_id',
                        dataLabel: 'title',
                        data: data
                    }
                ];
            },

            showManagePointEventModal(pointEvent) {
                this.$refs.managePointEvent.open(pointEvent);
            }
        }
    }
</script>

<style scoped>

</style>
