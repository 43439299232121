<template>
    <div class="empty-state" :class="{ 'sm': small, 'white': white }">

        <!-- Icon -->
        <i v-if="icon && !imageUrl && !loadingIcon && !statusIcon" class="icon" :class="[brandIcon ? 'fab' : 'fal', 'fa-' + icon]"></i>

        <!-- Loading Icon -->
        <i v-if="loadingIcon" class="icon fal fal-pulse fal-spinner"></i>

        <!-- Image -->
        <img v-if="imageUrl" class="image" :src="imageUrl">

        <!-- Status Icon -->
        <component
            v-if="statusIcon"
            :is="statusIcon + '-animated'"
            :size="iconSize">
        </component>

        <!-- Title -->
        <h1 v-if="title" v-html="title" class="title"></h1>

        <!-- Subtitle -->
        <h2 v-if="subtitle" v-html="subtitle" class="subtitle"></h2>

        <!-- Content -->
        <p v-if="content" v-html="content" class="content"></p>

        <slot></slot>

    </div>
</template>

<script>
    export default {
        props: {
            brandIcon: {
                type: Boolean,
                default: false
            },
            content: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: "exclamation"
            },
            loadingIcon: {
                type: Boolean,
                default: false
            },
            iconSize: {
                type: String,
                default: "2x"
            },
            imageUrl: {
                type: String,
                default: ""
            },
            inline: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            statusIcon: {
                type: String,
                default: ""
            },
            title: {
                type: String,
                default: "Nothing to See Here..."
            },
            subtitle: {
                type: String,
                default: ""
            },
            white: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>

</style>
