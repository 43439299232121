<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-9">
                <h3>
                    Company Settings
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <label for="home-message">Home Page Message</label>
                <vue-editor
                    id="home-message"
                    class="mb-3"
                    v-model="company.information.home_message"
                    placeholder="Enter the message displayed at the top of the home page."
                    :editorToolbar="editorToolbar"
                ></vue-editor>
            </div>

            <div class="col-lg-6 col-md-12">
                <label for="footer-message">Footer Message</label>
                <vue-editor
                    id="footer-message"
                    class="mb-3"
                    v-model="company.information.footer_message"
                    placeholder="Enter the message displayed on the footer."
                    :editorToolbar="editorToolbar"
                ></vue-editor>
            </div>
        </div>

        <hr class="mb-5" />

        <div class="row">
            <div class="col-md-6">
                <label for="visitor-home-message">Visitor Page Message</label>
                <vue-editor
                    id="visitor-home-message"
                    class="mb-3"
                    v-model="company.information.visitor_home_message"
                    placeholder="Enter the message displayed at the top of the visitor page."
                    :editorToolbar="editorToolbar"
                ></vue-editor>
            </div>

            <div class="col-md-6">
                <label for="visitor-footer-message">Visitor Footer Message</label>
                <vue-editor
                    id="visitor-footer-message"
                    class="mb-3"
                    v-model="company.information.visitor_footer_message"
                    placeholder="Enter the message displayed on the footer of the visitor page."
                    :editorToolbar="editorToolbar"
                ></vue-editor>
            </div>
        </div>

        <!-- Controls -->
        <div class="row">
            <div class="col-md-12 text-right">
                <button class="btn btn-success btn-lg" @click="update()" :disabled="saving">
                    <i class="fa fa-pulse fa-spinner" v-if="saving"></i>
                    Save Changes
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "CompanySettings",

        data: function() {
            return {
                saving: false,
                editorToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ["bold", "italic", "underline"],
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                    ],
                    ["blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ color: [] }, { background: [] }],
                    ["link"],
                    ["clean"] // remove formatting button
                ]
            }
        },

        computed: {
            ...mapGetters(['company']),
        },

        methods: {
            ...mapActions(['displayToast', 'updateCompanyInformation']),

            update() {
                if (this.saving) return;

                this.saving = true;
                this.updateCompanyInformation(this.company.information)
                    .then(() => {
                        this.displayToast({text: 'Your details have been saved successfully.', type: 'success'});
                        this.saving = false;
                    })
                    .catch(() => {
                        this.displayToast({text: 'There was a problem saving your details.', type: 'error'});
                        this.saving = false;
                    })
            },
        }
    }
</script>

<style scoped>

</style>
