import MultiSelect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';

/**
 * Icons
 */
Vue.component('error-animated', require('./components/icons/ErrorAnimated').default);
Vue.component('info-animated', require('./components/icons/InfoAnimated').default);
Vue.component('loading-animated', require('./components/icons/LoadingAnimated').default);
Vue.component('success-animated', require('./components/icons/SuccessAnimated').default);
Vue.component('warning-animated', require('./components/icons/WarningAnimated').default);

/**
 * Nav
 */
Vue.component('page-header', require('./components/nav/PageHeader').default);
Vue.component('page-footer', require('./components/nav/PageFooter').default);
Vue.component('menu-link', require('./components/nav/MenuLink').default);
Vue.component('all-rewards-links', require('./components/nav/AllRewardsLinks').default);

/**
 * Rewards
 */
Vue.component('featured-rewards', require('./components/rewards/FeaturedRewards').default);
Vue.component('event-rewards', require('./components/rewards/EventRewards').default);

/**
 * Misc
 */
Vue.component('data-table', require('./components/misc/DataTable').default);
Vue.component('empty-state', require('./components/misc/EmptyState').default);
Vue.component('faqs', require('./components/misc/Faqs').default);
Vue.component('loading-user', require('./views/misc/LoadingUser').default);
Vue.component('user-inactive', require('./views/misc/UserInactive').default);
Vue.component('redeemed-banner', require('./components/misc/RedeemedBanner').default);
Vue.component('dropzone', require('./components/misc/Dropzone').default);
Vue.component('multiselect', MultiSelect);
Vue.component('vue-editor', VueEditor);

/**
 * Modals
 */
Vue.component('edit-avatar-modal', require('./components/modals/users/EditAvatar').default);

Vue.component('merchandise-format-modal', require('./components/modals/company/merchandise/MerchandiseFormatModal').default);
Vue.component('merchandise-line-modal', require('./components/modals/company/merchandise/MerchandiseLineModal').default);
Vue.component('merchandise-bundle-modal', require('./components/modals/company/merchandise/MerchandiseBundleModal').default);
Vue.component('merchandise-bundle-run-modal', require('./components/modals/company/merchandise/MerchandiseBundleRunModal').default);

Vue.component('company-user-edit', require('./components/modals/users/CompanyUserEdit').default);

Vue.component('redeem-merchandise-reward-modal', require('./components/modals/rewards/RedeemMerchandiseReward').default);
Vue.component('redeem-reward-small-modal', require('./components/modals/rewards/RedeemRewardSmall').default);
Vue.component('redeem-reward-large-modal', require('./components/modals/rewards/RedeemRewardLarge').default);
Vue.component('reward-redeemed-modal', require('./components/modals/rewards/RewardRedeemed').default);

Vue.component('reward-cancel', require('./components/modals/rewards/RewardCancel').default);
Vue.component('reward-status-update', require('./components/modals/rewards/RewardStatusUpdate').default);

Vue.component('manage-point-event', require('./components/modals/pointEvents/ManagePointEvent').default);

Vue.component('create-reward-slots-modal', require('./components/modals/company/rewardSlots/CreateRewardSlotsModal').default);
Vue.component('change-slot-quantity-modal', require('./components/modals/company/rewardSlots/ChangeSlotQuantityModal').default);
Vue.component('confirm-update-reward-slots-modal', require('./components/modals/company/rewardSlots/ConfirmUpdateRewardSlotsModal').default);
Vue.component('application-info-modal', require('./components/modals/company/rewardSlots/ApplicationInfoModal').default);
