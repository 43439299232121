<template>
    <div>
        <div class="container">
            <div class="row" v-if="!visitor">
                <div class="col-sm-6 text-center"
                     v-html="company.information.footer_message">
                </div>

                <div class="col-sm-6 text-center">
                    <h4>Want more points?</h4>
                    <p>
                        Consider leveling your Patreon tier up!
                    </p>
                    <a :href="patreonUrl" target="_blank" class="btn btn-danger btn-sm mb-3">Support me on Patreon</a>
                    <p>
                        Or participate in our <a href="/point-events">Point Events</a>.
                    </p>
                </div>
            </div>

            <div class="row" v-if="visitor">
                <div class="col-12 text-center"
                     v-html="company.information.visitor_footer_message">
                    <a href="https://www.patreon.com/onyrica" target="_blank" class="btn btn-danger btn-sm mb-3">Support me on Patreon</a>
                </div>
                <div class="col-12 text-center">
                    <a href="https://www.patreon.com/onyrica" target="_blank" class="btn btn-danger btn-sm mb-3">Support me on Patreon</a>
                </div>
            </div>
        </div>
        <footer class="page-footer">
            <div class="container-fluid">
                <!-- Social Media -->
                <ul class="social-links">
                    <li><a :href="'https://www.patreon.com/onyrica/posts'" target="_blank" title="Patreon"><i class="fab fa-patreon fa-fw"></i></a></li>
                    <li><a :href="'https://onyrica.tumblr.com/'" target="_blank" title="Tumblr"><i class="fab fa-tumblr fa-fw"></i></a></li>
                    <li><a :href="'https://twitter.com/Onyrica'" target="_blank" title="Twitter"><i class="fab fa-twitter fa-fw"></i></a></li>
                    <li><a :href="'https://www.instagram.com/onyrica/'" target="_blank" title="Instagram"><i class="fab fa-instagram fa-fw"></i></a></li>
                    <li><a :href="'https://www.deviantart.com/onyrica'" target="_blank" title="DeviantArt"><i class="fab fa-deviantart fa-fw"></i></a></li>
                    <li><a :href="'https://www.artstation.com/onyrica'" target="_blank" title="ArtStation"><i class="fab fa-artstation fa-fw"></i></a></li>
                    <li><a :href="'https://www.twitch.tv/onyrica'" target="_blank" title="Twitch"><i class="fab fa-twitch fa-fw"></i></a></li>
                    <li><a :href="'mailto:hello@onyrica.art'" target="_blank" title="Email Me"><i class="fa fa-envelope fa-fw"></i></a></li>
                </ul>
            </div>
        </footer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: "Footer",

        props: {
            visitor: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapGetters(['company']),

            patreonUrl() {
                if (!this.company || !this.company.supporter_types.patreon) {
                    return null;
                }
                return this.company.supporter_types.patreon.company_url;
            }
        }
    }
</script>

<style scoped>

</style>
