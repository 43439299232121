import VueRouter from 'vue-router';
import store from './vuex/store';

let routes = [
    {
        path: '/visitor',
        name: 'visitor',
        component: require('./views/visitor/Index').default,
        meta: {
            title: 'Welcome to StarStickers!'
        }
    },
    {
        path: '/company',
        component: require('./views/CompanyIndex').default,
        meta: { middleware: 'company' },
        children: [
            {
                path: '/',
                redirect: { name: 'companyDashboard' }
            },
            {
                path: '/company/dashboard',
                name: 'companyDashboard',
                component: require('./views/company/CompanyDashboard').default,
                meta: {
                    title: 'StarStickers - Company Dashboard'
                }
            },
            {
                path: '/company/settings',
                name: 'companySettings',
                component: require('./views/company/CompanySettings').default,
                meta: {
                    title: 'StarStickers - Company Details'
                }
            },
            {
                path: '/company/rewards',
                name: 'companyRewards',
                component: require('./views/company/CompanyRewards').default,
                meta: {
                    title: 'StarStickers - Rewards'
                }
            },
            {
                path: '/company/featured-rewards',
                name: 'companyFeaturedRewards',
                component: require('./views/company/CompanyFeaturedRewards').default,
                meta: {
                    title: 'StarStickers - Featured Rewards'
                }
            },
            {
                path: '/company/rewards/:uuid',
                name: 'companyReward',
                props: true,
                component: require('./views/company/CompanyReward').default,
                meta: {
                    title: 'StarStickers - Reward'
                }
            },
            {
                path: '/company/reward-slots',
                name: 'companyRewardSlots',
                component: require('./views/company/rewardSlots/Index').default,
                meta: {
                    title: 'StarStickers - Reward Slots'
                }
            },
            {
                path: '/company/reward-slots/:uuid',
                name: 'companyRewardSlots.rewardSlot',
                props: true,
                component: require('./views/company/rewardSlots/RewardSlot').default,
                meta: {
                    title: 'StarStickers - Reward Slot'
                }
            },
            {
                path: '/company/merchandise',
                name: 'companyMerchandise',
                component: require('./views/company/merchandise/CompanyMerchandise').default,
                meta: {
                    title: 'StarStickers - Merchandise'
                }
            },
            {
                path: '/company/merchandise-bundles',
                name: 'companyMerchandiseBundles',
                component: require('./views/company/merchandise/CompanyMerchandiseBundles').default,
                meta: {
                    title: 'StarStickers - Merchandise Bundles'
                }
            },
            {
                path: '/company/point-events',
                name: 'companyPointEvents',
                component: require('./views/company/CompanyPointEvents').default,
                meta: {
                    title: 'StarStickers - Point Events'
                }
            },
            {
                path: '/company/users',
                name: 'companyUsers',
                component: require('./views/company/CompanyUsers').default,
                meta: {
                    title: 'StarStickers - Company Users'
                }
            },
            {
                path: '/company/user-rewards',
                name: 'companyUserRewards',
                component: require('./views/company/CompanyUserRewards').default,
                meta: {
                    title: 'StarStickers - User Rewards'
                }
            },
            {
                path: '/company/merchandise-rewards',
                name: 'companyMerchandiseRewards',
                component: require('./views/company/CompanyMerchandiseRewards').default,
                meta: {
                    title: 'StarStickers - Merchandise Rewards'
                }
            },
            {
                path: '/company/user-point-events',
                name: 'companyUserPointEvents',
                component: require('./views/company/CompanyUserPointEvents').default,
                meta: {
                    title: 'StarStickers - User Point Bundles'
                }
            }
        ]
    },
    {
        path: '/',
        component: require('./views/Index').default,
        children: [
            {
                path: '/',
                name: 'index',
                component: require('./views/Dashboard').default,
                meta: {
                    title: 'StarStickers'
                }
            },
            {
                path: '/rewards/merchandise',
                name: 'merchandiseRewards',
                props: { types: ['merchandise'] },
                component: require('./views/rewards/Rewards').default,
                meta: {
                    title: 'StarStickers - Merchandise Rewards'
                }
            },
            {
                path: '/rewards/mentorship',
                name: 'mentorshipRewards',
                props: { types: ['mentorship'] },
                component: require('./views/rewards/Rewards').default,
                meta: {
                    title: 'StarStickers - Mentorship Rewards'
                }
            },
            {
                path: '/rewards/token',
                name: 'tokenRewards',
                props: { types: ['discount', 'token'] },
                component: require('./views/rewards/Rewards').default,
                meta: {
                    title: 'StarStickers - Token Rewards'
                }
            },
            {
                path: '/rewards/illustration',
                name: 'illustrationRewards',
                props: { types: ['illustration'] },
                component: require('./views/rewards/Rewards').default,
                meta: {
                    title: 'StarStickers - Illustration Rewards'
                }
            },
            {
                path: '/me/settings',
                name: 'mySettings',
                component: require('./views/users/UserSettings').default,
                meta: {
                    title: 'StarStickers - My Account'
                }
            },
            {
                path: '/me/:startTab',
                name: 'me',
                props: true,
                component: require('./views/users/UserDetails').default,
                meta: {
                    title: 'StarStickers - My Account'
                }
            },
            {
                path: '/users/:userId/settings',
                name: 'userSettings',
                props: true,
                component: require('./views/users/UserSettings').default,
                meta: {
                    title: 'StarStickers - User Account',
                    middleware: 'user-admin'
                }
            },
            {
                path: '/users/:userId',
                name: 'user',
                props: true,
                component: require('./views/users/UserDetails').default,
                meta: {
                    title: 'StarStickers - User Account',
                    middleware: 'user-admin'
                }
            },
            {
                path: '/reward/:uuid',
                name: 'reward',
                props: true,
                component: require('./views/userRewards/UserRewardDetails').default,
                meta: {
                    title: 'StarStickers - My Reward'
                }
            },
            {
                path: '/guide',
                name: 'guide',
                props: true,
                component: require('./views/Guide.vue').default,
                meta: {
                    title: 'StarStickers Guide'
                }
            },
            {
                path: '/point-events',
                name: 'pointEvents',
                props: true,
                component: require('./views/PointEvents.vue').default,
                meta: {
                    title: 'StarStickers - Extra Point Events'
                }
            },
            // {
            //     path: '/playground',
            //     name: 'playground',
            //     component: require('./views/misc/Playground').default,
            //     meta: {
            //         title: 'Playground - weeeeeeee!'
            //     }
            // },
            {
                path: '/register'
            },
            {
                path: '*',
                redirect: { name: 'index' }
            }
        ]
    }
];

let router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    },
    mode: 'history',
    base: '/',
    fallback: true, //router should fallback to hash (#) mode when the browser does not support history.pushState
});

router.beforeEach((to, from, next) => {
    to.matched.some(record => {
        let user = store.getters.user;
        if (record.meta.middleware === 'company') {
            if (user.id && (user.is_admin || user.is_company_admin)) {
                next();
            } else {
                next('/');
            }
        }
        if (record.meta.middleware === 'user-admin') {
            if (user.id && (user.is_admin || user.is_company_admin)) {
                next();
            } else {
                next('/me/settings');
            }
        }
    });

    window.document.title = (to.meta && to.meta.title) ? to.meta.title : 'StarStickers';

    window.scrollTo(0, 0);

    next();
});

export default router;
