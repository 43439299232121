<template>
    <b-modal
        body-class="p-0"
        content-class="shadow"
        hide-footer
        hide-header
        :id="reward.code"
        modal-class="reward-modal vertical"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="close()"></b-button>
        <div class="d-flex flex-wrap">
            <div class="col-md-12 p-0">

                <!-- Image Carousel -->
                <b-carousel>
                    <b-carousel-slide
                        v-for="example in reward.examples"
                        :key="example.id"
                        :img-src="example.path"
                        style="height: 280px;">
                    </b-carousel-slide>
                </b-carousel>

                <!-- Modal Content -->
                <div class="modal-body">
                    <h3>{{ reward.title }}</h3>
                    <h4 class="text-primary">{{ reward.points_cost }} Points</h4>
                    <div v-if="!requestingRedemption" v-html="reward.description"></div>

                    <!-- Redeem details -->
                    <template v-if="canRedeem(reward) && !requestingRedemption">
                        <b-alert :show="hasErrors" variant="danger" class="d-flex">
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </b-alert>

                        <div class="redemption-controls">
                            <b-button variant="success" block @click="requestRedemption()">Redeem Now for {{ reward.points_cost }} Points</b-button>
                        </div>
                    </template>

                    <!-- Confirm redemption -->
                    <template v-if="(canRedeem(reward) || redeeming) && requestingRedemption">
                        <label>Before you redeem</label>
                        <div v-html="reward.type.redemption_note"></div>
                        <div class="redemption-controls">
                            <table class="redemption-table">
                                <tr>
                                    <td>Reward Cost</td>
                                    <td class="cost reward-cost">{{ reward.points_cost }}</td>
                                </tr>
                                <tr>
                                    <td>Current Points</td>
                                    <td class="cost">{{ user.points }}</td>
                                </tr>
                                <tr>
                                    <td>Points After Reward</td>
                                    <td class="cost">{{ totalAfterRedemption(reward) }}</td>
                                </tr>
                            </table>

                            <b-button v-if="canRedeem" variant="success" class="mb-3" block @click="redeem()" :disabled="redeeming">
                                <i v-if="redeeming" class="fa fa-pulse fa-spinner"></i>
                                Redeem Now for {{ reward.points_cost }} Points
                            </b-button>



                            <b-button variant="link" block @click="cancelRequest()">I've Changed My Mind</b-button>
                        </div>
                    </template>

                    <!-- Can't redeem -->
                    <template v-if="!canRedeem(reward) && !redeeming">
                        <hr class="divider">

                        <empty-state
                            icon="paint-brush"
                            title=""
                            :subtitle="cannotRedeemMessage(reward)">
                        </empty-state>
                    </template>

                    <!-- Temp close bookings -->
                    <template v-if="false">
                        <hr class="divider">

                        <empty-state
                            icon="paint-brush"
                            title=""
                            subtitle="We're temporarily halting reward bookings while people sign up to the service.">
                        </empty-state>
                    </template>
                </div>

            </div>
        </div>

    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props:['reward', 'showDates'],

    data: function() {
        return {
            errors: {},
            requestingRedemption: false,
            redeeming: false
        }
    },

    computed: {
        ...mapGetters([
            'cannotRedeemMessage',
            'canRedeem',
            'totalAfterRedemption',
            'user'
        ]),

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    },

    methods: {
        ...mapActions(['redeemReward']),

        redeem() {
            this.redeeming = true;
            this.redeemReward({
                reward: this.reward
            }).then(() => {
                this.$emit('redeemed', this.reward);
                this.reset();
            }).catch(error => {
                this.errors = error.data;
                this.requestingRedemption = false;
                this.redeeming = false;
            });
        },

        requestRedemption() {
            this.requestingRedemption = true;
        },

        cancelRequest() {
            this.reset();
            this.requestingRedemption = false;
        },

        close() {
            this.$bvModal.hide(this.reward.code);
            this.reset();
        },

        reset() {
            this.errors = {};
            this.requestingRedemption = false;
            this.redeeming = false;
        }
    }
}
</script>

<style scoped>

</style>
