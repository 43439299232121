<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Patron Point Bundles
                </h3>
            </div>
        </div>

        <template v-if="loading">
            <empty-state
                    icon="hourglass-half"
                    title="Loading..."
                    subtitle="We're checking for any pending patron point bundles.">
            </empty-state>
        </template>

        <template v-if="!loading">
            <empty-state
                    v-if="!userPointEvents.length"
                    icon="star"
                    title="No Pending Point Bundles"
                    subtitle="There are no pending patron point bundles for the current period">
            </empty-state>

            <div v-if="userPointEvents.length">
                <button v-if="selected.length" class="btn btn-success" @click="activate()">Activate</button>
                <button class="btn btn-info" @click="selectAll()">Select All</button>
                <button v-if="selected.length" class="btn btn-info" @click="deselectAll()">Deselect All</button>

                <b-table-simple responsive>
                    <b-thead>
                        <b-tr>
                            <b-th>User</b-th>
                            <b-th>Point Event</b-th>
                            <b-th>Supporter Level</b-th>
                            <b-th>Points</b-th>
                            <b-th>Generated</b-th>
                            <b-th>Select</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr v-for="userPointEvent in userPointEvents" :key="userPointEvent.point_event_id">
                            <b-th>{{ userPointEvent.user_name }}</b-th>
                            <b-th>{{ userPointEvent.point_event_type }}</b-th>
                            <b-th>{{ userPointEvent.supporter_type_level }}</b-th>
                            <b-th>{{ userPointEvent.points }}</b-th>
                            <b-th>{{ parseDate(userPointEvent.created_at) }}</b-th>
                            <b-th>
                                <b-form-checkbox
                                        :id="'checkbox-' + userPointEvent.point_event_id"
                                        v-model="selected"
                                        :name="'checkbox-' + userPointEvent.point_event_id"
                                        :value="userPointEvent.point_event_id"
                                ></b-form-checkbox>
                            </b-th>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.fetchUserPointEvents();
        },

        data() {
            return {
                activating: false,
                loading: true,
                selected: [],
                userPointEvents: []
            };
        },

        methods: {
            fetchUserPointEvents() {
                this.loading = true;
                axios.get(route('web.userPointEvents.pending'))
                    .then(response => {
                        this.userPointEvents = response.data;
                        this.loading = false;
                    });
            },

            activate() {
                this.loading = true;
                axios.post(route('web.userPointEvents.activate'), { to_activate: this.selected })
                    .then(() => {
                        this.fetchUserPointEvents();
                    });
            },

            parseDate(date) {
                return moment(date, 'YYYY-MM-DD HH:mm:ss').format('ddd Do MMM, YYYY');
            },

            selectAll() {
                for (let i = 0; i < this.userPointEvents.length; i++) {
                    if (!this.selected.includes(this.userPointEvents[i].point_event_id)) {
                        this.selected.push(this.userPointEvents[i].point_event_id);
                    }
                }
            },

            deselectAll() {
                this.selected = [];
            }
        }
    }
</script>

<style scoped>

</style>
