<template>
    <div class="col-10 offset-1">
        <div class="mb-5">
            <h2>Extra Point Events</h2>
        </div>
        <p class="mb-4">
            As a Patreon supporter, you get points just for pledging every month. But what’s that? You need a few extra to get that sketch of your 47th DnD character? We’ve got you covered! Below is a list of all the ways you can get bonus points:
        </p>

        <p class="mb-4">
            <span class="font-weight-bold">Art challenges - 10 or 15 points/bi-monthly</span><br/>
            No better way to take the dust off that art tablet you bought 5 years ago on an inspired impulse buy! Every month I publish an art challenge with a loose theme on the Artroom Discord and Patreon timeline. The challenges are open to all forms of media: drawing, writing, photobashing, sculpting, interpretative dancing… most importantly though, they are open to everyone and all skill levels. As long as enough time, care and effort was put into the piece, it will qualify for points!
        </p>

        <p class="mb-4">
            <span class="font-weight-bold">Refer a friend - Double tier points/one-off per friend</span><br/>
            Everything’s better with a friend- especially if you get something out of it! If you convince a friend to join my Patreon, you will both get a double point boost on the tier you have signed up to! For example: If you’re an Advanced patron (20$) and your friend joins the Intermediate tier (10$), you and your friend will both get an extra 10 points on top of your monthly allocation. This bonus applies one time per friend, but will be reapplied for new referrals- meaning, the more friends you bring, the more points you get!<br/>
            <span class="font-italic">Please note: New friends must be pledged for at least a month before the referee points get allocated to your account.</span>
        </p>

        <p class="mb-4">
            <span class="font-weight-bold">Pledge anniversary - Double tier points/one-off per year</span><br/>
            Happy Patreon Birthday to youuuu! If you’ve been pledged for a year- first off, THANK YOU. To celebrate your Artling anniversary, I’ll double that month’s points for the tier you’re pledged to at the time! This bonus will be applied for every pledge anniversary- with likely bonus points being added the longer you pledge. Because Starstickers are better than cake.
        </p>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters(['company'])
        }
    }
</script>

<style scoped>

</style>
