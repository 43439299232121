<template>
    <div class="container">
        <template v-if="statsLoaded">
            <div class="row">
                <div class="col-md-6" v-if="pausedSlots.length">
                    <h5>Paused Slots</h5>
                    <div class="row">
                        <div class="col-md-12" v-for="(slot, key) in pausedSlots" :key="key">
                            <router-link :to="{ name: 'companyRewardSlots.rewardSlot', params: { uuid: slot.id } }"
                                         class="card-stat bg-info">
                                <div class="text-stat">
                                    <h6>Slots opened: {{ slot.slots_opened }}</h6>
                                    <h4>{{ slot.reward_type }}: {{ slot.reward_title }}</h4>
                                    <p>{{ slot.slots_available }} {{ pluralize('Slot', slot.slots_available) }} - {{ slot.total_applications }} {{ pluralize('Application', slot.total_applications) }}</p>
                                </div>
                                <i class="fal fa-circle-pause stat-bg-icon"></i>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div :class="(pausedSlots.length) ? 'col-md-6' : 'col-md-12'">
                    <h5>Open Slots</h5>
                    <div class="row">
                        <div class="col-md-12" v-if="activeSlots.length" v-for="(slot, key) in activeSlots" :key="key">
                            <router-link :to="{ name: 'companyRewardSlots.rewardSlot', params: { uuid: slot.id } }"
                                         class="card-stat bg-success">
                                <div class="text-stat">
                                    <h6>Slots opened: {{ slot.slots_opened }}</h6>
                                    <h4>{{ slot.reward_type }}: {{ slot.reward_title }}</h4>
                                    <p>{{ slot.slots_available }} {{ pluralize('Slot', slot.slots_available) }} - {{ slot.total_applications }} {{ pluralize('Application', slot.total_applications) }}</p>
                                </div>
                                <i class="fal fa-circle-check stat-bg-icon"></i>
                            </router-link>
                        </div>
                        <div class="col-md-12" v-if="!activeSlots.length">
                            <empty-state
                                icon="empty-set"
                                title=""
                                subtitle="There's currently no active slots accepting applications.">
                            </empty-state>
                        </div>
                    </div>
                </div>
            </div>

            <hr class="mt-2 mb-4" />

            <div class="row">
                <div class="col-md-4">
                    <router-link :to="{ name: 'companyUsers' }" class="card-stat bg-success">
                        <div class="text-stat">
                            <h6>Users</h6>
                            <h4>{{ users.pending_activation }} Pending Users</h4>
                            <p>{{ users.total }} Total Users</p>
                        </div>
                        <i class="fal fa-users stat-bg-icon"></i>
                    </router-link>
                </div>

                <div class="col-md-4">
                    <router-link :to="{ name: 'companyUserRewards' }" class="card-stat bg-warning">
                        <div class="text-stat">
                            <h6>Rewards</h6>
                            <h4>{{ rewards.pending }} Pending Rewards</h4>
                            <p>{{ rewards.in_progress }} In Progress Rewards</p>
                        </div>
                        <i class="fal fa-treasure-chest stat-bg-icon"></i>
                    </router-link>
                </div>

                <div class="col-md-4">
                    <router-link :to="{ name: 'companyUserPointEvents' }" class="card-stat bg-info">
                        <div class="text-stat">
                            <h6>User Point Bundles</h6>
                            <h4>{{ points.for_review }} For Review</h4>
                        </div>
                        <i class="fal fa-star stat-bg-icon"></i>
                    </router-link>
                </div>
            </div>
        </template>

        <empty-state
                v-if="!statsLoaded"
                icon="hourglass"
                title="Hang On A Sec..."
                subtitle="We're just loading the dashboard stats.">
        </empty-state>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        mounted() {
            this.fetchCompanyStats();
        },

        data() {
            return {
                highlightedAvailability: null
            }
        },

        computed: {
            ...mapGetters(['companyStats', 'pluralize']),

            statsLoaded() {
                return Object.keys(this.companyStats).length > 0;
            },

            pausedSlots() {
                return this.companyStats.paused_slots;
            },

            activeSlots() {
                return this.companyStats.active_slots;
            },

            users() {
                return this.companyStats.users;
            },

            rewards() {
                return this.companyStats.user_rewards;
            },

            points() {
                return this.companyStats.point_events;
            }
        },

        methods: {
            ...mapActions(['fetchCompanyStats'])
        }
    }
</script>

<style scoped>

</style>
