<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-10">
                <h3>
                    Merchandise Rewards
                </h3>
            </div>
            <div class="col-md-2">
                <a class="btn btn-success form-control" :href="downloadDataLink" target="_blank">Download</a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div @click="showPending()" class="cursor-pointer">
                    <b-alert v-if="merchRewards.total_pending" show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ merchRewards.total_pending }} pending orders. Click to view.
                    </b-alert>
                </div>
            </div>
            <div class="col-md-6">
                <div @click="showInProgress()" class="cursor-pointer">
                    <b-alert v-if="merchRewards.total_in_progress" show variant="info" class="d-flex">
                        <i class="fal fa-info-circle"></i> There are {{ merchRewards.total_in_progress }} accepted orders. Click to view.
                    </b-alert>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <data-table ref="datatable"
                            :data="merchRewards"
                            :loading="loading"
                            idField="uuid"
                            :displayId="false"
                            :filters="filters"
                            editButtonText="View"
                            edit-route="/reward"
                            setStateColourOn="status"
                            successStateOn="Complete"
                            warningStateOn="Pending,In Progress"
                            dangerStateOn="Cancelled"
                            dateFormat="MMMM YYYY"
                            @reload="fetchMerchRewards"
                ></data-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.createFilters();
        },

        data() {
            return {
                loading: true,
                merchRewards: {},
                filters: []
            };
        },

        computed: {
            downloadDataLink() {
                return route('web.userRewards.merchandiseDownload');
            }
        },

        methods: {
            fetchMerchRewards(queryString) {
                this.loading = true;
                axios.get(route('web.userRewards.merchandise') + queryString)
                    .then(response => {
                        this.merchRewards = response.data;
                        this.loading = false;
                    });
            },

            createFilters() {
                let filters = [];

                filters.push({
                    title: 'Reward Status',
                    columnName: 'user_rewards.reward_status_id',
                    dataLabel: 'title',
                    data: [
                        {id: 1, title: 'Pending'},
                        {id: 2, title: 'Accepted'},
                        {id: 3, title: 'In Progress'},
                        {id: 4, title: 'Complete'},
                        {id: 5, title: 'On Hold'},
                        {id: 6, title: 'Cancelled'}
                    ]
                });

                this.filters = filters;
            },

            showPending() {
                this.$refs.datatable.triggerSelect(0, {id: 1, title: 'Pending'});
            },

            showInProgress() {
                this.$refs.datatable.triggerSelect(0, {id: 3, title: 'In Progress'});
            }
        }
    }
</script>

<style scoped>

</style>
