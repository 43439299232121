<template>
    <b-modal
            content-class="shadow"
            hide-footer
            hide-header
            id="manage-point-event"
            no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="$bvModal.hide('manage-point-event')"></b-button>

        <template v-if="!loading">

            <h3 v-if="pointEvent">
                {{ pointEvent.title }}<br/>
                <small v-if="pointEvent.description">{{ pointEvent.description }}</small>
            </h3>
            <h3 v-if="!pointEvent">
                New Point Event
            </h3>

            <b-alert :show="hasErrors" variant="danger" class="d-flex">
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </b-alert>

            <div class="form-group" v-if="isNew">
                <label for="point-event-type" class="col-form-label">Point Event Type</label>
                <select id="point-event-type" class="form-control" v-model="editingPointEvent.point_event_type" @change="selectedUser = null">
                    <option :value="null">Select a point event type</option>
                    <option v-for="type in availablePointEventTypes" :value="type">{{ type.title }}</option>
                </select>
            </div>
            <p class="text-xs font-italic" v-if="editingPointEvent.point_event_type">
                <i class="fal fa-exclamation-circle"></i>
                <span v-if="allowSingleUserPerEvent">A patron may receive this point event <strong>only once</strong>.</span>
                <span v-if="!allowSingleUserPerEvent">A patron may receive this point event multiple times.</span>
            </p>

            <div class="form-group">
                <label for="note" class="col-form-label">Description</label>
                <input id="note" class="form-control" type="text" v-model="editingPointEvent.note" />
            </div>

            <div class="form-group" v-if="!isLegacyPoints">
                <label for="points" class="col-form-label">Points Awarded</label>
                <input id="points" class="form-control" type="text" v-model="editingPointEvent.points" />
            </div>
            <p class="text-xs font-italic" v-if="isLegacyPoints">
                <i class="fal fa-exclamation-circle"></i> Legacy points are set on a case by case basis when activating a patron.
            </p>

            <div class="form-group">
                <label for="available-users" class="col-form-label">Available Users</label>
                <select id="available-users" class="form-control" v-model="selectedUser">
                    <option v-if="availableUsers.length" :value="null">Select a user to add to the point event</option>
                    <option v-if="!availableUsers.length" :value="null">There are no more available users</option>
                    <option v-for="user in availableUsers" :value="user">{{ user.name }}</option>
                </select>
            </div>

            <div class="form-group">
                <label for="existing-users" class="col-form-label">Users with point event:</label>
                <ul id="existing-users" v-if="usersInPointEvent.length">
                    <li v-for="user in usersInPointEvent">{{ user.name }}<span v-if="user.count > 1"> x{{ user.count }}</span></li>
                </ul>
                <p v-if="!usersInPointEvent.length" class="text-xs">
                    No users have been added yet.
                </p>
            </div>

            <b-button variant="success" class="mt-3" block @click="savePointEvent()" :disabled="updating || !editingPointEvent.point_event_type">
                <i v-if="updating" class="fa fa-pulse fa-spinner"></i>
                Save Point Event
            </b-button>
        </template>

        <empty-state
                v-if="loading"
                icon="hourglass-half"
                title="Loading..."
                subtitle="Loading Point Event.">
        </empty-state>

    </b-modal>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        props: {
            users: {
                type: Array,
                required: true,
                default: []
            },
            pointEventTypes: {
                type: Array,
                required: true,
                default: []
            }
        },

        data() {
            return {
                pointEvent: null,

                editingPointEvent: {
                    id: null,
                    point_event_type: null,
                    company: "",
                    company_users: [],
                    note: "",
                    points: 0
                },
                selectedUser: null,

                loading: false,
                updating: false,
                errors: []
            }
        },

        computed: {
            ...mapGetters(['user']),

            hasErrors() {
                return Object.keys(this.errors).length > 0;
            },

            availablePointEventTypes() {
                return this.pointEventTypes.filter((type) => {
                    return (
                        type.code !== 'legacy-points' &&
                        type.code !== 'referred-a-friend' &&
                        type.code !== 'registration-anniversary'
                    );
                });
            },

            availableUsers() {
                let existingCompanyUserIds = [];

                for (let i = 0; i < this.editingPointEvent.company_users.length; i++) {
                    existingCompanyUserIds.push(this.editingPointEvent.company_users[i].id);
                }

                return this.users.filter((user) => {
                    if (this.user.id === user.uuid) {
                        return false;
                    }

                    if (!this.allowSingleUserPerEvent) {
                        return true;
                    }

                    return !existingCompanyUserIds.includes(user.id);
                });
            },

            usersInPointEvent() {
                let users = this.editingPointEvent.company_users;

                let filteredUsers = [];
                for (let i = 0; i < users.length; i++) {
                    if (!filteredUsers[users[i].id]) {
                        filteredUsers[users[i].id] = {
                            id: users[i].id,
                            name: users[i].name,
                            uuid: users[i].uuid,
                            count: 0
                        }
                    }
                    filteredUsers[users[i].id].count++;
                }

                return filteredUsers.filter(function( element ) {
                    return element !== undefined;
                });
            },

            allowSingleUserPerEvent() {
                return this.editingPointEvent.point_event_type && (
                    this.editingPointEvent.point_event_type.code === 'challenge-completed' ||
                    this.editingPointEvent.point_event_type.code === 'voted-in-a-poll'
                );
            },

            isLegacyPoints() {
                return this.editingPointEvent.point_event_type && this.editingPointEvent.point_event_type.code && this.editingPointEvent.point_event_type.code === 'legacy-points';
            },

            isNew() {
                return this.editingPointEvent.id === null;
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            open(pointEvent = null) {
                this.setEmptyPointEvent();

                this.loading = true;
                this.errors = [];

                this.pointEvent = pointEvent;
                this.loadPointEvent();

                this.$bvModal.show('manage-point-event');
            },

            loadPointEvent() {
                if (!this.pointEvent) {
                    return this.loading = false;
                }

                this.loading = true;
                axios.get(route('web.pointEvents.pointEvent.show', this.pointEvent.uuid))
                    .then(response => {
                        this.editingPointEvent = response.data;
                        this.loading = false;
                    })
            },

            savePointEvent() {
                this.errors = [];
                this.updating = true;

                let verb = this.isNew ? 'post' : 'put';
                let endpoint = this.isNew ? route('web.pointEvents.store') : route('web.pointEvents.pointEvent.update', this.pointEvent.uuid);

                axios[verb](endpoint, this.getPayload())
                    .then(response => {
                        this.displayToast({text: `Point event saved.`, type: 'success'});
                        this.pointEvent = response.data;
                        this.editingPointEvent = response.data;
                        this.selectedUser = null;
                        this.updating = false;
                        this.$emit('reload');

                    }).catch(errors => {
                        console.log(errors);
                        this.displayToast({text: `Could not save the point event.`, type: 'danger'});
                        this.errors = errors.response.data;
                        this.updating = false;
                    })
            },

            getPayload() {
                return {
                    point_event_type_id: this.editingPointEvent.point_event_type.id,
                    note: this.editingPointEvent.note,
                    points: this.editingPointEvent.points,
                    company_user_id: this.selectedUser ? this.selectedUser.id : null
                };
            },

            setEmptyPointEvent() {
                this.editingPointEvent = {
                    id: null,
                    type: null,
                    company: "",
                    company_users: [],
                    note: "",
                    points: 0
                };
            }
        }
    }
</script>

<style scoped>

</style>