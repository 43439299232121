<template>
    <div>
        <vue-dropzone
                :id="`${type}-upload`"
                :ref="`${type}-upload`"
                :options="dropzoneOptions"
                @vdropzone-success="uploadComplete"
                @vdropzone-error="uploadError"
        ></vue-dropzone>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import { mapActions } from 'vuex';

    export default {
        name: "Dropzone",

        components: {
            vueDropzone: vue2Dropzone
        },

        props: {
            ownerClass: {
                type: String,
                required: true
            },
            ownerId: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
            uploadText: {
                type: String,
                default: 'Upload your files'
            },
            overwrite: {
                type: Boolean,
                default: false
            },
            fileSize: {
                type: Number,
                default: 5
            },
            acceptedFiles: {
                type: String,
                default: 'image/*'
            }
        },

        data: function () {
            return {
                dropzoneOptions: {
                    url: '/web/files',
                    headers: { 'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]').content },
                    thumbnailWidth: 150,
                    maxFilesize: this.fileSize,
                    acceptedFiles: this.acceptedFiles,
                    dictDefaultMessage: `<i class='fa fa-cloud-upload'></i> ${this.uploadText}<br/><span class="text-xs">Image - ${this.fileSize}mb Max</span>`,
                    params: () => {
                        return ({
                            owner_class: this.ownerClass,
                            owner_id: this.ownerId,
                            type: this.type,
                            overwrite: this.overwrite
                        })
                    },
                }
            }
        },

        methods: {
            ...mapActions(['displayToast']),

            uploadComplete(file, response) {
                let type = this.type.charAt(0).toUpperCase() + this.type.slice(1);
                this.displayToast({text: `${type} uploaded successfully.`, type: 'success'});

                this.$refs[`${this.type}-upload`].removeAllFiles();
                this.$emit('uploadComplete');
            },

            uploadError(file, message, xhr) {
                this.displayToast({text: message, type: 'error'});
                this.$refs[`${this.type}-upload`].removeAllFiles();
            }
        }
    }
</script>