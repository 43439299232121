<template>
    <div>
        <template v-if="!reward">
            <empty-state
                icon="hourglass"
                title="Hang On A Sec..."
                subtitle="We're just loading your details. Won't be a moment.">
            </empty-state>
        </template>

        <template v-if="reward">
            <b-tabs pills class="mb-2">
                <b-tab
                        v-for="reward in user.current_rewards"
                        :key="reward.id"
                        :title="`${reward.reward_type} Reward`"
                        :active="currentRewardTabIsActive(reward)"
                        @click="goToReward(reward.id)"
                ></b-tab>
            </b-tabs>

            <div class="row">
                <div class="col-12 mb-1">
                    <div class="d-lg-flex justify-content-lg-between mb-2 align-items-center">
                        <span v-if="rewardStatus" class="badge mb-3" :class="badgeClass">
                            {{ rewardStatusText }} <template v-if="userReward.complete">{{ userReward.completed_at }}</template>
                        </span>
                        <b-button variant="primary" small v-if="isCompanyAdmin" @click="openUpdateStatusModal()">Update Status</b-button>
                    </div>
                    <h4 v-if="isCompanyAdmin" class="text-primary mb-3"><small class="text-white">Booked by:</small> {{ userReward.booked_by }}</h4>
                    <p class="text-xs">Ref: {{ uuid }}</p>
                    <h2 class="text-primary">
                        {{ reward.title }}
                    </h2>
                    <p v-html="reward.description"></p>
                    <br/>
                    <p v-if="!isMerchandise">
                        Please note that all booked rewards are worked on in the order that they were allocated. Timeframes for delivery may vary and be delayed due to personal circumstances or priority deadlines. If your request is urgent or expected by a deadline, please make sure you communicate that to me as soon as possible via the usual channels (Discord, Patreon, Email).
                        <br/><br/>
                        While we work on a system to display this via StarStickers, you may follow my workflow and reward order on my Trello board <a href="https://bit.ly/ony-work" target="_blank">here</a>.
                    </p>
                </div>

<!--                <div class="col-6 col-md-4">-->
<!--                    <div class="text-stat text-center">-->
<!--                        <h6 class="text-white">Date Requested</h6>-->
<!--                        <h4>{{ userReward.created_at }}</h4>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-6 col-md-4">-->
<!--                    <div class="text-stat text-center">-->
<!--                        <h6 class="text-white">Chosen Period</h6>-->
<!--                        <h4>{{ userReward.period }}</h4>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="col-6 col-md-4">-->
<!--                    <div class="text-stat text-center">-->
<!--                        <h6 class="text-white">Points Used</h6>-->
<!--                        <h4>{{ userReward.points_cost }}</h4>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="w-100" v-if="reward.examples.length > 0 && isIllustration">
                    <div class="text-center">
                        <b-button v-b-toggle.reward-examples variant="outline-primary">View Reward Examples</b-button>
                    </div>
                    <b-collapse id="reward-examples" class="mt-4">
                        <div class="reference-grid">
                            <div v-for="example in reward.examples" class="reference-grid-item">
                                <a :href="example.path" target="_blank">
                                    <img :src="example.path" alt="Reward illustration">
                                </a>
                            </div>
                        </div>
                        <div class="text-center">
                            <b-button v-b-toggle.reward-examples><i class="fal fa-chevron-up"></i></b-button>
                        </div>
                    </b-collapse>
                </div>
            </div>

            <hr v-if="showDivider" class="divider" />

            <b-tabs pills>

                <template v-if="reward.allow_upload">
                    <!-- Illustrations -->
                    <b-tab v-if="displayIllustrations" title="Completed Illustration" @click="setTab('illustrations')" :active="tabIsActive('illustrations')">
                        <b-card class="reward-card">

                            <template v-if="userReward.illustrations.length > 0">
                                <div class="reference-grid">
                                    <div v-for="illustration in userReward.illustrations" class="reference-grid-item">
                                        <a :href="illustration.path" target="_blank">
                                            <img :src="illustration.path" alt="Reward illustration">
                                        </a>
                                        <button v-if="isCompanyAdmin" @click="triggerFileDelete(illustration.id)" class="btn btn-danger btn-sm reference-delete"><i class="fa fa-times"></i></button>
                                    </div>
                                </div>
                            </template>

                            <template v-if="userReward.illustrations.length <= userReward.illustration_max && isCompanyAdmin">
                                <h3>Upload an Illustration</h3>
                                <dropzone
                                        ownerClass="UserReward"
                                        :ownerId="uuid"
                                        :fileSize="10"
                                        type="illustration"
                                        uploadText="Upload Finished Illustrations"
                                        @uploadComplete="uploadComplete"
                                ></dropzone>
                            </template>
                        </b-card>
                    </b-tab>

                    <!-- Description -->
                    <b-tab title="Description" @click="setTab('description')" :active="tabIsActive('description')">
                        <b-card class="reward-card" v-if="!editingDescription">
                            <div v-html="userReward.description"></div>
                            <button class="btn btn-md btn-info" @click="editingDescription = true">Edit</button>
                        </b-card>
                        <b-card class="reward-card" v-if="editingDescription">
                            <vue-editor
                                    id="description-editor"
                                    class="mb-3"
                                    v-model="userReward.description"
                                    placeholder="Add a description of requested reward including pose, expression, and any other details not shown in the references."
                                    :editorToolbar="editorToolbar"
                            ></vue-editor>
                            <button class="btn btn-md btn-success" @click="saveDescription()">
                                <i v-if="saving" class="fal fa-pulse fa-spinner"></i> Save
                            </button>
                        </b-card>
                    </b-tab>

                    <!-- References -->
                    <b-tab title="References" v-if="canUploadReferences" @click="setTab('references')" :active="tabIsActive('references')">
                        <b-card class="reward-card">

                            <template v-if="userReward.references.length > 0">
                                <div class="reference-grid">
                                    <div v-for="reference in userReward.references" class="reference-grid-item">
                                        <a :href="reference.path" target="_blank">
                                            <img :src="reference.path" alt="Reward Reference">
                                        </a>
                                        <button @click="triggerFileDelete(reference.id)" class="btn btn-danger btn-sm reference-delete"><i class="fa fa-times"></i></button>
                                    </div>
                                </div>
                            </template>

                            <template v-if="userReward.references.length <= userReward.reference_max && !userReward.complete">
                                <h3>Upload a Reference</h3>
                                <dropzone
                                        ownerClass="UserReward"
                                        :ownerId="uuid"
                                        type="reference"
                                        uploadText="Upload Your References"
                                        @uploadComplete="uploadComplete"
                                ></dropzone>
                            </template>
                        </b-card>
                    </b-tab>
                </template>

                <!-- Bundle Contents -->
                <b-tab title="Bundle" @click="setTab('bundle')" :active="tabIsActive('bundle')" v-if="isMerchandise">
                    <b-card class="reward-card">
                        <h2 class="my-3">Bundle Contents</h2>
                        <p class="mb-0">Your bundle will arrive containing the below {{ bundleItems.length }} items:</p>
                        <div class="media">
                            <div class="media-body">
                                <div class="reference-grid">
                                    <div v-for="(bundleItem, key) in bundleItems" :key="key" class="reference-grid-item">
                                        <p class="my-2 mx-1">
                                            <strong>{{ bundleItem.qty }}x {{ bundleItemFormat(bundleItem) }} of {{ bundleItemLine(bundleItem) }}</strong>
                                        </p>
                                        <a :href="bundleItemLineImage(bundleItem)" target="_blank">
                                            <img :src="bundleItemLineImage(bundleItem)" alt="Reward illustration">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-tab>

                <!-- Shipping Address -->
                <b-tab title="Address" @click="setTab('address')" :active="tabIsActive('address')" v-if="isMerchandise">
                    <b-card class="reward-card">
                        <h2 class="my-3">Shipping Address</h2>
                        <p>Your bundle will be shipped to the below address:</p>
                        <div class="media">
                            <div class="media-body">
                                <ul style="list-style: none">
                                    <li>{{ userReward.address.full_name }}</li>
                                    <li>{{ userReward.address.line_1 }}</li>
                                    <li>{{ userReward.address.line_2 }}</li>
                                    <li>{{ userReward.address.city }}</li>
                                    <li>{{ userReward.address.county }}</li>
                                    <li>{{ userReward.address.postcode }}</li>
                                    <li>{{ userReward.address.country }}</li>
                                </ul>
                            </div>
                        </div>
                    </b-card>
                </b-tab>

                <b-tab title="Notes" @click="setTab('notes')" :active="tabIsActive('notes')" v-if="displayNotes">
                    <!-- List of Notes -->
                    <template v-if="userReward.notes.length">
                        <b-card
                            v-for="note in userReward.notes.slice().reverse()"
                            :key="note.id"
                            class="reward-card">
                            <div class="media">
                                <div class="media-body">
                                    <div class="title" v-html="note.text"></div>
                                    <p class="date font-italic">
                                        {{ note.user }} - {{ note.created_at }}
                                    </p>
                                </div>
                                <div class="media-right points-earned" v-if="isCompanyAdmin">
                                    <button class="btn btn-danger" @click="triggerNoteDelete(note.id)">Delete</button>
                                </div>
                            </div>
                        </b-card>
                    </template>

                    <!-- New note -->
                    <template v-if="isCompanyAdmin">
                        <b-card class="reward-card">
                            <h2 class="my-3">Add New Note</h2>
                            <p v-if="isMerchandise" class="text-muted text-sm">If you have a tracking number for the shipment, add it here.</p>
                            <vue-editor
                                    id="note-editor"
                                    class="mb-3"
                                    v-model="noteText"
                                    placeholder="Enter reward notes here."
                            ></vue-editor>
                            <button class="btn btn-md btn-success" @click="triggerAddNote()">Add Note</button>
                        </b-card>
                    </template>

                    <!-- Empty state -->
                    <empty-state
                            v-if="userReward.notes.length < 1 && !isCompanyAdmin"
                            icon="comment-slash"
                            title="It's very quiet..."
                            subtitle="No notes have been added to this reward yet.">
                    </empty-state>

                </b-tab>
            </b-tabs>

            <div class="row justify-content-md-center">
                <div class="col-12 col-md-6 col-lg-4">
                    <b-button class="mt-2 mb-1" variant="danger" block v-b-modal.reward-cancel v-if="canCancelReward">
                        Cancel Reward
                    </b-button>
                    <router-link :to="{name: 'me', params: {startTab: 'rewards'}}">
                        <p class="text-md-center">View your reward history</p>
                    </router-link>
                </div>
            </div>

        </template>

        <reward-cancel v-if="canCancelReward" :userReward="userReward" @updated="fetchUserReward(uuid)"></reward-cancel>
        <reward-status-update v-if="isCompanyAdmin" ref="rewardUpdateStatus" :userReward="userReward" @updated="fetchUserReward(uuid)"></reward-status-update>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "UserRewardDetails",

        mounted() {
            this.fetchUserReward(this.uuid)
                .then(() => {
                    if (!this.userReward.description) {
                        this.editingDescription = true;
                    }
                    this.setTab(this.startingTab);
                    this.loading = false;
                });
        },

        props: ['uuid'],

        data: function() {
            return {
                tab: null,
                loading: true,
                saving: false,
                noteText: '',
                editingDescription: false,
                editorToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ["bold", "italic", "underline"],
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" }
                    ],
                    ["blockquote"],
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                    [{ indent: "-1" }, { indent: "+1" }],
                    [{ color: [] }, { background: [] }],
                    ["link"],
                    ["clean"] // remove formatting button
                ]
            }
        },

        computed: {
            ...mapGetters(['canCancelReward', 'isCompanyAdmin', 'user', 'userReward']),

            badgeClass() {
                if (this.rewardStatus) {
                    if (this.rewardStatus.code === 'complete') {
                        return 'badge-success';
                    }
                    if (this.rewardStatus.code === 'cancelled') {
                        return 'badge-danger';
                    }
                }
                return 'badge-info';
            },

            bundleItems() {
                if (!this.userReward.merchandise_items || !this.userReward.merchandise_items.length) {
                    return {};
                }

                let items = {};

                for (let i = 0; i < this.userReward.merchandise_items.length; i++) {

                    let format = this.userReward.merchandise_items[i].merchandise_format;
                    let line = this.userReward.merchandise_items[i].merchandise_line;

                    let key = `${format ? format.id : '-'}${line ? line.id : '-'}`;

                    if (items[key] === undefined) {
                        items[key] = {
                            qty: 0,
                            item: this.userReward.merchandise_items[i]
                        }
                    }

                    items[key].qty++;
                }

                return items;
            },

            canUploadReferences() {
                return this.rewardStatus && (
                    this.rewardStatus.code === 'pending' ||
                    this.rewardStatus.code === 'accepted' ||
                    this.rewardStatus.code === 'in-progress'
                );
            },

            displayIllustrations() {
                if (this.isCompanyAdmin) {
                    return true;
                }
                return this.userReward.complete && this.userReward.illustrations.length;
            },

            displayNotes() {
                return this.isCompanyAdmin || this.userReward.notes.length;
            },

            reward() {
                if (!this.userReward || !this.userReward.reward) {
                    return null;
                }
                return this.userReward.reward;
            },

            rewardStatus() {
                if (!this.userReward || !this.userReward.reward_status) {
                    return null;
                }
                return this.userReward.reward_status;
            },

            rewardStatusText() {
                if (this.rewardStatus.code === 'complete' && this.isMerchandise) {
                    return 'Shipped';
                }
                return this.rewardStatus.title;
            },

            showDivider() {
                return (this.reward && this.reward.allow_upload) || this.displayNotes;
            },

            startingTab() {
                if (this.reward && this.reward.type === "Merchandise") {
                    return 'bundle';
                } else if (this.reward && !this.reward.allow_upload) {
                    return 'notes';
                }
                return (this.displayIllustrations) ? 'illustrations' : 'description';
            },

            isIllustration() {
                return this.reward && this.reward.type === 'Illustration';
            },

            isMerchandise() {
                return this.reward && this.reward.type === 'Merchandise';
            }
        },

        methods: {
            ...mapActions(['addNote', 'deleteFile', 'deleteNote', 'displayToast', 'fetchUserReward', 'updateUserReward']),

            setTab(tab) {
                this.tab = tab;
            },

            tabIsActive(tab) {
                if (!this.tab && tab === 'points') {
                    return true;
                }
                return tab === this.tab;
            },

            triggerAddNote() {
                if (!this.noteText || this.noteText === '') {
                    return;
                }

                this.addNote({ uuid: this.uuid, noteText: this.noteText }).then(() => {
                    this.displayToast( {text: 'Your note was successfully added.', type: 'success'});
                    this.noteText = '';
                })
            },

            saveDescription() {
                this.saving = true;
                this.updateUserReward({ uuid: this.uuid, description: this.userReward.description })
                    .then(() => {
                        this.displayToast( {text: 'The reward description was successfully updated.', type: 'success'});
                        this.editingDescription = false;
                        this.saving = false;
                    }).catch(() => {
                    this.displayToast( {text: 'There was a problem updating the reward description.', type: 'error'});
                    this.saving = false;
                });
            },

            triggerFileDelete(uuid) {
                this.deleteFile(uuid).then(() => {
                    this.fetchUserReward(this.uuid);
                })
            },

            triggerNoteDelete(uuid) {
                this.deleteNote(uuid).then(() => {
                    this.fetchUserReward(this.uuid);
                })
            },

            uploadComplete() {
                this.fetchUserReward(this.uuid);
            },

            openUpdateStatusModal() {
                this.$refs.rewardUpdateStatus.open();
            },

            currentRewardTabIsActive(reward) {
                return reward.id === this.uuid;
            },

            goToReward(rewardUuid) {
                if (rewardUuid === this.uuid) {
                    return;
                }
                this.$router.push({name: 'reward', params: {uuid: rewardUuid}});
            },

            bundleItemFormat(bundleItem) {
                let format = bundleItem.item.merchandise_format;
                return format ? format.title : 'Deactivated Format';
            },

            bundleItemLine(bundleItem) {
                let line = bundleItem.item.merchandise_line;
                return line ? line.title : 'Deactivated Line';
            },

            bundleItemLineImage(bundleItem) {
                let line = bundleItem.item.merchandise_line;
                return line ? line.images[0].path : '';
            }
        }
    }
</script>

<style scoped>

</style>
