<template>
    <div class="admin-container">
        <div class="admin-title row">
            <div class="col-md-12">
                <h3>
                    Merchandise
                </h3>
            </div>
        </div>

        <!-- MERCHANDISE FORMATS -->
        <div class="mb-3">
            <div class="row">
                <div class="col-md-12">
                    <h5>Formats</h5>
                    <p class="text-sm">The physical formats that the merchandise comes in.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b-badge
                            pill
                            class="merchandise-format-pill mb-3"
                            variant="success"
                            href="#"
                            @click="openFormatModal(null)"
                    >
                        <i class="fa fa-plus"></i> New Format
                    </b-badge>

                    <b-badge
                            pill
                            class="merchandise-format-pill mb-3"
                            variant="primary"
                            href="#"
                            @click="openFormatModal(format)"
                            v-for="format in formats"
                            :key="format.id"
                    >
                        {{ format.title }}
                    </b-badge>
                </div>
            </div>
        </div>

        <!-- MERCHANDISE LINES -->
        <div>
            <div class="row">
                <div class="col-md-9">
                    <h5>Lines</h5>
                    <p class="text-sm">The merchandise lines that are on offer.</p>
                </div>
                <div class="col-md-3">
                    <b-form-group>
                        <b-form-select
                                v-model="formatFilter"
                                :options="formatFilterOptions"
                        ></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="merchandise-line-grid mb-5">
                        <b-card
                                @click="openLineModal(null)"
                                class="merchandise-card-new mb-5"
                                align="center"
                                no-body
                                tag="article">
                            <h5>
                                <i class="fa fa-plus"></i> New Line
                            </h5>
                        </b-card>

                        <b-card
                                @click="openLineModal(line)"
                                class="merchandise-card mb-5"
                                v-for="line in filteredLines"
                                :key="line.id"
                                align="center"
                                no-body
                                tag="article">
                            <div class="merchandise-line-img-container" v-if="lineImage(line)">
                                <b-card-img
                                        :src="lineImage(line)"
                                        :alt="line.title"
                                        top>
                                </b-card-img>
                            </div>
                            <p v-if="!lineImage(line)" class="merchandise-line-no-image">
                                No Image
                            </p>
                            <div class="merchandise-line-body">
                                <b-card-title class="merchandise-line-title">
                                    {{ line.title }}
                                </b-card-title>
                                <b-badge pill v-for="format in line.formats" :key="format.id" variant="primary" class="merchandise-line-pill">
                                    {{ format.title }}
                                </b-badge>
                            </div>
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

        <merchandise-format-modal
                ref="merchandiseFormatModal"
                @reload="fetchFormats()"
        ></merchandise-format-modal>

        <merchandise-line-modal
                :formats="formats"
                ref="merchandiseLineModal"
                @reload="fetchLines()"
        ></merchandise-line-modal>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: "CompanyMerchandise",

        data() {
            return {
                formatFilter: null
            }
        },

        computed: {
            ...mapGetters(['availableLineIdsByFormatId', 'formats', 'lines']),

            formatFilterOptions() {
                let formats = [{value: null, text: 'All Formats'}];
                for (let i = 0; i < this.formats.length; i++) {
                    formats.push({
                        value: this.formats[i].id,
                        text: this.formats[i].title
                    });
                }
                return formats;
            },

            filteredLines() {
                if (!this.formatFilter) {
                    return this.lines;
                }

                let lines = [];

                let lineIds = this.availableLineIdsByFormatId(this.formatFilter);

                for (let i = 0; i < this.lines.length; i++) {

                    if (lineIds.includes(this.lines[i].id)) {
                        lines.push(this.lines[i]);
                    }
                }

                return lines;
            }
        },

        mounted() {
            this.fetchFormats();
            this.fetchLines();
        },

        methods: {
            ...mapActions(['fetchFormats', 'fetchLines', 'displayToast']),

            lineImage(line) {
                if (!line.images.length) {
                    return null;
                }
                return line.images[0].path;
            },

            openFormatModal(format) {
                this.$refs.merchandiseFormatModal.open(format);
            },

            openLineModal(line) {
                this.$refs.merchandiseLineModal.open(line);
            }
        }
    }
</script>

<style scoped>

</style>
