<template>
    <b-modal
        content-class="shadow"
        hide-footer
        hide-header
        id="confirm-cancel-reward-slots-modal"
        no-stacking>

        <b-button class="modal-close fal fa-times" variant="danger" @click="close()"></b-button>

        <h3><span class="text-capitalize">{{ type }}</span> Reward Slots</h3>

        <p>Are you sure you want to {{ type }} these reward slots?</p>

        <p>Success emails will be sent, but failed applications and cancelled slots will not currently send emails.</p>

        <b-button :variant="type === 'confirm' ? 'success' : 'danger'" class="mt-3" block @click="triggerUpdateRewardSlots()">Yes, <span class="text-uppercase">{{ type }}</span> Reward Slots</b-button>
        <b-button variant="primary" class="mt-3" block @click="close()">No Thanks</b-button>

    </b-modal>
</template>

<script>
    export default {
        name: "ConfirmUpdateRewardSlotsModal",

        data() {
            return {
                type: null
            }
        },

        methods: {
            triggerUpdateRewardSlots() {
                if (this.type === 'confirm') {
                    this.$emit('confirm');
                } else {
                    this.$emit('update', {status: 'cancelled'});
                }
                this.close();
            },

            open(type) {
                this.type = type;
                this.$bvModal.show('confirm-cancel-reward-slots-modal');
            },

            close() {
                this.$bvModal.hide('confirm-cancel-reward-slots-modal');
            }
        }
    }
</script>

<style scoped>

</style>
