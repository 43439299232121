const state = {
    company: {
        id: null,
        hook_id: null,
        name: '',
        description: '',
        supporter_types: [],
        faqs: []
    },
    stats: {}
};

const getters = {
    company: state => {
        return state.company;
    },

    companyStats: state => {
        return state.stats;
    }
};

const actions = {
    fetchCompany({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/companies/active`)
                .then(response => {
                    dispatch('setCompany', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fetchOnyricaCompany({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/onyrica-company`)
                .then(response => {
                    dispatch('setCompany', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    updateCompanyInformation({dispatch}, companyInformation) {
        return new Promise((resolve, reject) => {
            axios.put(`/web/companies`, companyInformation)
                .then(response => {
                    dispatch('setCompany', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setCompany({commit}, company) {
        return new Promise((resolve) => {
            commit('SET_COMPANY', company);
            resolve();
        });
    },

    clearCompany({commit}) {
        return new Promise((resolve) => {
            commit('CLEAR_COMPANY');
            resolve();
        });
    },

    fetchCompanyStats({dispatch}) {
        return new Promise((resolve, reject) => {
            axios.get(`/web/companies/stats`)
                .then(response => {
                    dispatch('setCompanyStats', response.data).then(() => {
                        resolve();
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setCompanyStats({commit}, companyStats) {
        return new Promise((resolve) => {
            commit('SET_COMPANY_STATS', companyStats);
            resolve();
        });
    }
};

const mutations = {
    SET_COMPANY(state, company) {
        state.company = company;
    },

    CLEAR_COMPANY(state) {
        state.company = {
            id: null,
            hook_id: null,
            name: '',
            description: '',
            supporter_types: [],
            faqs: []
        };
        state.stats = {};
    },

    SET_COMPANY_STATS(state, companyStats) {
        state.stats = companyStats;
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}
