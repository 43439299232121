<template>
    <div class="admin-container">
        Company Reward
    </div>
</template>

<script>
    export default {
        name: "CompanyReward"
    }
</script>

<style scoped>

</style>
